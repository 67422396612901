import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '@common/components/breadcrumb/breadcrumb.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@common/modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@common/shared.module';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
