export const ROOT = '/';
export const NOT_FOUND = 'not-found';
export const EDIT = 'edit';
export const NEW = 'new';
export const UNAUTHORIZED = 'unauthorized';

export const ROUTES = Object.freeze({
  root: '',
  auth: 'auth',
  login: 'login',
});

export const SETTINGS = Object.freeze({
  root: 'settings',
  profile: 'profile',
  accessControl: 'access-control',
});
