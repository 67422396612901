import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RoleEnum } from '@common/generated/graphql';
import { AuthorizationService } from '@common/services/authorization.service';

export const hasSimpleWrapperGuard: CanActivateFn = () => {
  const authorizationService = inject(AuthorizationService);

  const isInterviewer = authorizationService.hasSingleRole(
    RoleEnum.Interviewer,
  );

  const hasSimpleWrapper = isInterviewer;

  return hasSimpleWrapper;
};
