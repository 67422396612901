import { inject, Injectable } from '@angular/core';
import ReportIssueService from '@common/services/report-issue.service';
import { UserInfoService } from '@app/modules/admin/pages/users/user-info/user-info.service';

@Injectable({
  providedIn: 'root',
})
export default class ReportIssueGtopService extends ReportIssueService {
  userInfoService = inject(UserInfoService);

  override reportIssue(message: string) {
    return this.userInfoService.reportIssue(message);
  }
}
