export const KANDA_TIME_SHEET_PERMISSIONS = {
  readByFilter: {
    module: 'KandaTimeSheet',
    action: 'GetKandaTimeSheetsByFilter',
  },
  updateHours: {
    module: 'KandaTimeSheet',
    action: 'UpdateKandaTimeSheetHours',
  },
  lockUnlock: { module: 'KandaTimeSheet', action: 'LockUnlockTimeSheet' },
  readPersonalTimeSheets: {
    module: 'KandaTimeSheet',
    action: 'GetPersonalTimeSheets',
  },
};
