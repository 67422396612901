export const PROJECTS_PERMISSIONS = {
  read: { module: 'Project', action: 'GetAllProjects' },
  readByFilter: { module: 'Project', action: 'GetProjectsByFilter' },
  readById: { module: 'Project', action: 'GetProjectById' },
  update: { module: 'Project', action: 'UpdateProject' },
  create: { module: 'Project', action: 'AddProject' },
  changeProjectState: { module: 'Project', action: 'ChangeProjectState' },
  changeProjectHealth: { module: 'Project', action: 'ChangeProjectHealth' },
  changeProjectSowSigned: {
    module: 'Project',
    action: 'ChangeProjectSowSigned',
  },
  uploadProjectDocuments: {
    module: 'Project',
    action: 'UploadProjectDocuments',
  },
  downloadProjectDocument: {
    module: 'Project',
    action: 'GetDownloadProjectDocument',
  },
  getProjectDiscounts: {
    module: 'Project',
    action: 'GetProjectDiscounts',
  },
  updateProjectDiscount: {
    module: 'Project',
    action: 'UpdateProjectDiscount',
  },
  addProjectDiscount: {
    module: 'Project',
    action: 'AddProjectDiscount',
  },
};
