import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentInfo, Maybe } from '@common/generated/graphql';
import { FormControl } from '@angular/forms';
import { PermissionConfig } from '@common/services/authorization.service';

@Component({
  selector: 'assets-logs',
  templateUrl: 'logs.component.html',
})
export class LogsComponent {
  @Input() control: FormControl;
  @Input() label?: string = '';
  @Input() comments: Maybe<Array<Maybe<CommentInfo>>>;
  @Input() permission: PermissionConfig | PermissionConfig[] | undefined;
  @Output() sendLog = new EventEmitter();

  addLog(): void {
    this.sendLog.emit(this.control.value);
    this.control.markAsUntouched();
  }
}
