import { Directive } from '@angular/core';
import { VendorStateEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = VendorStateEnum | undefined;

@Directive({
  selector: '[appVendorStateChipResolver]',
})
export class VendorStateChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const state = model ?? VendorStateEnum.Active;

    const classNames = state
      ? state === VendorStateEnum.Active
        ? ['assets-chip-success']
        : state === VendorStateEnum.Suspended
        ? ['assets-chip-gray']
        : ['assets-chip-danger']
      : [];

    return {
      classNames,
      contentToken: state,
    };
  }
}
