export const PLACEMENTS_PERMISSIONS = {
  read: { module: 'Placement', action: 'GetAllPlacements' },
  readByFilter: { module: 'Placement', action: 'GetPlacementsByFilter' },
  readPersonalPlacements: {
    module: 'Placement',
    action: 'GetPersonalPlacementsByFilter',
  },
  readById: { module: 'Placement', action: 'GetPlacementById' },
  update: { module: 'Placement', action: 'UpdatePlacement' },
  create: { module: 'Placement', action: 'AddPlacement' },
  delete: { module: 'Placement', action: 'DeletePlacement' },
  createOrUpdateBillings: {
    module: 'Placement',
    action: 'AddOrUpdatePlacementBillings',
  },
  readBillings: { module: 'Placement', action: 'GetBillingRatesByPlacementId' },
  readChecklists: {
    module: 'Placement',
    action: 'GetPlacementOnboardingChecksByPlacementId',
  },
  updateCurrentPlacementState: {
    module: 'Placement',
    action: 'UpdateCurrentPlacementState',
  },
  movePlacement: { module: 'Placement', action: 'MovePlacement' },
  addPlacementComment: { module: 'Placement', action: 'AddPlacementComment' },
};
