import { HttpRequest } from '@angular/common/http';
import { NON_INTERCEPTED_OPERATIONS } from '@common/const/apollo.const';

export default function nonInterceptedReqChecker(
  req: HttpRequest<any>,
): boolean {
  if (req.method === 'GET') {
    return true;
  }

  const isNonInterceptedRequest =
    req.body?.operationName &&
    NON_INTERCEPTED_OPERATIONS.includes(req.body?.operationName);
  return isNonInterceptedRequest;
}
