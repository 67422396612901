import { Directive, HostBinding, Input } from '@angular/core';
import { StopPropagationDirective } from '@common/directives/stop-propagation/stop-propagation.directive';

@Directive({
  selector: '[href.click.stop]',
})
export class HrefClickStopDirective extends StopPropagationDirective {
  @Input('href.click.stop')
  @HostBinding('href')
  href: string;
}
