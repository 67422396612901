<div
  [class]="
    'assets-form-dropdown assets-form-field ' + ('label-' + labelPosition)
  "
>
  <span class="assets-form-field-label">
    {{ label | translate }}
    <span
      *ngIf="hasRequiredField(control)"
      class="text-danger font-weight-light"
      >*</span
    >
  </span>
  <mat-form-field appearance="fill">
    <mat-label>{{ placeholder | translate }} </mat-label>

    <mat-select
      [formControl]="control"
      (selectionChange)="selectedValue($event.value)"
      disableOptionCentering
      panelClass="custom-mat-select-panel"
      #singleSelect
      [multiple]="multiple"
    >
      <ng-container *ngIf="options | selectOptions as SELECT_OPTIONS">
        <mat-option
          *ngFor="let option of SELECT_OPTIONS"
          [value]="option?.id ?? option?.value"
        >
          {{ option.name | translate }}
        </mat-option>
      </ng-container>
    </mat-select>

    <div
      matSuffix
      class="d-flex justify-content-center"
      *ngIf="!control.disabled"
    >
      <mat-icon (click)="clearValue($event)">clear</mat-icon>
      <mat-icon>expand_more</mat-icon>
    </div>

    <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>

    <!--    hint-->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

    <mat-icon *ngIf="suffix" matSuffix>{{ suffix }}</mat-icon>

    <mat-icon *ngIf="prefix" matPrefix>{{ prefix }}</mat-icon>
  </mat-form-field>
</div>
