import { Pipe, PipeTransform } from '@angular/core';
import { Primitive } from 'ts-essentials';

@Pipe({
  name: 'fallback',
})
export class FallbackPipe implements PipeTransform {
  transform(
    value: Primitive | null | undefined,
    hideValue: 'hideValue' | false = false,
  ) {
    return value ? (hideValue ? '' : String(value)) : '-';
  }
}
