import { HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { IRoute } from '@common/models';

export const Truth = {
  true: {
    value: true,
    name: 'Yes',
  },
  false: {
    value: false,
    name: 'No',
  },
};

export type NonInterceptedReqChecker = (req: HttpRequest<any>) => boolean;
export const NON_INTERCEPTED_REQUEST_CHECKER =
  new InjectionToken<NonInterceptedReqChecker>('NON_INTERCEPTED_REQ_CHECKER');
export const BASE_URL = new InjectionToken<string>('BASE_URL');
export const TITLE_PREFIX = new InjectionToken<string>('TITLE_PREFIX');
export const NAVIGATION_ROUTES = new InjectionToken<IRoute[]>(
  'NAVIGATION_ROUTES',
);
export const ENVIRONMENT = new InjectionToken<{
  production: boolean;
  apiURL: string;
  title: string;
  i18nPrefix: string;
}>('environment');
