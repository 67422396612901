import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { AnchorAppLayoutDirective } from './anchor-app-layout.directive';
import { LinkContextDirective } from './link-context.directive';

export const OPEN_IN_NEW_TAB = new InjectionToken<boolean>(
  'Open in a new tab option',
);

@Directive({
  selector: '[appLink],[routerLink]',
})
export class LinkDirective implements OnInit, OnDestroy {
  protected viewContainerRef = inject(AnchorAppLayoutDirective, {
    optional: true,
  })?.viewContainerRef;
  protected routerLink = inject(RouterLink, { optional: true });
  protected routerLinkWithHref = inject(RouterLink, { optional: true });
  // protected routerLink = inject(RouterLink, { optional: true });
  protected router = inject(Router);
  protected cdRef = inject(ChangeDetectorRef);
  protected elementRef = inject(ElementRef);
  protected document: Document = inject(DOCUMENT);
  protected openInNewTabConfig: boolean =
    inject(OPEN_IN_NEW_TAB, { optional: true }) ?? false;
  protected context = inject(LinkContextDirective, { optional: true })?.context;
  protected readonly destroy$ = new Subject<void>();

  @HostBinding('class') className = 'app-link-directive';

  @Input('routerLink') commands: any[];
  @Input() openInNewTab: BooleanInput = false;
  @HostListener('contextmenu', ['$event']) onContextmenu(event: MouseEvent) {
    event.stopPropagation();

    if (
      !this.routerLink?.urlTree ||
      this.shouldOpenDefaultContextMenu(event) ||
      !this.viewContainerRef
    ) {
      return;
    }

    event.preventDefault();

    const componentRef =
      this.viewContainerRef.createComponent(ContextMenuComponent);
    componentRef.instance.openMenu(event);
    componentRef.instance.items = [
      {
        text: 'OPEN_LINK_NEW_TAB',
        callback: () => window.open(this.routerLink?.urlTree?.toString()),
      },
      {
        text: 'COPY_LINK',
        callback: () =>
          navigator.clipboard.writeText(
            window.location.origin +
              (this.routerLink?.urlTree?.toString() ?? ''),
          ),
      },
    ];

    this.cdRef.detach();

    componentRef.instance.menuClosed
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.viewContainerRef!.clear());
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (!this.routerLink?.urlTree) {
      return;
    }

    if (!this.shouldOpenDefaultContextMenu(event)) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }

    const urlTree =
      this.routerLink?.urlTree || this.routerLinkWithHref?.urlTree;

    this.router.events.pipe(first(), takeUntil(this.destroy$)).subscribe(() => {
      const previousUrlTree =
        this.router.getCurrentNavigation()?.previousNavigation?.finalUrl!;
      if (urlTree) {
        const openInANewTab =
          coerceBooleanProperty(this.openInNewTab) ||
          this.openInNewTabConfig ||
          this.context?.openInNewTab;

        if (this.hasSelection()) {
          this.router.navigateByUrl(previousUrlTree);
          return;
        }

        if (event.metaKey || event.ctrlKey || openInANewTab) {
          window.open(urlTree.toString(), openInANewTab ? '_blank' : '');
          this.router.navigateByUrl(previousUrlTree);
        }
      }
    });
  }

  ngOnInit(): void {
    if (!this.routerLink && !this.routerLinkWithHref) {
      console.error(`To be able to use the [appLink] on an element
      you must apply the [routerLink] to this element`);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hasSelection() {
    const selection = this.document.getSelection();
    const selectedText = this.document.getSelection()?.toString();
    if (selection && selectedText) {
      const hasSelection = this.elementRef.nativeElement.contains(
        selection?.anchorNode,
      );
      if (hasSelection) {
        return true;
      }
    }

    return false;
  }

  private shouldOpenDefaultContextMenu(event: MouseEvent) {
    const isAnchorElement =
      (event.currentTarget as HTMLElement).tagName === 'A';
    if (isAnchorElement) {
      return true;
    }

    if (this.hasSelection()) {
      return true;
    }

    return false;
  }
}
