import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IUser } from '@common/models';

export class LoginForm extends UntypedFormGroup {
  readonly login = this.get('login') as UntypedFormControl;
  readonly password = this.get('password') as UntypedFormControl;
  readonly code = this.get('code') as UntypedFormControl;
  readonly trustDevice = this.get('trustDevice') as UntypedFormControl;

  constructor(
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
    readonly model?: IUser,
  ) {
    super(
      fb.nonNullable.group({
        login: [model?.login, Validators.required],
        password: [model?.password, Validators.required],
        code: [null],
        trustDevice: [false, Validators.required],
      }).controls,
    );
  }
}
