import { Component, Inject } from '@angular/core';
import { BaseDialogComponent } from './base-dialog.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export interface IConfirmDeletionData {
  title: string;
  text: string;
  dialogRes?: boolean;
  spinnerName?: string;
  searchMethod?: Function;
  callback?: {
    synchronous: boolean;
    action: Function;
  };
}

@Component({
  selector: 'assets-confirm-deletion-dialog',
  template: `
    <assets-base-dialog
      [actions]="action"
      [spinnerName]="data.spinnerName"
      [dialogTitle]="data.title"
    >
      <p class="text-lg mb-0">{{ data.text }}</p>
    </assets-base-dialog>
    <ng-template #action>
      <button
        type="submit"
        class="assets-btn primary-btn w-100 mr-3"
        mat-flat-button
        (click)="onSubmit()"
      >
        {{ 'YES' | translate }}
      </button>
      <button
        type="submit"
        class="assets-btn danger-btn w-100"
        mat-flat-button
        (click)="onClose()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </ng-template>
  `,
})
export class ConfirmDeletionDialogComponent extends BaseDialogComponent<any> {
  constructor(
    dialogRef: MatDialogRef<ConfirmDeletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDeletionData,
  ) {
    super(dialogRef);
  }

  override onSubmit() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
