import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from '@root/environments/environment';

@Directive({
  selector: '[appImage]',
})
export class ImageDirective implements OnInit {
  @Input('appImage') set appImage(value: string) {
    this.image = value;
    this.setImage();
  }

  @Input() extension: string;
  @Input() useAsBase64: boolean = false;

  @Input() defaultImage: string;
  @Input() imageSize: 'extra-small' | 'small' | 'medium' | 'large' = 'large';
  @HostBinding('src') src = '';
  @HostBinding('class') imageClass = '';

  private image = '';

  constructor(private el: ElementRef<HTMLImageElement>) {
    if (this.el.nativeElement.localName !== 'img') {
      console.error("Directive 'appImage' should only apply to the img tag");
    }
  }

  ngOnInit(): void {
    this.setImage();
  }

  setImage() {
    this.src = this.image
      ? this.image.startsWith('data:')
        ? this.image
        : this.useAsBase64
        ? `data:image/${this.extension + ';base64,' + this.image}`
        : new URL(this.image, environment.apiURL).href
      : `/assets/images/${this.defaultImage}`;

    this.imageClass = `image-size-${this.imageSize}`;
  }
}
