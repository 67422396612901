<div
  style="visibility: hidden; position: fixed"
  [style.left.px]="menuTopLeftPosition.x"
  [style.top.px]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent>
    <button *ngFor="let item of items" (click)="item.callback()" mat-menu-item>
      {{ item.text | translate }}
    </button>
  </ng-template>
</mat-menu>
