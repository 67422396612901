import { Component } from '@angular/core';
import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import { StandaloneComponentModule } from '@common/modules';
import { Subject } from 'rxjs';
import { DialogModule } from './dialog.module';

@Component({
  standalone: true,
  imports: [StandaloneComponentModule, DialogModule],
  template: `
    <assets-base-dialog [actions]="actions"> </assets-base-dialog>
    <ng-template #actions>
      <ng-container *ngIf="dialogData.isEdited">
        <button
          type="submit"
          mat-flat-button
          (click)="openEditDialog()"
          [class]="'w-100 assets-btn primary-btn'"
        >
          {{ 'EDIT' | translate }}
        </button>
      </ng-container>
    </ng-template>
  `,
  selector: 'assets-details-dialog',
})
export class DetailsInfoDialog extends BaseDialogComponent<any> {
  editButtonClicked$ = new Subject<Boolean>();

  openEditDialog() {
    this.editButtonClicked$.next(true);
  }
}
