<div
  [class]="
    'assets-form-dropdown dropdown-search assets-form-field ' +
    ('label-' + labelPosition)
  "
>
  <div class="d-flex">
    <span *ngIf="label" class="assets-form-field-label">
      {{ label | translate }}
    </span>
    <span
      *ngIf="hasRequiredField(control)"
      class="ml-2 text-danger font-weight-light"
      >*</span
    >
  </div>
  <mat-form-field appearance="fill">
    <!-- *TODO: need to refine the design
    <mat-label>{{placeholder}}</mat-label> -->
    <mat-select
      #singleSelect
      [formControl]="control"
      [disabled]="loadingSubject.value"
      [panelClass]="{
        'form-dropdown-search-panel__loading':
          !opened || !_options || loadingSubject.value,
        'form-dropdown-search-panel__notransition': true
      }"
      [placeholder]="
        (!loadingSubject.value ? placeholder : 'LOADING') | translate
      "
      [multiple]="multiple"
      (openedChange)="onOpenedChange.next($event)"
      (selectionChange)="selectedValue($event.value)"
    >
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterCtrl"
          [placeholderLabel]="placeholder | translate"
          [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate"
        >
        </ngx-mat-select-search>
      </mat-option>
      <ng-container
        *ngIf="!loadingSubject.value && hasInitValue && optionsSelected"
      >
        <mat-option
          *ngFor="let optionSelected of optionsSelected"
          [hidden]="!opened"
          [value]="optionSelected.id ?? optionSelected.value"
        >
          {{ optionSelected[keyValue] ?? '' | translate }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="loadingSubject.value">
        <!-- use bootstrap class to center flex items -->
        <div class="mat-option justify-content-center">
          {{ 'LOADING' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="filteredOptions | async as options">
        <mat-option
          [hidden]="!opened"
          *ngFor="let option of options"
          [value]="option.id ?? option.value"
        >
          {{ option[keyValue] ?? '' | translate }}
        </mat-option>
      </ng-container>
      <div class="footer-option">
        <ng-content></ng-content>
      </div>
    </mat-select>

    <div
      matSuffix
      class="d-flex justify-content-center"
      *ngIf="!control.disabled"
    >
      <mat-icon (click)="clearValue($event)">clear</mat-icon>
      <mat-icon>expand_more</mat-icon>
    </div>
    <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>

    <!--    hint-->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
