import { Directive } from '@angular/core';
import { EmployeeStatusEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

@Directive({
  selector: '[assetsEmployeeStatusChipResolver]',
})
export class EmployeeStatusChipResolverDirective extends StatusChipResolverDirective<EmployeeStatusEnum> {
  getValues(model: EmployeeStatusEnum) {
    const state = model;
    const classNames = this.getClassNames(state);
    return {
      classNames,
      contentToken: state ?? '',
    };
  }

  getClassNames(state: EmployeeStatusEnum): string[] {
    switch (state.toUpperCase()) {
      case EmployeeStatusEnum.Inactive:
        return ['assets-chip-danger'];
      case EmployeeStatusEnum.Active:
        return ['assets-chip-success'];
      case EmployeeStatusEnum.Available:
        return ['assets-chip-yellow'];
      default:
        return [];
    }
  }
}
