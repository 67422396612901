import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-group',
  template: ` <mat-button-toggle-group
    class="primary"
    name="favoriteColor"
    aria-label="Favorite Color"
    [value]="'active'"
  >
    <mat-button-toggle matTooltip="Активен" value="active"
      >Активен</mat-button-toggle
    >
    <mat-button-toggle matTooltip="Сотрудник в отпуске" value="onVacation"
      >В отпуске</mat-button-toggle
    >
    <mat-button-toggle matTooltip="Сотрудник не активен" value="inactive"
      >Не активен</mat-button-toggle
    >
  </mat-button-toggle-group>`,
  styles: [],
})
export class ButtonGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
