<ng-container
  *appVar="
    ('SEARCH_BY' | translate) + ' ' + (searchBy | translate) as placeholder
  "
>
  <assets-form-input
    class="table-search-input"
    prefix="search"
    [placeholder]="placeholder | truncate: placeholderMaxLength"
    [control]="control"
    [tp]="placeholder"
    [tpIsEnabled]="searchBy.length > placeholderMaxLength"
  />
</ng-container>
