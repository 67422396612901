import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {
  Observable,
  Subject,
  catchError,
  takeUntil,
  throwError,
  EMPTY,
} from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SystemErrorCode } from '../enums/system-error-code.enum';
import { configConfirm } from '../const/dialog.const';
import { GlobalErrorWithReloadDialog } from '@common/modules/core/errors/global-error-with-reload-dialog.component';
import {
  NON_INTERCEPTED_REQUEST_CHECKER,
  NonInterceptedReqChecker,
} from '@common/const/common';

@Injectable()
export class AuthorizeErrorWithRelogin implements HttpInterceptor {
  private cancelRequest$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private matDialog: MatDialog,
    @Inject(NON_INTERCEPTED_REQUEST_CHECKER)
    private nonInterceptedReqChecker: NonInterceptedReqChecker,
  ) {}

  private isAuthorizedErrorResponse(event: HttpEvent<any>): boolean {
    const isUnauthorized =
      event instanceof HttpErrorResponse &&
      event.status === HttpStatusCode.Unauthorized;
    if (!isUnauthorized) {
      return false;
    }

    type AuthorizedError = {
      extensions?: { code?: SystemErrorCode.AuthNotAuthorizedNeedRelogin };
    };
    const errors: AuthorizedError[] = event.error?.errors ?? [];
    const isAuthorizedError = errors.some(
      err =>
        err?.extensions?.code === SystemErrorCode.AuthNotAuthorizedNeedRelogin,
    );

    return isAuthorizedError;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.nonInterceptedReqChecker(req)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(event => {
        if (!this.isAuthorizedErrorResponse(event)) {
          return throwError(() => event);
        } else {
          const config = {
            ...configConfirm,
            data: {
              title: SystemErrorCode.AuthNotAuthorizedNeedRelogin,
            },
          };
          const dialog = this.matDialog.open(
            GlobalErrorWithReloadDialog,
            config,
          );
          dialog.componentInstance.reload$.subscribe(() => {
            this.authService
              .logout()
              .then(result => result && window.location.reload());
          });
          this.cancelRequest$.next();
          return EMPTY;
        }
      }),
      takeUntil(this.cancelRequest$),
    );
  }
}
