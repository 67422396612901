import { inject, Injectable } from '@angular/core';
import { Spinner } from 'ngx-spinner';
import { Mutation, TypedDocumentNode } from 'apollo-angular';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../components/spinner/spinner.service';
import { MutationResult } from 'apollo-angular/types';
import { DocumentNode } from 'graphql';
import { DEFAULT_SPINNER_CONFIG } from '../const/apollo.const';

export type SpinnerOptions = {
  name?: string;
  options?: Spinner;
};

@Injectable({
  providedIn: 'root',
})
export class ApiSpinnerService {
  public spinnerService = inject(SpinnerService);

  loadingWrapper<T>(observable: Observable<T>, spinner?: SpinnerOptions) {
    const mergedOptions = Object.assign(
      { ...DEFAULT_SPINNER_CONFIG },
      spinner?.options || {},
    );
    const loaderName = spinner?.name ?? 'page-host';
    this.spinnerService.show(loaderName, mergedOptions);
    return observable.pipe(
      finalize(() => {
        this.spinnerService.hide(loaderName);
      }),
    );
  }
}

type Result<
  T,
  V,
  D extends Mutation<T, V>['mutate'],
> = ReturnType<D> extends Observable<infer X>
  ? X extends MutationResult<infer Y>
    ? Y
    : never
  : never;
type Variables<T, V, D = Mutation<T, V>['document']> = Exclude<
  D,
  DocumentNode
> extends TypedDocumentNode<any, infer X>
  ? X
  : never;
