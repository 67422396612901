import { inject } from '@angular/core';
import { AuthenticationService } from '@common/services/authentication.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ROUTES } from '@common/const';
import { map } from 'rxjs/operators';

export const publicGuard = (route: ActivatedRouteSnapshot) => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  return auth.isAuthorized().pipe(
    map(isAuthorized => {
      if (isAuthorized) {
        router.navigate([ROUTES.root]); //  authorized so redirect to home
        return false;
      }
      return true; // not authorized so let user in to get logged in
    }),
  );
};
