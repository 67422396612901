import { Directive } from '@angular/core';
import { ActionEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = ActionEnum;

@Directive({
  selector: '[appHistoryLogActionChipResolver]',
})
export class HistoryLogActionChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const action = model;

    const classNames = action
      ? action === ActionEnum.Delete
        ? ['assets-chip-danger']
        : action === ActionEnum.Create
        ? ['assets-chip-success']
        : ['assets-chip-yellow']
      : [];

    return {
      classNames,
      contentToken: action ?? '',
    };
  }
}
