import { Injectable } from '@angular/core';
import { BaseApolloService } from '@common/services/base.apollo.service';
import { map } from 'rxjs/operators';
import {
  AllRolesQuery,
  UsersByFilterForOptionsGQL,
  UsersByFilterForOptionsQuery,
  UsersByFilterForOptionsQueryVariables,
} from '@common/generated/graphql';
import { GqlItem } from '@common/types';

export type MentionItem = {
  value: string;
  link: string;
  id: string;
  name: string;
};

@Injectable({
  providedIn: 'root',
})
export class MentionsService extends BaseApolloService {
  getUsersForMention = (params?: UsersByFilterForOptionsQueryVariables) => {
    return this.query(UsersByFilterForOptionsGQL, params ?? {}).pipe(
      map(response => {
        return response.data?.usersByFilter?.items?.map(item => {
          const user = this.reformUser(item);
          return {
            ...user,
            value: user.name?.replace(/\@.+/, ''),
            link: `mailto:${item?.userName}`,
          };
        });
      }),
    );
  };

  reformUser(user: GqlItem<UsersByFilterForOptionsQuery>) {
    return {
      id: user?.id!,
      name:
        user?.firstName && user?.lastName
          ? `${user.firstName} ${user.lastName}`
          : `${user?.userName}`,
    };
  }
}
