import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-snackbar',
  template: `
    <div [class]="'assets-alert ' + data.status + '-alert'">
      <ng-container [ngSwitch]="data.status">
        <ng-template ngSwitchCase="success">
          <mat-icon class="prefix icon-lg">check_circle</mat-icon>
        </ng-template>
        <ng-template ngSwitchCase="warn">
          <mat-icon class="prefix icon-lg">warning</mat-icon>
        </ng-template>
        <ng-template ngSwitchCase="error">
          <mat-icon class="prefix icon-lg">error</mat-icon>
        </ng-template>
        <ng-template ngSwitchDefault>
          <mat-icon class="prefix icon-lg">info</mat-icon>
        </ng-template>
      </ng-container>
      <span>{{ data.message | translate }}</span>
      <button
        mat-icon-button
        class="icon-btn-lg"
        (click)="_snackBarRef.dismiss()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  constructor(
    public _snackBarRef: MatSnackBarRef<any>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  ngOnInit(): void {}
}
