import { Component } from '@angular/core';
import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'global-error-with-reload-dialog',
  templateUrl: './global-error-with-reload-dialog.component.html',
  styleUrls: ['./global-error-with-reload-dialog.component.scss'],
})
export class GlobalErrorWithReloadDialog extends BaseDialogComponent<any> {
  reload$ = new Subject<Boolean>();

  reload() {
    this.reload$.next(true);
  }
}
