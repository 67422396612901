import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NOT_FOUND, UNAUTHORIZED } from './const';

export const NOT_FOUND_ROUTE = {
  path: NOT_FOUND,
  loadComponent: () => import('./views/not-found/not-found.component'),
};

export const UNAUTHORIZED_ROUTE = {
  path: UNAUTHORIZED,
  loadComponent: () => import('./views/unauthorized/unauthorized.component'),
};

export const FALLBACK_ROUTE = {
  path: '**',
  redirectTo: NOT_FOUND,
};

@NgModule({
  imports: [RouterModule.forChild([NOT_FOUND_ROUTE, FALLBACK_ROUTE])],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
