<div class="locate-between">
  <button class="mat-button">
    <img
      [matMenuTriggerFor]="menu"
      [appAvatar]="authService.currentUser?.employee?.shortImage ?? ''"
      imageSize="extra-small"
      defaultImage="avatar1.jpg"
    />
  </button>
</div>
<mat-menu #menu="matMenu">
  <div class="mt-3 ml-3 mr-3">
    <div class="d-flex">
      <img
        [appAvatar]="authService.currentUser?.employee?.shortImage ?? ''"
        imageSize="extra-small"
        defaultImage="avatar1.jpg"
      />
      <div class="ml-2">
        <a
          [routerLink]="[Routes.SETTINGS.root]"
          [class.disabled-link]="disabled"
        >
          {{ authService.currentUser | userName }}
        </a>
      </div>
    </div>
    <hr class="mat-toolbar mt-2" />
    <button
      mat-flat-button
      class="assets-button-sm ghost-btn w-100"
      (click)="authService.logout()"
    >
      {{ 'SIGN_OUT' | translate }}
    </button>
  </div>
</mat-menu>
