import { inject, Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router,
  Event,
  NavigationBehaviorOptions,
} from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, take } from 'rxjs';

export type ReturnUrl = {
  url: string;
  param: string;
};

@Injectable({
  providedIn: 'root',
})
export class ReturnUrlService {
  private readonly returnUrlSubject = new BehaviorSubject<ReturnUrl | null>(
    null,
  );
  private readonly returnUrlStack: ReturnUrl[] = [];
  public readonly returnUrl$ = this.returnUrlSubject.asObservable();

  getReturnUrl(id?: string | number): string | null {
    const returnUrl = this.returnUrlSubject.value;

    if (!returnUrl) {
      return null;
    }
    const queryParams =
      returnUrl.param && id ? `?${returnUrl.param}=${id}` : '';
    const url = returnUrl.url + queryParams;

    return url;
  }

  resetWhenNavigationEnd() {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        take(1),
      )
      .subscribe(() => {
        this.returnUrlStack.pop();
        this.returnUrlSubject.next(this.returnUrlStack.at(-1) ?? null);
      });
  }

  private router = inject(Router);

  setReturnUrl(url: ReturnUrl | null) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        take(1),
      )
      .subscribe(() => {
        if (url) {
          this.returnUrlStack.push(url);
        }
        this.returnUrlSubject.next(url);
      });
  }

  static readonly IS_RETURN_BACK_URL = 'IS_RETURN_BACK_URL';
  static readonly EXTRAS_STATE = {
    [ReturnUrlService.IS_RETURN_BACK_URL]: true,
  };
}
