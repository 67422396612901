export enum Time {
  SECOND = 1000,
  SECONDS_IN_MINUTE = 60,
  MINUTE = SECONDS_IN_MINUTE * SECOND,
  MINUTES_IN_HOUR = 60,
  SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR,
  HOUR = MINUTES_IN_HOUR * MINUTE,
  HOURS_IN_DAY = 24,
  DAY = HOURS_IN_DAY * HOUR,
}
