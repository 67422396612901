import { Component } from '@angular/core';
import { WrapperModule } from '@common/components/wrapper/wrapper.module';

@Component({
  standalone: true,
  selector: 'app-auth-layout',
  template: '<app-auth-wrapper></app-auth-wrapper>',
  imports: [WrapperModule],
})
export default class AuthLayoutComponent {}
