import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  MatLegacyColumnDef as MatColumnDef,
  MatLegacyHeaderRowDef as MatHeaderRowDef,
  MatLegacyRowDef as MatRowDef,
  MatLegacyTable as MatTable,
} from '@angular/material/legacy-table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { GqlTableDataSource } from '@common/classes/gql.table.data-source';
import { TablePaginator } from '@common/classes/table-paginator';
import { ReturnUrlService } from '@common/services/return-url.service';
import { TableFilterService } from '@common/services/table-filter.service';
import { Query } from 'apollo-angular';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { CLICKABLE_LINKS } from '../link/link.component';
import { TableContextDirective } from './table-context.directive';
import { GqlTableService } from './gql.table.service';

export const CLICKABLE_ROWS = new InjectionToken<boolean>('CLICKABLE_ROWS');

/**
 * TableComponent
 * ========================================
 * This component is a wrapper for "mat-table".
 * The data source for the table is provided through the dataService input field, which accepts a table service inherited from GqlTableService.
 * The table service is actually the GqlTableService configurator.
 * Table's column templates (mat-cell, mat-row etc) are passed via the TableComponent and added dynamically to MatTable definitions.
 */

/**
 * GqlTableService
 * ========================================
 * All table services are inherited from GqlTableService.
 * The GqlTableService is meant to serve as a place to encapsulate any sorting, filtering, pagination, and data retrieval logic
 * as well as misc features (columns reordering, hiding etc).
 */

/**
 * Features
 * ========================================
 * Columns reordering
 * ----------------------------------------
 * This feature is provided by TableDropListDirective and TableDragElementDirective.
 *
 * This component has two input fields related to reordering columns.
 * The first field allowColumnReordering disables column reordering.
 * The second field persistColumns disables the persistence of columns settings.
 */

@Component({
  selector: 'assets-table',
  templateUrl: 'table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<
    T,
    QV,
    GQL extends Query,
    F extends UntypedFormGroup,
  >
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  protected readonly destroy$ = new Subject<void>();
  protected readonly currentNavigationState = inject(Location).getState();
  selection = new SelectionModel<T>(true);
  tablePaginator = new TablePaginator();
  protected contextDirective = inject(TableContextDirective, {
    optional: true,
  });
  protected scrollPosition: number = 0;

  private readonly tableFilterService = inject(TableFilterService);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly dropList = inject(CdkDropList, { optional: true });

  @Input() data: T[];
  @Input() dataService?: GqlTableService<GQL, F, T, QV, any>;
  @Input() hasPaginator = true;
  @Input() term: UntypedFormControl;
  @Input() enableCheckbox: boolean = true;
  @Input() allowMultiSelect: boolean = true;
  @Input() allowColumnReordering?: boolean = true;
  @Input() persistentColumns?: boolean = true;
  @Input() linkedRows: boolean =
    inject(CLICKABLE_ROWS, { optional: true }) ??
    inject(CLICKABLE_LINKS, { optional: true }) ??
    true;
  @Input({ transform: booleanAttribute }) hasFooter: boolean = false;
  @Input() dataSource: GqlTableDataSource<T>;
  @Input() displayColumns: string[];
  @Input() sort: MatSort; // TODO redo with directives composition after upgrade to v15
  @Input() idKey: string = 'id';

  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true, read: MatTable }) table: MatTable<T>;
  @ViewChild(MatTable, { static: true, read: ElementRef }) tableEl: ElementRef;
  @ViewChild('tableWrapper', { read: ElementRef })
  tableWrapperEl: ElementRef<HTMLDivElement>;

  @Output() getSelectedRows = new EventEmitter();

  ngAfterContentInit() {
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef =>
      this.table.addHeaderRowDef(headerRowDef),
    );
    const columnNames: string[] = [];
    this.columnDefs.forEach(columnDef => {
      columnNames.push(columnDef?.name);
      this.table.addColumnDef(columnDef);
    });

    if (!this.dataService?.displayedColumns) {
      this.dataService?.setDisplayedColumns(columnNames);
    }
  }

  ngOnInit() {
    if (!this.data) {
      this.tableFilterService.setFilterComponent(
        this.dataService?.filterComponent ?? null,
      );
      this.sort?.sort(<MatSortable>{
        ...this.dataService?.getSortChange(),
        disableClear: true,
      });
      this.dataService
        ?.connect()
        .pipe(takeUntil(this.destroy$))
        .subscribe(_ => this.selection.deselect(...this.selection.selected));
    }

    if (!this.dataSource) {
      this.dataSource = this.dataService as unknown as GqlTableDataSource<T>;
    }

    this.dataService?.restoreTableColumns();
    if (this.dropList) {
      this.dropList.disabled = !this.allowColumnReordering;
    }
  }

  ngAfterViewInit() {
    this.selection = new SelectionModel<T>(this.allowMultiSelect);
    const isReturnBackNavigation =
      ReturnUrlService.IS_RETURN_BACK_URL in
      (this.currentNavigationState as any);

    if (!this.data) {
      if (this.hasPaginator) {
        this.dataService?.setPaginator(this.paginator, isReturnBackNavigation);
      }

      if (this.sort) {
        this.dataService?.setSort(this.sort);
      }

      if (isReturnBackNavigation || this.dataService?.isPopstateNavigation) {
        this.dataService
          ?.connect()
          .pipe(skip(1), takeUntil(this.destroy$))
          .subscribe(data => {
            this.cdRef.detectChanges();
            this.tableWrapperEl.nativeElement.scrollTo({
              top: this.dataService?.getScrollPosition(),
            });
          });
      }

      this.dataService?.loadGqlData();
    }
  }

  dropColumn(event: CdkDragDrop<string[]>) {
    this.dataService?.dropColumn(event, this.persistentColumns);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
    this.getSelectedRows.emit(this.selection.selected);
  }
  rowSelect(row: T) {
    this.selection.toggle(row);
    this.getSelectedRows.emit(this.selection.selected);
  }

  onScroll() {
    this.scrollPosition = this.tableWrapperEl.nativeElement.scrollTop;
  }

  ngOnDestroy() {
    this.tableFilterService.setFilterComponent(null);
    this.dataService?.setScrollPosition(this.scrollPosition);
    this.destroy$.next();
    this.destroy$.complete();
    this.dataService?.disconnect();
  }

  exportAsXLSX() {
    const fileName = 'demo.xlsx';
    let wb: XLSX.WorkBook;
    let ws: XLSX.WorkSheet;
    if (this.selection.selected.length > 0) {
      ws = XLSX.utils.json_to_sheet(this.selection.selected);
      wb = XLSX.utils.book_new();
    } else {
      ws = XLSX.utils.table_to_sheet(this.tableEl.nativeElement);
      wb = XLSX.utils.book_new();
    }
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  protected trackById = (_index: number, item: T) => {
    return (item as { [K: string]: string | number })[this.idKey];
  };
}
