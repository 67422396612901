import { Component } from '@angular/core';
import { StandaloneComponentModule } from '@common/modules';

@Component({
  standalone: true,
  selector: 'assets-page-tab-actions',
  imports: [StandaloneComponentModule],
  templateUrl: './page-tab-actions.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 1rem 0 1rem 0;
      }
    `,
  ],
})
export class PageTabActionsComponent {}
