import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FullScreenService {
  get isOn() {
    return this.#isOn;
  }
  #isOn = false;

  toggle() {
    this.#isOn = !this.#isOn;
  }
}
