import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DialogModule } from '@common/components/dialog/dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@common/directives/directives.module';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FormModule } from '../form/form.module';

@Component({
  standalone: true,
  selector: 'assets-report-issue-dialog',
  imports: [
    FormModule,
    DialogModule,
    MatIconModule,
    TranslateModule,
    DirectivesModule,
    MatLegacyButtonModule,
  ],
  template: `
    <assets-base-dialog [actions]="actions" [dialogTitle]="'REPORT_AN_ISSUE'">
      <assets-rich-text [control]="commentControl" />
    </assets-base-dialog>
    <ng-template #actions>
      <button
        mat-flat-button
        color="primary"
        class="ml-auto"
        (click.stop)="onSubmit()"
        [disabled]="commentControl?.invalid"
      >
        {{ 'SEND' | translate }}
      </button>
    </ng-template>
  `,
  styles: [
    `
      app-rich-text {
        min-height: 25rem;
      }
    `,
  ],
})
export default class ReportIssueDialogComponent extends BaseDialogComponent<any> {
  commentControl: FormControl = new FormControl('', Validators.required);

  override onSubmit() {
    this.dialogRef.close(this.commentControl.value);
  }
}
