<mat-card>
  <div class="container p-0">
    <mat-card-title class="d-flex align-items-center flex-column">
      <img alt="logo" src="assets/images/kanda_logo.svg" />
      <h2 class="mt-3">{{ modeToTitleMap[authMode] | translate }}</h2>
    </mat-card-title>

    <ng-container [ngSwitch]="authMode">
      <ng-template [ngSwitchCase]="AuthMode.Login">
        <mat-card-content>
          <form (ngSubmit)="submitHandler()" class="auth-form">
            <assets-form-input
              [control]="loginForm.login"
              label="LOGIN"
              id="username"
              autocomplete="username"
              placeholder="ENTER_LOGIN"
            >
            </assets-form-input>

            <assets-form-input
              [control]="loginForm.password"
              type="password"
              label="PASSWORD"
              placeholder="ENTER_PASSWORD"
            >
            </assets-form-input>
            <button #submit type="submit" class="d-none"></button>
          </form>
        </mat-card-content>
        <mat-card-actions class="p-2">
          <button
            mat-flat-button
            [disabled]="!loginForm.valid"
            class="assets-btn primary-btn submit"
            (click)="submit.click()"
          >
            {{ 'LOGIN' | translate }}
          </button>
          <div class="text-center link">
            <a
              href="#"
              (click)="setAuthModeValue(AuthMode.ForgotPassword, $event)"
              >{{ 'FORGOT_PASSWORD' | translate }}</a
            >
          </div>
        </mat-card-actions>
        <asl-google-signin-button
          text="signin_with"
          *ngIf="isGoogleSignInEnabled"
          [locale]="translateService.currentLang || i18nConfig.defaultLang"
          type="standard"
          shape="square"
          [width]="$any(400)"
          logo_alignment="center"
          size="medium"
        >
        </asl-google-signin-button>
      </ng-template>

      <ng-template [ngSwitchCase]="AuthMode.ForgotPassword">
        <mat-card-content>
          <form class="auth-form">
            <assets-form-input
              [control]="loginForm.login"
              label="EMAIL"
              id="email"
              autocomplete="email"
              placeholder="ENTER_EMAIL"
            >
            </assets-form-input>
          </form>
        </mat-card-content>
        <mat-card-actions class="p-2 mb-0">
          <button
            mat-flat-button
            class="assets-btn primary-btn submit"
            [disabled]="loginForm.login.invalid || disabledSendButton"
            (click)="sendPasswordRecoveryEmail()"
          >
            {{ 'SEND' | translate }}
          </button>
          <div class="text-center link">
            <a href="#" (click)="setAuthModeValue(AuthMode.Login, $event)">{{
              'GO_TO_LOGIN' | translate
            }}</a>
          </div>
        </mat-card-actions>
      </ng-template>

      <ng-template [ngSwitchCase]="AuthMode.TwoStepVerification">
        <mat-card-content>
          <div class="mb-4">
            {{ '2FA_CODE_SENT' | translate }}
          </div>
          <form (ngSubmit)="submitHandler()" class="auth-form">
            <assets-form-input
              [control]="loginForm.code"
              [maxLength]="6"
              class="mt-2"
              label="CODE"
              placeholder="⚹⚹⚹⚹⚹⚹"
            >
            </assets-form-input>
            <mat-checkbox
              class="mb-2 w-100"
              [formControl]="loginForm.trustDevice"
            >
              {{ 'TRUST_THIS_DEVICE' | translate }}
            </mat-checkbox>
            <div class="p-2 mb-0">
              <button
                mat-flat-button
                [disabled]="loginForm.invalid || !loginForm.dirty"
                class="assets-btn primary-btn submit"
              >
                {{ 'SUBMIT' | translate }}
              </button>
              <div class="text-center link my-4">
                <a href="#" (click)="resend2FACodeMessage($event)">{{
                  'RESEND_CODE' | translate
                }}</a>
              </div>
              <div class="text-center link">
                <a href="#" (click)="resetLoginForm(AuthMode.Login)">{{
                  'GO_TO_LOGIN' | translate
                }}</a>
              </div>
            </div>
          </form>
        </mat-card-content>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <mat-progress-bar
        class="progress-bar-bottom"
        mode="indeterminate"
      ></mat-progress-bar>
    </ng-container>
  </div>
</mat-card>
