import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@common/modules/material.module';
import { ButtonGroupComponent } from '@common/components/buttons/button-group.component';

@NgModule({
  declarations: [ButtonGroupComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ButtonGroupComponent],
})
export class ButtonsModule {}
