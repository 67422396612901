import { EMPTY, Observable, switchMap } from 'rxjs';
import ReportIssueDialogComponent from '@common/components/dialog/report-issue-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default abstract class ReportIssueService {
  matDialog = inject(MatDialog);

  open() {
    const dialogRef = this.matDialog.open(ReportIssueDialogComponent, {
      width: '60rem',
      height: '45rem',
    });

    return dialogRef.afterClosed().pipe(
      switchMap(response => {
        if (response) {
          return this.reportIssue(response);
        }
        return EMPTY;
      }),
    );
  }

  abstract reportIssue(
    message: string,
  ): Observable<number | null | undefined | void>;
}
