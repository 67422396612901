import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared.routing';
import { MaterialModule } from './modules/material.module';
import { SnackbarComponent } from './components/snackbars/snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { BasePageComponent } from './components/base-page/base-page.component';
import { StatusChipComponent } from '@common/components/status-chip/status-chip.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@common/directives/directives.module';
import { RequisiteComponent } from './components/requisites/requisite/requisite.component';
import { RequiredSkillsTableComponent } from './components/required-skills-table/required-skills-table.component';
import { TextSearchModule } from './components/filters/text-search/text-search.module';
import { TextSearchComponent } from './components/filters/text-search/text-search.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ChangeImageComponent } from './components/change-image/change-image/change-image.component';
import { QuillModule } from 'ngx-quill';
import { MyCandidateIconComponent } from '@common/components/my-candidate-icon/my-candidate-icon.component';
import { ReturnBackUrlDirective } from './directives/return-back-url.directive';
import { ReturnBackUrlBlockDirective } from './directives/return-back-url-block.directive';
import { LogsComponent } from '@common/components/logs/logs.component';
import { FormModule } from './components/form/form.module';
import { PipesModule } from '@common/pipes/pipes.module';

@NgModule({
  declarations: [
    SnackbarComponent,
    BasePageComponent,
    StatusChipComponent,
    MyCandidateIconComponent,
    LogsComponent,
    RequisiteComponent,
    RequiredSkillsTableComponent,
    ContextMenuComponent,
    ChangeImageComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    DirectivesModule,
    FormModule,
    TextSearchModule,
    ReactiveFormsModule,
    QuillModule,
    ReturnBackUrlDirective,
    ReturnBackUrlBlockDirective,
    PipesModule,
  ],
  exports: [
    StatusChipComponent,
    MyCandidateIconComponent,
    LogsComponent,
    RequisiteComponent,
    RequiredSkillsTableComponent,
    TextSearchComponent,
    ContextMenuComponent,
    ChangeImageComponent,
    ReturnBackUrlDirective,
    ReturnBackUrlBlockDirective,
  ],
})
export class SharedModule {}
