import { Directive } from '@angular/core';
import { RequisitionStatusEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = RequisitionStatusEnum;

@Directive({
  selector: '[appRequisitionStatusChipResolver]',
})
export class RequisitionStatusChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const state = model;
    const classNames = this.getClassNames(state);

    return {
      classNames,
      contentToken: state ?? '',
    };
  }

  getClassNames(state: RequisitionStatusEnum): string[] {
    switch (state) {
      case RequisitionStatusEnum.CanceledByClient:
      case RequisitionStatusEnum.CanceledByKanda:
        return ['assets-chip-danger'];
      case RequisitionStatusEnum.Filled:
        return ['assets-chip-success'];
      case RequisitionStatusEnum.Open:
        return ['assets-chip-yellow'];
      case RequisitionStatusEnum.OnHold:
        return ['mat-chip-grey'];
      default:
        return [];
    }
  }
}
