import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styles: [
    `
      .table-search-input {
        display: block;
        width: 29rem;
      }
    `,
  ],
})
export class TextSearchComponent {
  @Input() control: FormControl<string>;
  @Input() searchBy = 'NAME';
  readonly placeholderMaxLength = 35;
}
