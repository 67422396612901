<div [class]="'assets-form assets-form-field ' + ('label-' + labelPosition)">
  <span class="assets-form-field-label">
    {{ label | translate }}
    <span
      *ngIf="hasRequiredField(control)"
      class="text-danger font-weight-light"
      >*</span
    >
    <mat-icon *ngIf="hintText" [tp]="hintText | translate" class="help-hint"
      >help</mat-icon
    >
  </span>
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    [class.text-center]="centeredText"
    [hideRequiredMarker]="true"
  >
    <div class="d-flex align-items-center">
      <input
        #input
        inputRef
        *ngIf="mask; else nomask"
        matInput
        [type]="type"
        [formControl]="control"
        [patterns]="patterns"
        [mask]="mask.maskExpression"
        [leadZero]="mask.leadZero"
        [separatorLimit]="mask.separatorLimit"
        [thousandSeparator]="mask.thousandSeparator"
        [allowNegativeNumbers]="mask.allowNegativeNumbers"
        [readonly]="readonly"
      />
      <ng-template #nomask>
        <input
          #input
          inputRef
          matInput
          [readonly]="readonly"
          [type]="type"
          [formControl]="control"
        />
      </ng-template>
      <button
        *ngIf="withClear"
        matSuffix
        mat-icon-button
        type="button"
        [hidden]="control.disabled || readonly"
        (click.stop)="clear()"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <ng-container *ngIf="isPassword">
        <mat-icon
          class="icon-display"
          (mousedown)="changeInputType(true)"
          (mouseup)="changeInputType(false)"
        >
          remove_red_eye
        </mat-icon>
      </ng-container>
    </div>
    <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>

    <!--    hint-->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

    <mat-icon *ngIf="suffix" matSuffix>{{ suffix }}</mat-icon>

    <mat-icon *ngIf="prefix" matPrefix>{{ prefix }}</mat-icon>
  </mat-form-field>
</div>
