import { Directive, HostListener, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ReturnUrlService, ReturnUrl } from '../services/return-url.service';
import { ReturnBackUrlBlockDirective } from './return-back-url-block.directive';

@Directive({
  selector: `[assetsReturnBackUrl][routerLink],
  [assetsReturnBackUrlWithKey][routerLink],
  a[routerLink],
  mat-row[routerLink],
  button[routerLink],
  `,
  standalone: true,
})
export class ReturnBackUrlDirective {
  private returnUrlService = inject(ReturnUrlService);
  private router = inject(Router);
  private blockDirective = inject(ReturnBackUrlBlockDirective, {
    optional: true,
  });

  @Input('assetsReturnBackUrlWithKey') keyId: string;

  @HostListener('click') onClick() {
    if (!(this.blockDirective?.enabled ?? true)) {
      return;
    }

    const urlTree = this.router.parseUrl(this.router.url);
    delete urlTree.queryParams[this.keyId];

    const returnUrl: ReturnUrl = {
      url: urlTree.toString(),
      param: this.keyId,
    };

    this.returnUrlService.setReturnUrl(returnUrl);
  }
}
