import { LanguageConfig } from '../components/i18n';
import { LANG_STORE_KEY, RU, EN } from './language.const';

export const I18N_CONFIG = Object.freeze<LanguageConfig>({
  defaultLang: EN,
  languages: [RU, EN],
  languageOptions: [
    { value: EN, label: 'en' },
    { value: RU, label: 'ру' },
    // {value: KG, label: 'кг'}
  ],
  storeKey: LANG_STORE_KEY,
});
