<header>
  <div
    class="d-flex align-items-center justify-content-between header"
    role="button"
  >
    <div class="d-flex justify-content-center">
      <div
        class="mat-button d-flex align-items-center"
        (click)="isShowMenu('menu')"
      >
        <mat-icon class="material-icons-round icon-lg icon-color"
          >menu</mat-icon
        >
      </div>
      <div (click)="router.navigate([''])">
        <img
          alt="logo"
          src="assets/images/logo.svg"
          [ngStyle]="{ height: '2.3rem' }"
        />
        <div class="ml-2 d-inline-block mt-2">
          <p [ngStyle]="{ 'font-size': '1.5rem' }" class="m-0">Assets</p>
        </div>
      </div>
    </div>
    <!--    <div>-->
    <!--      <app-breadcrumb></app-breadcrumb>-->
    <!--    </div>-->
    <div class="d-flex align-items-center justify-content-end">
      <app-i18n-dropdown></app-i18n-dropdown>
      <app-avatar-bar></app-avatar-bar>

      <ng-container *ngIf="router.url !== '/dashboard'">
        <div
          class="mat-button d-flex align-items-center"
          (click)="isShowMenu('filter')"
        >
          <mat-icon class="material-icons-round icon-lg icon-color"
            >search</mat-icon
          >
        </div>
      </ng-container>
    </div>
  </div>
</header>
