<div class="breadcrumbs">
  <div *ngFor="let breadcrumb$ of breadcrumbs$; let i = index" class="d-flex">
    <ng-container *ngIf="breadcrumb$ | async as breadcrumb">
      <ng-container *ngIf="breadcrumb.label?.prefix; else aliasButton">
        <a
          class="breadcrumb"
          assetsBlockedReturnBackUrl
          mat-flat-button
          [routerLink]="breadcrumb.url"
        >
          {{ breadcrumb.label?.prefix ?? '' | translate }}
        </a>
        <span class="m-1">/</span>
      </ng-container>
      <ng-template #aliasButton>
        <a
          class="breadcrumb"
          assetsBlockedReturnBackUrl
          mat-flat-button
          [routerLink]="breadcrumb.url"
          [routerLinkActive]="'router-link-active'"
        >
          {{ breadcrumb.label?.alias?.toUpperCase() ?? '' | translate }}
        </a>
        <span class="m-1">/</span>
      </ng-template>
    </ng-container>
  </div>
</div>
