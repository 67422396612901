import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  booleanAttribute,
} from '@angular/core';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';

@Component({
  selector: 'assets-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['form-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormTextareaComponent
  extends FormInputComponent
  implements OnInit
{
  @Input({ transform: booleanAttribute }) autosize = true;
  @Input() autosizeMaxRows = 2;
  @Input({ transform: booleanAttribute }) hiddenScroll = false;
}
