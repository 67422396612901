import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handling';
import { GlobalErrorWithReloadDialog } from './global-error-with-reload-dialog.component';
import { DialogModule } from '@common/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GlobalErrorWithReloadDialog],
  imports: [CommonModule, DialogModule, TranslateModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class GlobalErrorHandlerModule {}
