import { Component, inject, Input } from '@angular/core';
import { CandidateInfo, Maybe, UserRoleInfo } from '@common/generated/graphql';
import { AuthenticationService } from '@common/services/authentication.service';

@Component({
  selector: 'app-my-candidate-icon',
  template: `<mat-icon>{{ icon }}</mat-icon>`,
})
export class MyCandidateIconComponent {
  private authService = inject(AuthenticationService);
  @Input() set model(value: CandidateInfo) {
    this.setIcon(value);
  }
  icon: string;

  setIcon(candidate: CandidateInfo) {
    this.icon = this.isMyCandidate(candidate)
      ? 'radio_button_checked'
      : 'radio_button_unchecked';
  }

  isMyCandidate(candidate: CandidateInfo): boolean | void {
    return this.authService.currentUser?.userRoles?.some(
      (role: Maybe<UserRoleInfo>) => role?.type === candidate.queue,
    );
  }
}
