export const REFERENCES_PERMISSIONS = {
  divisions: { module: 'Division', action: 'GetAllDivisions' },
  jobTitles: { module: 'JobTitle', action: 'GetAllJobTitles' },
  levels: { module: 'Level', action: 'GetAllLevels' },
  guilds: { module: 'Guild', action: 'GetAllGuilds' },
  seniorities: { module: 'Seniority', action: 'GetAllSeniorities' },
  skills: { module: 'Skill', action: 'GetAllSkills' },
  contractualTypes: {
    module: 'ContractualType',
    action: 'GetAllContractualTypes',
  },
  skillSets: { module: 'SkillSet', action: 'GetAllSkillSets' },
  englishLevels: { module: 'EnglishLevel', action: 'GetAllEnglishLevels' },
  currencies: { module: 'Currency', action: 'GetAllCurrencies' },
  rateTypes: { module: 'RateType', action: 'GetAllRateTypes' },
  rateRegions: { module: 'Region', action: 'GetAllRegions' },
  geographicalRegions: {
    module: 'GeographicalRegion',
    action: 'GetAllGeographicalRegions',
  },
  countries: { module: 'Country', action: 'GetAllCountries' },
  timeZones: { module: 'TimeZone', action: 'GetAllTimeZones' },
  workStatuses: { module: 'WorkState', action: 'GetAllWorkStates' },
  rejectionReasons: {
    module: 'RejectionReason',
    action: 'GetAllRejectionReasons',
  },
  withdrawalReasons: {
    module: 'WithdrawalReason',
    action: 'GetAllWithdrawalReasons',
  },
  onboardingChecklist: {
    module: 'OnboardingChecklist',
    action: 'GetAllOnboardingChecklists',
  },
  industries: {
    module: 'Industry',
    action: 'GetAllIndustries',
  },
};
