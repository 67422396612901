import { inject, InjectionToken } from '@angular/core';
import { PageIdGetter, PageTabsConfig } from './types';

export const PAGE_IDENTITY_KEY = new InjectionToken<string>(
  'Page identity key',
);
export const PAGE_BREADCRUMB_MAP_FN = new InjectionToken<string>(
  'Page breadcrumb map function',
);
export const PAGE_REQUISITE_MAP_FN = new InjectionToken<string>(
  'Page requisite map function',
);
export const PAGE_TABS_CONFIG = new InjectionToken<PageTabsConfig>(
  'Page tabs config',
);
export const PAGE_ID_GETTER = new InjectionToken<PageIdGetter>(
  'Page id getter',
);
export const PAGE_TABS_MARKER = new InjectionToken<boolean>('Page tabs marker');
export const pageIdGetter = () =>
  inject(PAGE_ID_GETTER, { optional: true })?.() ?? null;

export class EditPageProto {}
