import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mat-flat-button]',
})
export class AppButtonDirective {
  @Input() outline: BooleanInput = false;

  @HostBinding('class') get classes() {
    return {
      'assets-btn': true,
      'primary-btn': true,
      'primary-btn-outline': coerceBooleanProperty(this.outline),
    };
  }
}
