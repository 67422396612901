import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StandaloneFormComponentModule } from '@common/modules/standalone-form-component.module';
import { FormControl } from '@angular/forms';
import { Observable, Subject, filter, takeUntil, withLatestFrom } from 'rxjs';
import { ISelectOption } from '@common/models';
import {
  FormDropdownSearchComponent,
  Key,
} from '@common/components/form/form-dropdown-search/form-dropdown-search.component';

@Component({
  selector: 'assets-form-dropdown-with-chips',
  standalone: true,
  imports: [StandaloneFormComponentModule],
  templateUrl: './form-dropdown-with-chips.component.html',
  styleUrls: ['./form-dropdown-with-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDropdownWithChipsComponent implements OnInit, OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  @Input() control: FormControl<(number | string)[] | null>;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() key: Key = 'name';
  @Input('initOption') set initOptionValue(
    initOption:
      | ISelectOption<string | number>
      | ISelectOption<string | number>[],
  ) {
    initOption =
      Array.isArray(initOption) || !initOption ? initOption : [initOption];
    this.options = initOption;
  }
  protected initOption:
    | ISelectOption<string | number>
    | ISelectOption<string | number>[];
  @Input() fetchMethod?: () => Observable<
    FormDropdownSearchComponent<string | number>['_options']
  >;

  @ViewChild(FormDropdownSearchComponent, { static: true })
  dropdown: FormDropdownSearchComponent<string | number>;

  protected optionsForDropdown: ISelectOption<number | string>[];
  @Input() set options(value: ISelectOption<number | string>[]) {
    this.optionsForDropdown = value;
    this.optionsForDropdown?.forEach(option => {
      this.optionsMap.set(option.id!, { name: option[this.key] });
    });
  }
  optionsMap = new Map<number | string, ISelectOption<number | string>>();
  selectedIds: Set<number | string>;

  @Output() onSelected = new EventEmitter<(number | string)[] | null>();

  ngOnInit(): void {
    this.selectedIds = new Set<number | string>(this.control.value ?? []);

    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(ids => {
      this.selectedIds = new Set<number | string>(ids);
    });

    this.dropdown.loading$
      .pipe(
        filter(loading => !loading),
        withLatestFrom(this.dropdown.filteredOptions),
        takeUntil(this.destroy$),
      )
      .subscribe(([, filteredOptions]) => {
        this.options = filteredOptions ?? [];
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  remove(id: number | string) {
    this.selectedIds.delete(id);
    this.control.setValue([...this.selectedIds]);
    this.control.markAsDirty();
  }

  trackById(index: number, id: number | string) {
    return id;
  }
}
