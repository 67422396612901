<div class="assets-form assets-form-field" *ngIf="control && innerControl">
  <assets-form-upload
    [required]="required"
    [control]="innerControl"
    [accept]="accept"
    [label]="label"
    [clearHidden]="clearHidden"
    (file)="onFileChange($event)"
  >
    <ng-template #name *ngIf="control.controls!.id!.value">
      {{ control.controls.name.value }}
    </ng-template>
  </assets-form-upload>
  <ng-container *ngIf="control?.invalid">
    <mat-error *ngIf="control.errors?.emptyFile as error">{{
      error | translate
    }}</mat-error>
  </ng-container>
</div>
