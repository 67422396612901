import { Directive, Input } from '@angular/core';

@Directive({
  selector: `[assetsBlockedReturnBackUrl]`,
  standalone: true,
})
export class ReturnBackUrlBlockDirective {
  @Input({
    alias: 'assetsBlockedReturnBackUrl',
  })
  enabled: boolean | string = true;
}
