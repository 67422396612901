import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@common/pipes/pipes.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { DirectivesModule } from '@common/directives/directives.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormDropdownComponent } from './form-dropdown/form-dropdown.component';
import { FormDropdownSearchComponent } from './form-dropdown-search/form-dropdown-search.component';
import { FormFileInputComponent } from './form-file-input/form-file-input.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormUploadComponent } from './form-upload/form-upload.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QuillModule } from 'ngx-quill';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TippyDirective } from '@ngneat/helipopper';

@NgModule({
  declarations: [
    FormDatepickerComponent,
    FormDropdownComponent,
    FormDropdownSearchComponent,
    FormFileInputComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormUploadComponent,
    RichTextComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    PipesModule,
    OverlayModule,
    DirectivesModule,
    NgxMatSelectSearchModule,
    ImageCropperModule,
    QuillModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TippyDirective,
  ],
  providers: [provideNgxMask()],
  exports: [
    FormDatepickerComponent,
    FormDropdownComponent,
    FormDropdownSearchComponent,
    FormFileInputComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormUploadComponent,
    RichTextComponent,
    ReactiveFormsModule,
  ],
})
export class FormModule {}
