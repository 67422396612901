import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { IDialogData } from '@common/components/dialog/basic-dialog-form.component';
import { UploadDialogFormComponent } from '@common/components/dialog/upload-dialog-form.component';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PermissionConfig } from '@common/services/authorization.service';

@Component({
  selector: 'app-change-image',
  templateUrl: './change-image.component.html',
  styleUrls: ['./change-image.component.scss'],
})
export class ChangeImageComponent {
  @Input('imageURL') set imageURLInput(value: string) {
    this.imageURL = value;
    this.useAsBase64 = false;
  }
  @Input() defaultImage = 'default-employee.jpeg';
  @Input() permission: PermissionConfig | PermissionConfig[] | undefined;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() title: string;
  imageBase64: string;
  extension: string;
  useAsBase64: boolean = false;
  protected imageURL = '';

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private matDialog: MatDialog,
  ) {}

  openImageDialog(): void {
    const form = this.fb.group({
      name: ['', Validators.required],
    });

    const controls = [
      {
        type: 'file',
        control: form.get('name') as UntypedFormControl,
        inputType: 'file',
        accept: 'image/*',
        showCropper: true,
        label: this.title,
      },
    ];
    const data: IDialogData = {
      title: 'UPLOAD_' + this.title,
      dialogRes: true,
      controls,
      spinnerName: 'reference-dialog',
    };
    const dialogRef = this.matDialog.open(UploadDialogFormComponent, { data });
    dialogRef.componentInstance.submitted$.subscribe(submitted => {
      if (submitted) {
        this.useAsBase64 = true;
        this.extension = dialogRef.componentInstance.documentExtension;
        this.imageBase64 =
          dialogRef.componentInstance.content?.toString() ?? '';
        this.change.emit(this.imageBase64);
        dialogRef.close(true);
      }
    });
  }
}
