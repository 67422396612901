import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ROOT } from '@common/const';

@Injectable({
  providedIn: 'root',
})
export default class FallbackRouteService {
  #isRootNavigationInProgress: boolean = false;
  public get isRootNavigationInProgress() {
    return this.#isRootNavigationInProgress;
  }

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart && event.url === ROOT) {
        this.#isRootNavigationInProgress = true;
      }
      if (event instanceof NavigationEnd) {
        this.#isRootNavigationInProgress = false;
      }
    });
  }
}
