import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperComponent } from './content-wrapper/wrapper.component';
import { MaterialModule } from '@common/modules';
import { RouterModule } from '@angular/router';
import { AuthWrapperComponent } from './auth-wrapper/auth-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from '../i18n';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from '@common/directives/directives.module';
import { BreadcrumbModule } from '@common/components/breadcrumb/breadcrumb.module';
import { SharedModule } from '@common/shared.module';
import { MainHeaderComponent } from '@common/components/wrapper/main-header/main-header.component';
import { FilterInputModule } from '@common/components/filters/filter-input/filter-input.module';
import { FormModule } from '@common/components/form/form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AvatarBarComponent } from '../avatar-bar/avatar-bar.component';
import { NavigationMenuComponent } from './nav-menu/nav-menu.component';
import { HeaderLogoComponent } from '@common/components/header-logo/header-logo.component';
import ReportIssueButtonComponent from '@common/components/buttons/report-issue-button/report-issue-button.component';

@NgModule({
  declarations: [WrapperComponent, MainHeaderComponent, AuthWrapperComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    I18nModule,
    NgxSpinnerModule,
    DirectivesModule,
    BreadcrumbModule,
    SharedModule,
    FilterInputModule,
    FormModule,
    ReactiveFormsModule,
    AvatarBarComponent,
    HeaderLogoComponent,
    NavigationMenuComponent,
    ReportIssueButtonComponent,
  ],
  exports: [WrapperComponent, MainHeaderComponent, AuthWrapperComponent],
})
export class WrapperModule {}
