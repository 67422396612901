import { Directive, Input } from '@angular/core';

interface TableContext {
  linkPrefix?: string[];
}

@Directive({
  selector: '[assetsTableContext]',
  standalone: true,
})
export class TableContextDirective {
  @Input('assetsTableContext') context: TableContext;
}
