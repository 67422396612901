import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';

@Component({
  selector: 'assets-form-dropdown',
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormDropdownComponent
  extends FormInputComponent
  implements OnInit
{
  @Input() options: any | string[];
  @Input() multiple: boolean = false;
  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();

  selectedValue(value: any) {
    this.onSelected.emit(value);
  }

  clearValue(e?: Event) {
    e?.stopPropagation();
    this.control.setValue(null);
    this.control.markAllAsTouched();
    this.control.markAsDirty();
    this.onSelected.emit(null);
  }
}
