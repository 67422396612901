import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Primitive } from 'ts-essentials';

export type NonPrimitiveFields<T> = {
  [K in keyof T]: T[K] extends Primitive ? never : K;
}[keyof T];

export type PrimitiveFields<T> = Exclude<keyof T, NonPrimitiveFields<T>>;

export type Resolved<T extends Resolve<any>> = ReturnType<
  T['resolve']
> extends Observable<infer R>
  ? R
  : never;

export type FromObservable<T> = T extends Observable<infer R> ? R : never;
