import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
})
export class FilterInputComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input('placeholder') placeholder: string;
  @Output() onFilterChange = new EventEmitter();
  isLoading = false;

  constructor() {}

  ngOnInit(): void {}

  emit(term: string): any {
    return this.onFilterChange.emit(term);
  }
}
