import { Directive, Output, EventEmitter, HostListener } from '@angular/core';
import { StopPropagationDirective } from '@common/directives/stop-propagation/stop-propagation.directive';

@Directive({
  selector: '[click.stop]',
})
export class ClickStopDirective extends StopPropagationDirective {
  @Output('click.stop') stopPropEvent = new EventEmitter();

  @HostListener('click', ['$event'])
  onClickStop(event: Event) {
    this.stopPropEvent.emit(event);
  }
}
