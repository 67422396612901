export const DASHBOARD_PERMISSIONS = {
  readCurrentActivities: {
    module: 'Dashboard',
    action: 'GetCurrentActivities',
  },
  readActiveStaffings: { module: 'Dashboard', action: 'GetActiveStaffings' },
  readUtilizations: { module: 'Dashboard', action: 'GetUtilizations' },
  readOnboardingPerformances: {
    module: 'Dashboard',
    action: 'GetOnboardingPerformances',
  },
};
