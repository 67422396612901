import { Injectable } from '@angular/core';
import {
  ActiveUserPreferencesInfo,
  UserPreferenceEnum,
} from '@common/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private _defaultActiveFilters = new Set<UserPreferenceEnum>();

  constructor() {
    const defaultFilters =
      JSON.parse(localStorage.getItem('defaultFilters') ?? 'null') ?? [];
    this.setUserDefaultFilters(defaultFilters);
  }

  isFilterActive(filter: UserPreferenceEnum): boolean {
    return this._defaultActiveFilters.has(filter);
  }

  setUserPreferences(userPreferences: ActiveUserPreferencesInfo) {
    this.setUserDefaultFilters(userPreferences.defaultFilters ?? []);
  }

  setUserDefaultFilters(filters: UserPreferenceEnum[]) {
    this._defaultActiveFilters.clear();
    this._defaultActiveFilters = new Set<UserPreferenceEnum>(filters);
    localStorage.setItem(
      'defaultFilters',
      JSON.stringify([...this._defaultActiveFilters]),
    );
  }

  addUserDefaultFilter(filter: UserPreferenceEnum) {
    this.setUserDefaultFilters([...this._defaultActiveFilters.add(filter)]);
  }

  removeUserDefaultFilter(filter: UserPreferenceEnum) {
    this._defaultActiveFilters.delete(filter);
    this.setUserDefaultFilters([...this._defaultActiveFilters]);
  }
}
