import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StandaloneComponentModule } from './standalone-component.module';
import { ButtonsModule } from '@common/components/buttons/buttons.module';
import { FormModule } from '@common/components/form/form.module';

@NgModule({
  exports: [
    StandaloneComponentModule,
    ReactiveFormsModule,
    ButtonsModule,
    FormModule,
    FormsModule,
  ],
})
export class StandaloneFormComponentModule {}
