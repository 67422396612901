import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';
import { ISelectOption } from '@common/models';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

//TODO syncronize with ISelectOption
export type Options<T extends string | number | null = string> =
  ISelectOption<T>[];

export type Key = 'name' | 'shortName';

@Component({
  selector: 'assets-form-dropdown-search',
  templateUrl: './form-dropdown-search.component.html',
  styleUrls: ['form-dropdown-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDropdownSearchComponent<
    T extends string | number | null = string,
  >
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  private readonly cdRef = inject(ChangeDetectorRef);
  @Input() multiple: boolean;
  @Input() chips: boolean;
  protected _options: Options<T> | null;
  @Input() set options(value: Options<T>) {
    this._options = value;
    if (this.loadingSubject.value) {
      this.loadingSubject.next(false);
    }
    if (this._options) {
      this.filteredOptions.next(this._options.slice());
    }
  }

  get options(): Options<T> {
    return this._options ?? [];
  }

  @Input() set initOption(value: ISelectOption<T>[] | ISelectOption<T>) {
    value = value ?? null;
    this.optionsSelected =
      Array.isArray(value) || value === null ? value : [value];
    this.hasInitValue = true;
  }
  override control: FormControl<T[] | null>;
  @Output() onSelected = new EventEmitter<T[] | null>();
  @Output() onOpened: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatLegacySelect;
  @ViewChild(MatSelectSearchComponent, { static: true })
  selectSearchCmponent: MatSelectSearchComponent;
  @Input() searchMethod?: (
    term: string,
  ) => Observable<FormDropdownSearchComponent<T>['_options']>;
  @Input() fetchMethod?: () => Observable<
    FormDropdownSearchComponent<T>['_options']
  >;
  @Input() withInitFetch = false;
  @Input() emitEventOnInit = true;
  protected keyValue: Key = 'name';
  @Input() set key(value: Key) {
    if (value) {
      this.keyValue = value;
    }
  }
  @Input()
  get required(): boolean {
    return this._required ?? this.control.hasValidator(Validators.required);
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  private _required: boolean = false;
  public filterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredOptions: ReplaySubject<Options<T> | null> = new ReplaySubject(
    1,
  );
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  protected _onDestroy = new Subject<void>();
  protected onOpenedChange = new Subject<boolean>();
  protected optionsSelected: ISelectOption<T>[] | null;
  protected hasInitValue = false;
  protected opened = false;

  override ngOnInit() {
    this.resetFilteredOptions();
    this.onOpenedChange
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy))
      .subscribe((opened: boolean) => {
        if (opened) {
          if (this.fetchMethod) {
            this.fetchOptions({ skipOpen: false });
            this.filteredOptions.next(null);
            this.optionsSelected = null;
            this.onOpened.emit();
          } else {
            this.singleSelect.close();
            this.cdRef.detectChanges();
            this.singleSelect.open();
            this.opened = true;
          }
        } else {
          this.opened = false;
        }
      });
    this.filterCtrl.valueChanges
      .pipe(
        tap(term => {
          if (!term) {
            this.resetFilteredOptions();
          }
        }),
        debounceTime(200),
        distinctUntilChanged(),
        filter<string>(Boolean),
        takeUntil(this._onDestroy),
      )
      .subscribe((value: string) => {
        if (this.searchMethod) {
          this.filterOptionsAsync(value);
        } else {
          this.filterOptionsSync();
        }
      });
    if (this.emitEventOnInit) {
      this.selectedValue(this.control.value ?? []);
    }
    if (this.shouldMakeInitFetch()) {
      this.fetchOptions({ skipOpen: true });
    }
  }

  private shouldMakeInitFetch() {
    if (this.withInitFetch) {
      return true;
    }

    const value = this.control.value;
    const hasValue = Array.isArray(value) ? value.length !== 0 : Boolean(value);

    return !this.hasInitValue && hasValue;
  }

  private resetFilteredOptions() {
    this.filteredOptions.next(this._options?.slice() ?? null);
  }

  private fetchOptions(params: { skipOpen: boolean }) {
    if (this.fetchMethod) {
      this.fetchMethod()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(options => {
          this.filteredOptions.next(options);
          this._options = options;
          this.loadingSubject.next(false);
          this.singleSelect.close();
          this.cdRef.detectChanges();
          if (!params.skipOpen) {
            this.singleSelect.open();
            this.opened = true;
          } else {
            this.opened = false;
          }

          //TODO: fix this or remove
          // const selected = options?.find(option => option.id === this.control.value);
          // if (!selected) {
          //   this.clearValue();
          // }
        });
      this.loadingSubject.next(true);
    }
  }

  /**
   * Write code on Method
   *
   * method logical code
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Write code on Method
   *
   * method logical code
   */
  protected setInitialValue() {
    this.filteredOptions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.singleSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  private filterOptionsSync() {
    if (!this._options) {
      return;
    }

    let search = this.filterCtrl.value;
    search = search.toLowerCase();

    this.filteredOptions.next(
      this._options.filter((option: ISelectOption<T>) =>
        option[this.keyValue]!.toLowerCase().includes(search),
      ),
    );
  }

  private filterOptionsAsync(search: string) {
    if (this.searchMethod) {
      search = search.toLowerCase();
      this.searchMethod(search)
        .pipe(
          tap(result => {
            this.filteredOptions.next(result);
          }),
          takeUntil(this._onDestroy),
        )
        .subscribe();
    }
  }

  selectedValue(value: T[]) {
    this.onSelected.emit(value);
  }

  //TODO: fix this or remove
  // onOptionSelect(value: ISelectOption<T>) {
  //   this.optionsSelected = value;
  // }

  clearValue(e?: Event) {
    e?.stopPropagation();

    this.control.setValue(null);
    this.control.markAllAsTouched();
    this.control.markAsDirty();
    //TODO: fix this or remove
    // this.resetFilteredOptions();
    // if (this.required) {
    //   this.singleSelect.open();
    // }

    this.onSelected.emit(null);
  }
}
