import { inject } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@common/services/authorization.service';
import { isArray } from 'lodash-es';
import { UNAUTHORIZED } from '@common/const';
import { Observable } from 'rxjs';
import FallbackRouteService from '@common/services/fallback-route.service';

export function protectedGuard(next: Route): Observable<boolean> {
  const router = inject(Router);
  const authService = inject(AuthenticationService);
  const authorizationService = inject(AuthorizationService);
  const fallbackRouteService = inject(FallbackRouteService);

  return authService.isAuthorized().pipe(
    map((isAuthorized: boolean) => {
      if (isAuthorized) {
        const hasPermission = authorizationService.hasPermission(
          next.data?.permission,
          next.data?.permissionsMode,
        );
        if (hasPermission) {
          return true;
        }
        const fallback = next.data?.fallback;
        const commands =
          fallback && fallbackRouteService.isRootNavigationInProgress
            ? isArray(fallback)
              ? fallback
              : [fallback]
            : [UNAUTHORIZED];

        router.navigate(commands);
        return false;
      }
      authService.logoutWithRedirect();
      return false;
    }),
  );
}
