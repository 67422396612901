import { OverlayContainer } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  inject,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { toggleAnimation2 } from '@common/common/animations';
import { PageRightDrawerContentDirective } from '@common/directives/page-right-drawer-content.directive';
import { AuthenticationService } from '@common/services/authentication.service';
import { ConfigService } from '@common/services/core/config.service';
import { FullScreenService } from '@common/services/full-screen.service';
import { TableFilterService } from '@common/services/table-filter.service';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  animations: [toggleAnimation2],
})
export class WrapperComponent implements AfterViewInit {
  panelOpenState = true;
  hasBackdrop: boolean;
  @ViewChild('rightDrawer') rightDrawer: MatDrawer;
  @ViewChild(PageRightDrawerContentDirective, { static: true })
  rightDrawerContent: PageRightDrawerContentDirective | undefined;
  projectedPage: any;
  hasRightDrawer: boolean = false;
  rightDrawerComponentRef: ComponentRef<any> | null = null;
  rightDrawerComponent: any | null = null;
  navigation$: Observable<boolean>;
  tableFilterService = inject(TableFilterService);
  releaseVersion$ = inject(ConfigService).releaseVersion$;
  overlayContainerEl = inject(OverlayContainer).getContainerElement();
  protected readonly destroy$ = new Subject<void>();

  constructor(
    public authService: AuthenticationService,
    public fullScreenService: FullScreenService,
    private cd: ChangeDetectorRef,
    public router: Router,
  ) {
    this.navigation$ = this.router.events.pipe(
      map(event => {
        switch (event.constructor) {
          case NavigationStart:
            this.loadRightDrawerComponent(null);
            return true;
          case NavigationEnd:
          case NavigationCancel:
          case NavigationError:
            return false;
          default:
            return null;
        }
      }),
      filter((value): value is boolean => value !== null),
    );

    this.detetectOverlay();
  }

  private detetectOverlay() {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        this.isOverlayOpened = Boolean(mutation.target.childNodes.length);
      });
    });

    observer.observe(this.overlayContainerEl, {
      childList: true,
      subtree: false,
    });

    this.destroy$.subscribe(() => observer.disconnect());
  }

  protected isOverlayOpened = false;

  ngAfterViewInit(): void {
    this.tableFilterService.filterComponent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(cmp => {
        this.rightDrawer.close();
        if (cmp) {
          this.rightDrawerComponent = cmp;
          this.hasRightDrawer = true;
        } else if (!cmp) {
          this.rightDrawerComponent = null;
          this.hasRightDrawer = false;
        }
        this.cd.detectChanges();
      });
  }

  loadRightDrawerComponent(projectedPageComponent: any) {
    const viewContainerRef = this.rightDrawerContent?.viewContainerRef;

    if (viewContainerRef) {
      viewContainerRef.clear();
      this.rightDrawerComponentRef = null;
      if (projectedPageComponent) {
        this.rightDrawerComponentRef = viewContainerRef.createComponent<any>(
          projectedPageComponent,
        );
      }
    }
  }

  onRightDrawerOpen() {
    if (
      this.rightDrawerComponent &&
      (!this.rightDrawerComponentRef ||
        !(
          this.rightDrawerComponentRef.instance instanceof
          this.rightDrawerComponent
        ))
    ) {
      this.loadRightDrawerComponent(this.rightDrawerComponent);
    }
  }

  get animationState() {
    return this.panelOpenState ? 'in' : 'out';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
