import { Pipe, PipeTransform } from '@angular/core';
import { ISelectOption } from '@common/models/common.models';

@Pipe({
  name: 'selectOptions',
})
export class SelectOptionsPipe implements PipeTransform {
  transform(options: string[] | number[] | ISelectOption[]) {
    return options?.map(
      (option: string | number | ISelectOption, index: number) => {
        switch (typeof option) {
          case 'string':
            return option !== option.toUpperCase()
              ? { name: option, value: index + 1 }
              : { name: option, value: option };
          case 'number':
            return { name: String(option), value: option };
          default:
            return option;
        }
      },
    );
  }
}
