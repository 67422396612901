import { inject } from '@angular/core';
import {
  createUrlTreeFromSnapshot,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { BehaviorSubject, filter, finalize, Observable, Subject } from 'rxjs';
import { NOT_FOUND } from '../const';

export abstract class BasePageFacadeService<T> {
  private _pageData$ = new BehaviorSubject<T | null>(null);
  public pageData$ = this._pageData$.pipe(
    filter((data): data is Exclude<T, null | undefined> => Boolean(data)),
  );

  private _pageError$ = new Subject<true>();
  public pageError$ = this._pageError$.asObservable();

  private _spinner$ = new BehaviorSubject<boolean>(false);
  public spinner$ = this._spinner$.asObservable();

  public setId(value: string, route: ActivatedRouteSnapshot) {
    this._id = value;
    this._route = route;
    this.fetchPageData();
  }
  public setLazyId(value: string) {
    this._id = value;
  }
  public get id() {
    return this._id ?? 0;
  }
  //TODO: When all the modules are redone, we do need to change the _id type to a string
  private _id: any = null;
  private _route: ActivatedRouteSnapshot | null = null;

  private router = inject(Router);

  pushPageData(pageData: T | null) {
    this._pageData$.next(pageData);
  }

  reset() {
    this._pageData$.next(null as T);
    this._id = null;
    this._route = null;
  }

  abstract fetchObservableFactory: () => Observable<T | null>;

  fetchPageData() {
    if (!this.fetchObservableFactory) {
      throw new Error('fetchObservableFactor is not implemented');
    }

    this.showSpinner();

    this.fetchObservableFactory()
      ?.pipe(
        finalize(() => {
          if (!this._pageData$.value) {
            this.reset();
            this._pageError$.next(true);
          }
          this.hideSpinner();
        }),
      )
      .subscribe({
        next: item => {
          if (!item) {
            const snapshot = this._route?.parent?.parent;
            const urlTree = createUrlTreeFromSnapshot(
              snapshot! ?? this._route,
              [NOT_FOUND],
            );
            this.router.navigateByUrl(urlTree);
            return;
          }
          this.pushPageData(item);
        },
        error: () => this._pageError$.next(true),
      });
  }

  showSpinner() {
    this._spinner$.next(true);
  }

  hideSpinner() {
    this._spinner$.next(false);
  }
}
