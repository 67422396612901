import { inject, Injectable, Injector } from '@angular/core';
import { OperationVariables } from '@apollo/client/core';
import { Mutation, Query, TypedDocumentNode } from 'apollo-angular';
import { MutationResult } from 'apollo-angular/types';
import { DocumentNode } from 'graphql';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ORDER_BY_NAME } from '../const/apollo.const';
import { ApiSpinnerService, SpinnerOptions } from './api-spinner.service';
import { NotificationService } from './notification.service';
import { IError } from '@common/models';
import { FILTER_SPINNER_OPTION } from '@common/const/spinner.const';

export type Options = {
  spinner?: SpinnerOptions | false;
  withoutErrorHandling?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BaseApolloService {
  public readonly notificationService = inject(NotificationService);
  public readonly apiSpinnerService = inject(ApiSpinnerService);
  protected readonly injector = inject(Injector);

  constructor(injector?: Injector) {
    this.injector = injector ?? this.injector;
  }

  loadingWrapper<T>(
    observable: Observable<T>,
    options?: Options,
  ) {

    if (options?.spinner !== false) {
      const spinnerOptions = {
        name: 'page-host',
        options: FILTER_SPINNER_OPTION,
        ...options?.spinner,
      };
      observable = this.apiSpinnerService.loadingWrapper(observable, spinnerOptions);
    }

    return observable.pipe(
      catchError((error: IError) => {
        if (!options?.withoutErrorHandling) {
          this.notificationService.occurredError(error);
          this.notificationService.notify(error.message, 'error');
          return EMPTY;
        }
        return throwError(() => error);
      }),
    );
  }

  query<
    GQL extends Query<T, V>,
    T extends Result<T, V, GQL['fetch']>,
    V extends OperationVariables,
  >(
    query: new (...args: any) => GQL,
    params: V,
    options?:  Options,
  ) {
    const queryInstance = this.injector.get<GQL>(query);
    const observable = queryInstance.fetch(params);

    return this.loadingWrapper(observable, options);
  }

  mutate<
    GQL extends Mutation<T, V>,
    T extends Result<T, V, GQL['mutate']>,
    V extends Variables<T, V, GQL['document']>,
  >(
    mutation: new (...args: any) => GQL,
    params: V,
    options?: Options,
  ) {
    const mutationInst = this.injector.get<GQL>(mutation);
    const observable = mutationInst.mutate(params);

    return this.loadingWrapper(observable, options);
  }

  static getSortByNameParams<T>(params: T) {
    return { ...ORDER_BY_NAME, ...params };
  }
}

type Result<
  T,
  V,
  D extends Mutation<T, V>['mutate'],
> = ReturnType<D> extends Observable<infer X>
  ? X extends MutationResult<infer Y>
    ? Y
    : never
  : never;
type Variables<T, V, D = Mutation<T, V>['document']> = Exclude<
  D,
  DocumentNode
> extends TypedDocumentNode<any, infer X>
  ? X
  : never;
