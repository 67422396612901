import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticationService } from '@common/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  @Output() showMenu = new EventEmitter<any>();

  constructor(
    public authService: AuthenticationService,
    public router: Router,
  ) {}

  isShowMenu(name: string): void {
    this.showMenu.emit(name);
  }
}
