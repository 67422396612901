import { Component, Input } from '@angular/core';
import { Routes } from '@common/const';
import { StandaloneComponentModule } from '@common/modules';
import { RoleNamesPipe } from '@common/pipes/user-roles.pipe';
import { AuthenticationService } from '@common/services/authentication.service';

@Component({
  selector: 'app-avatar-bar',
  standalone: true,
  imports: [StandaloneComponentModule, RoleNamesPipe],
  templateUrl: './avatar-bar.component.html',
  styleUrls: ['avatar-bar.component.scss'],
})
export class AvatarBarComponent {
  Routes = Routes;
  @Input() disabled: boolean = false;

  constructor(public authService: AuthenticationService) {}
}
