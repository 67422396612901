import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appVar]',
})
export class VarDirective<T> {
  public context: { $implicit?: T; appVar?: T } = {};

  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
  ) {}

  @Input()
  public set appVar(context: T) {
    this.context.$implicit = context;
    this.context.appVar = context;
    this.updateView();
  }

  private updateView(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }

  static ngTemplateContextGuard<T>(
    dir: VarDirective<T>,
    ctx: any,
  ): ctx is VarDirectiveContext<Exclude<T, false | 0 | '' | null | undefined>> {
    return true;
  }
}

export class VarDirectiveContext<T = unknown> {
  public $implicit: T = null!;
  public appVar: T = null!;
}
