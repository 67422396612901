import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

class CurrentUser {}

class Permissions {
  canActivate(user: CurrentUser, id: string): boolean {
    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProtectedChildGuard implements CanActivateChild {
  permissions: Permissions;
  currentUser: CurrentUser;
  constructor() {
    this.permissions = new Permissions();
    this.currentUser = new CurrentUser();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.permissions.canActivate(this.currentUser, route.params.id);
  }
}
