<div
  [class]="
    'assets-form-datepicker assets-form-dropdown assets-form-field ' +
    ('label-' + labelPosition)
  "
>
  <span class="assets-form-field-label">
    {{ label | translate }}
    <span
      *ngIf="hasRequiredField(control)"
      class="text-danger font-weight-light"
      >*</span
    >
  </span>
  <mat-form-field appearance="fill">
    <mat-label
      >{{ placeholder | translate }}
      <ng-template [ngIf]="hasRequiredField(control)"> </ng-template>
    </mat-label>
    <input
      #input
      matInput
      autocomplete="off"
      [type]="type"
      [formControl]="control"
      [min]="minDateString"
      [max]="maxDateString"
      (blur)="onBlur()"
      (focus)="input.showPicker()"
      (change)="onDateChange($event)"
    />
    <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>

    <!--    hint-->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
