import { Directive } from '@angular/core';
import { AccountStateEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';
type Item = AccountStateEnum | undefined;
@Directive({
  selector: '[appAccountStateChipResolver]',
})
export class AccountStateChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const state = model ?? AccountStateEnum.Active;
    let classNames = [''];
    switch (model) {
      case AccountStateEnum.Active:
        classNames = ['assets-chip-success'];
        break;
      case AccountStateEnum.Suspended:
      case AccountStateEnum.Prospect:
        classNames = ['assets-chip-gray'];
        break;
      default:
        classNames = ['assets-chip-danger'];
        break;
    }
    return {
      classNames,
      contentToken: state,
    };
  }
}
