import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { ImageDirective } from '@common/directives/images/image.directive';

@Directive({
  selector: '[appAvatar]',
})
export class AvatarDirective extends ImageDirective implements OnInit {
  @Input('appAvatar') override set appImage(value: string) {
    super.appImage = value;
  }

  @Input() override defaultImage = 'default-employee.jpeg';
  @HostBinding('class') avatarClass = 'border rounded-circle';
}
