import { Directive } from '@angular/core';
import { HealthEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = HealthEnum;

@Directive({
  selector: '[appHealthChipResolver]',
})
export class HealthChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const classNames = model
      ? model === HealthEnum.Unknown
        ? ['mat-chip-grey']
        : model === HealthEnum.Healthy
        ? ['assets-chip-success']
        : model === HealthEnum.Issues
        ? ['assets-chip-yellow']
        : ['assets-chip-danger']
      : [];

    return {
      classNames,
      contentToken: model ?? '',
    };
  }
}
