import { Injectable } from '@angular/core';
import { DEFAULT_SPINNER_CONFIG } from '@common/const/apollo.const';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private spinner: NgxSpinnerService) {}

  show(loaderName?: string, options?: Spinner) {
    this.spinner.show(loaderName, { ...DEFAULT_SPINNER_CONFIG, ...options });
  }

  hide(loaderName: string) {
    this.spinner.hide(loaderName);
  }
}
