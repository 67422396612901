import { NgModule } from '@angular/core';
import { SelectOptionsPipe } from '@common/pipes/select.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { MemoizePipe } from './memoize.pipe';
import { TruncatePipe } from './truncate.pipe';
import { EnumToOptionsPipe } from '@common/pipes/enum-to-options.pipe';
import { IsRequiredFieldPipe } from './is-required-field.pipe';
import { UserNameTransformPipe } from './user-name.pipe';
import { CustomDateTimePipe } from '@common/pipes/custom-date-time.pipe';
import { FallbackPipe } from './fallback.pipe';
import { PrefixedLinkPipe } from './prefixed-link.pipe';

@NgModule({
  declarations: [
    SelectOptionsPipe,
    CustomDatePipe,
    CustomDateTimePipe,
    MemoizePipe,
    TruncatePipe,
    FallbackPipe,
    EnumToOptionsPipe,
    IsRequiredFieldPipe,
    UserNameTransformPipe,
    PrefixedLinkPipe,
  ],
  exports: [
    SelectOptionsPipe,
    CustomDatePipe,
    CustomDateTimePipe,
    MemoizePipe,
    TruncatePipe,
    FallbackPipe,
    EnumToOptionsPipe,
    IsRequiredFieldPipe,
    UserNameTransformPipe,
    PrefixedLinkPipe,
  ],
  imports: [],
})
export class PipesModule {}
