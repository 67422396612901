import { Component, OnInit } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Router,
} from '@angular/router';
import { CustomTitleStrategy } from '@common/services/title-strategy.service';
import { NonNullableArray } from '@common/types';
import {
  Observable,
  of,
  distinctUntilChanged,
  filter,
  map,
  zip,
  Subject,
  takeUntil,
} from 'rxjs';
export interface IBreadCrumb {
  label: {
    alias?: string;
    prefix?: string;
  };
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs$: Observable<IBreadCrumb | null>[];
  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private titleStrategy: CustomTitleStrategy,
  ) {
    this.handleBreadcrumbs();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        // @ts-ignore
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.handleBreadcrumbs();
      });
  }

  private handleBreadcrumbs() {
    this.breadcrumbs$ = this.buildBreadcrumbs(
      this.router.routerState.snapshot.root,
    );
    zip(this.breadcrumbs$!)
      .pipe(
        map(breadcrumbs =>
          breadcrumbs
            .filter(Boolean)
            .map(
              breadcrumb =>
                breadcrumb!.label?.prefix ?? breadcrumb!.label?.alias,
            )
            .filter(Boolean),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(breadcrumbs =>
        this.titleStrategy.pushBreadcrumbs(NonNullableArray(breadcrumbs)),
      );
  }

  buildBreadcrumbs(
    route: ActivatedRouteSnapshot | null,
    url: string[] = [],
    breadcrumbs: Observable<IBreadCrumb | null>[] = [],
  ): Observable<IBreadCrumb | null>[] {
    //If no routeConfig is avalailable we are on the root path
    // let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    // let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // // If the route is dynamic route such as ':id', remove it
    // const lastRoutePart = path!.split('/').pop();
    // const isDynamicRoute = lastRoutePart!.startsWith(':');
    // if(isDynamicRoute && !!route.snapshot) {
    //   const paramName = lastRoutePart!.split(':')[1];
    //   path = path!.replace(lastRoutePart!, route.snapshot.params[paramName]);
    //   label = route.snapshot.params[paramName];
    // }

    // //In the routeConfig the complete path is not available,
    // //so we rebuild it each time
    // const nextUrl = path ? `${url}/${path}` : url;

    // const breadcrumb: IBreadCrumb = {
    //   label: label,
    //   url: nextUrl,
    // };
    // // Only adding route with non-empty label
    // // @ts-ignore
    // const newBreadcrumbs = breadcrumb.label?.alias ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    // if (route.firstChild) {
    //   //If we are not on our current path yet,
    //   //there will be more children to look after, to build our breadcumb
    //   return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    // }
    // return this.finalizeBreadcrubs(newBreadcrumbs);

    if (route !== null) {
      const routeCommands: string[] = url.concat(
        route.url.map(url => url.path),
      );

      const breadcrumbFromData = route.data.breadcrumb;
      const breadcrumbFromParentData = route.parent?.data.breadcrumb;
      if (
        breadcrumbFromData &&
        (route.parent ? breadcrumbFromData !== breadcrumbFromParentData : true)
      ) {
        const breadcrumb = this.getBreadcrumb(route.data, routeCommands);
        breadcrumbs.push(breadcrumb);
      }

      if (route.firstChild) {
        return this.buildBreadcrumbs(
          route.firstChild,
          routeCommands,
          breadcrumbs,
        );
      } else {
        return this.finalizeBreadcrumbs(breadcrumbs);
      }
    } else {
      return this.finalizeBreadcrumbs(breadcrumbs);
    }
  }

  private getBreadcrumb(
    data: Data,
    routeCommands: string[],
  ): Observable<IBreadCrumb | null> {
    const url = '/' + routeCommands.join('/');

    if (data.breadcrumb instanceof Observable) {
      return data.breadcrumb.pipe(
        map(breadcrumb =>
          breadcrumb ? { label: { prefix: breadcrumb }, url } : null,
        ),
      );
    }
    const label: IBreadCrumb['label'] =
      typeof data.breadcrumb === 'function'
        ? { prefix: data.breadcrumb(data) }
        : typeof data.breadcrumb === 'string'
        ? { prefix: data.breadcrumb }
        : data.breadcrumb;

    return of({ label, url });
  }

  finalizeBreadcrumbs(breadcrumbs: Observable<IBreadCrumb | null>[]) {
    const result: Observable<IBreadCrumb | null>[] = [];
    for (let i = 0; i < breadcrumbs.length; i++) {
      const breadcrumb$ = breadcrumbs[i];
      const mapped$ = breadcrumb$.pipe(
        map(breadcrumb => {
          return breadcrumb;
        }),
      );
      result.push(mapped$);
    }
    return result;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
