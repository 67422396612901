import { NgIf } from '@angular/common';
import { Directive, inject, Input, OnInit } from '@angular/core';
import {
  AuthorizationService,
  PermissionConfig,
} from '@common/services/authorization.service';

export enum PermissionsMode {
  Some = 'Some',
  Every = 'Every',
}

@Directive({
  selector: '[appPermissionIf]',
  standalone: true,
  hostDirectives: [
    {
      directive: NgIf,
      inputs: ['ngIfElse: appPermissionIfElse'],
    },
  ],
})
export class PermissionIfDirective implements OnInit {
  constructor(private authorizationService: AuthorizationService) {}
  private readonly ngIfDirective = inject(NgIf);
  #permission: PermissionConfig | PermissionConfig[];
  #mode: PermissionsMode;

  @Input() set appPermissionIf(
    // TODO: Task 91084: [FE] Modify appPermissionIf directive
    permission: PermissionConfig | PermissionConfig[] | undefined,
  ) {
    this.#permission = permission!;
  }

  @Input() set appPermissionIfMode(mode: PermissionsMode) {
    this.#mode = mode;
  }

  ngOnInit() {
    this.ngIfDirective.ngIf = this.#permission
      ? this.authorizationService.hasPermission(this.#permission, this.#mode)
      : true;
  }
}
