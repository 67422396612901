import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from '@common/directives/directives.module';
import { MaterialModule } from '@common/modules/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '@common/shared.module';
import { FormModule } from '@common/components/form/form.module';

@NgModule({
  exports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DirectivesModule,
    MaterialModule,
    TranslateModule,
    NgxSpinnerModule,
    PipesModule,
    FormModule,
  ],
})
export class StandaloneComponentModule {}
