import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  inject,
  Input,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subject } from 'rxjs';

type MenuItem = {
  text: string;
  callback: (...args: any[]) => void;
};

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
})
export class ContextMenuComponent implements OnDestroy {
  @Input() items: MenuItem[] = [];
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  private readonly destroy$ = new Subject<void>();
  private cdRef = inject(ChangeDetectorRef);

  menuTopLeftPosition = { x: 0, y: 0 };

  openMenu(event: MouseEvent) {
    this.menuTopLeftPosition.x = event.clientX;
    this.menuTopLeftPosition.y = event.clientY;
    this.cdRef.detectChanges();
    this.matMenuTrigger.menu?.focusFirstItem('mouse');
    this.matMenuTrigger.openMenu();
  }

  get menuClosed() {
    return this.matMenuTrigger.menuClosed.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
