import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDirective } from './error.directive';
import { PageRightDrawerContentDirective } from './page-right-drawer-content.directive';
import { ActivityStatusChipResolverDirective } from './status-chip-resolver/activity-status-chip-resolver.directive';
import { ProjectStateChipResolverDirective } from '@common/directives/status-chip-resolver/project-state-chip-resolver';
import { HrefClickStopDirective } from '@common/directives/stop-propagation/href-click-stop.directive';
import { StopPropagationDirective } from '@common/directives/stop-propagation/stop-propagation.directive';
import { ClickStopDirective } from '@common/directives/stop-propagation/click-stop.directive';
import { ImageDirective } from '@common/directives/images/image.directive';
import { AvatarDirective } from '@common/directives/images/avatar.directive';
import { LinkDirective } from './link.directive';
import { AnchorDirective } from './anchor.directive';
import { VendorStateChipResolverDirective } from './status-chip-resolver/vandor-state-chip-resolver';
import { HealthChipResolverDirective } from './status-chip-resolver/health-chip-resolver.directive';
import { EmployeeStatusChipResolverDirective } from './status-chip-resolver/employee-status-chip-resolver.directive';
import { AccountStateChipResolverDirective } from './status-chip-resolver/account-state-chip-resolver';
import { YesNoChipResolverDirective } from '@common/directives/status-chip-resolver/yes-no-chip-resolver';
import { PlacementStatusChipResolverDirective } from './status-chip-resolver/placement-status-chip-resolver.directive';
import { RequirementSkillModeChipResolverDirective } from '@common/directives/status-chip-resolver/requirement-skill-mode-chip-resolver';
import { RequisitionStatusChipResolverDirective } from './status-chip-resolver/requisition-status-chip-resolver.directive';
import { CandidateStatusChipResolverDirective } from '@common/directives/status-chip-resolver/cadidate-status-chip-resolver.directive';
import { CandidateStatesChipResolverDirective } from '@common/directives/status-chip-resolver/cadidate-states-chip-resolver.directive';
import { CandidateActionsChipResolverDirective } from '@common/directives/status-chip-resolver/cadidate-actions-chip-resolver.directive';
import { AppButtonDirective } from './app-button.directive';
import { VarDirective } from './var.directive';
import { HistoryLogActionChipResolverDirective } from '@common/directives/status-chip-resolver/history-log-action-chip-resolver.directive';
import { TypedTemplateDirective } from './typed-template.directive';
import { InputRefDirective } from './input-ref.directive';
import { PermissionIfDirective } from './permission-if.directive';
import { FormPageTabGroupDirective } from './form-page-tab-group.directive';
import InvoiceStatusChipResolverDirective from './status-chip-resolver/invoice-status-chip-resolver.directive';
import AccountReceivableStatusChipResolverDirective from '@common/directives/status-chip-resolver/account-receivable-status-chip-resolver.directive';
import OtherBillStatusChipResolverDirective from '@common/directives/status-chip-resolver/other-bill-status-chip-resolver.directive';

@NgModule({
  declarations: [
    ErrorDirective,
    VarDirective,
    TypedTemplateDirective,
    InputRefDirective,
    PageRightDrawerContentDirective,
    PlacementStatusChipResolverDirective,
    ActivityStatusChipResolverDirective,
    ProjectStateChipResolverDirective,
    VendorStateChipResolverDirective,
    EmployeeStatusChipResolverDirective,
    RequisitionStatusChipResolverDirective,
    StopPropagationDirective,
    HrefClickStopDirective,
    ClickStopDirective,
    ImageDirective,
    AvatarDirective,
    LinkDirective,
    AnchorDirective,
    HealthChipResolverDirective,
    AccountStateChipResolverDirective,
    YesNoChipResolverDirective,
    RequirementSkillModeChipResolverDirective,
    CandidateStatusChipResolverDirective,
    CandidateStatesChipResolverDirective,
    CandidateStatesChipResolverDirective,
    CandidateActionsChipResolverDirective,
    AppButtonDirective,
    HistoryLogActionChipResolverDirective,
    InvoiceStatusChipResolverDirective,
    AccountReceivableStatusChipResolverDirective,
    OtherBillStatusChipResolverDirective,
  ],
  imports: [CommonModule, PermissionIfDirective, FormPageTabGroupDirective],
  exports: [
    VarDirective,
    TypedTemplateDirective,
    InputRefDirective,
    PageRightDrawerContentDirective,
    PlacementStatusChipResolverDirective,
    ActivityStatusChipResolverDirective,
    ProjectStateChipResolverDirective,
    VendorStateChipResolverDirective,
    EmployeeStatusChipResolverDirective,
    RequisitionStatusChipResolverDirective,
    StopPropagationDirective,
    HrefClickStopDirective,
    ClickStopDirective,
    ImageDirective,
    AvatarDirective,
    LinkDirective,
    AnchorDirective,
    HealthChipResolverDirective,
    AccountStateChipResolverDirective,
    YesNoChipResolverDirective,
    RequirementSkillModeChipResolverDirective,
    CandidateStatusChipResolverDirective,
    CandidateStatesChipResolverDirective,
    CandidateStatesChipResolverDirective,
    CandidateActionsChipResolverDirective,
    AppButtonDirective,
    HistoryLogActionChipResolverDirective,
    PermissionIfDirective,
    FormPageTabGroupDirective,
    InvoiceStatusChipResolverDirective,
    AccountReceivableStatusChipResolverDirective,
    OtherBillStatusChipResolverDirective,
  ],
})
export class DirectivesModule {}
