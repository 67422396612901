import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ROOT } from '@common/const/routes.const';
import { NotificationService } from '@common/services/notification.service';

export function checkIfAuthorizedGuard(isAuthorized: boolean) {
  if (isAuthorized) {
    return true;
  }

  inject(NotificationService).notify('ERROR_NOT_AUTHORIZED', 'error');
  inject(Router).navigate([ROOT]);

  return false;
}
