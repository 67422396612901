import { Component, EnvironmentInjector, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NAVIGATION_ROUTES } from '@common/const/common';
import { IRoute } from '@common/models';
import { StandaloneComponentModule } from '@common/modules';

@Component({
  standalone: true,
  selector: 'app-nav-menu',
  templateUrl: 'nav-menu.component.html',
  imports: [StandaloneComponentModule],
})
export class NavigationMenuComponent {
  private injector = inject(EnvironmentInjector);
  routes = inject(NAVIGATION_ROUTES);
  navMenuItemTypeToken: {
    route: IRoute;
    routerCommands: RouterLink['commands'];
    level: number;
  };
  listItemTypeToken: NavigationMenuComponent['navMenuItemTypeToken'] & {
    hasChildren: boolean;
  };

  checkRouteVisibility = (checkVisibility?: IRoute['checkVisibility']) =>
    checkVisibility ? checkVisibility(this.injector) : true;
}
