import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableFilterService {
  private _filterComponent$ = new BehaviorSubject<Type<any> | null>(null);
  public filterComponent$ = this._filterComponent$.asObservable();

  setFilterComponent(cmp: Type<any> | null) {
    this._filterComponent$.next(cmp);
  }
}
