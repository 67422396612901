import { Directive } from '@angular/core';
import { CandidateStatusEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = CandidateStatusEnum;

@Directive({
  selector: '[assetsCandidateStatusChipResolver]',
})
export class CandidateStatusChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const status = model;

    const classNames = status
      ? status === CandidateStatusEnum.Rejected
        ? ['assets-chip-danger']
        : status === CandidateStatusEnum.Placed
        ? ['assets-chip-success']
        : status === CandidateStatusEnum.InProcess
        ? ['assets-chip-yellow']
        : status === CandidateStatusEnum.Withdrawn
        ? ['mat-chip-grey']
        : []
      : [];

    let contentToken: string = status ?? '';

    if (status === CandidateStatusEnum.Closed) {
      contentToken = 'REQUISITION_CLOSED';
    }

    return {
      classNames,
      contentToken,
    };
  }
}
