import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@common/services/authentication.service';
import { LoginForm } from './login-form';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { I18N_CONFIG } from '@common/const';
import { SignInQueryVariables } from '@common/generated/graphql';
import { NotificationService } from '@common/services/notification.service';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  MAX_LENGTH_6_FN,
  MIN_LENGTH_6_FN,
  INTEGER_FN,
} from '@common/const/form.const';
import { Validators } from '@angular/forms';

export enum AuthMode {
  Login = 1,
  ForgotPassword,
  TwoStepVerification,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  readonly AuthMode = AuthMode;
  readonly modeToTitleMap = {
    [AuthMode.Login]: 'AUTHORIZATION',
    [AuthMode.ForgotPassword]: 'FORGOT_PASSWORD',
    [AuthMode.TwoStepVerification]: '2FA_VERIFICATION',
  };
  authMode: AuthMode = AuthMode.Login;
  disabledSendButton: boolean = false;
  isLoading: boolean = false;
  loginForm: LoginForm;
  readonly i18nConfig = I18N_CONFIG;
  readonly destroy$ = new Subject<void>();

  @Input() isGoogleSignInEnabled?: boolean;

  constructor(
    public authService: AuthenticationService,
    public translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loginForm = new LoginForm();
  }

  async submitHandler() {
    const params: SignInQueryVariables = {
      SignInRequestInput: {
        auth: this.loginForm.value,
      },
    };

    this.isLoading = true;

    this.authService
      .login(params)
      .pipe(this.stopSpinner, takeUntil(this.destroy$))
      .subscribe(loginResponse => {
        if (!loginResponse.success) {
          if (loginResponse.nextMode) {
            if (loginResponse.nextMode === AuthMode.TwoStepVerification) {
              this.loginForm.code.setValidators([
                Validators.required,
                MIN_LENGTH_6_FN,
                MAX_LENGTH_6_FN,
                INTEGER_FN,
              ]);
            } else if (loginResponse.nextMode === AuthMode.Login) {
              this.resetLoginForm();
            }

            this.setAuthModeValue(loginResponse.nextMode);
          }
        }
      });
  }

  resetLoginForm(authMode?: AuthMode) {
    this.loginForm.reset();
    if (authMode) {
      this.setAuthModeValue(authMode);
    }
  }

  setAuthModeValue(authMode: AuthMode, event?: Event) {
    this.authMode = authMode;
    event?.preventDefault();
  }

  sendPasswordRecoveryEmail() {
    this.disabledSendButton = true;
    this.authService
      .forgotPassword(this.loginForm.login.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.disabledSendButton = false;
          this.setAuthModeValue(AuthMode.Login);
          this.notificationService.notify(response!, 'success');
        },
        error: errorResponse => {
          if (errorResponse.error.errors && errorResponse.error.errors.length) {
            this.notificationService.notify(
              errorResponse.error.errors[0].message,
              'error',
            );
          } else {
            this.notificationService.notify(errorResponse.message, 'error');
          }
        },
      });
  }

  resend2FACodeMessage(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.authService
      .resend2FACode(this.loginForm.login.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.notificationService.notify('2FA_CODE_SENT', 'success');
      });
  }

  private stopSpinner = <T>(source: Observable<T>) =>
    source.pipe(finalize(() => (this.isLoading = false)));

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
