import { Directive } from '@angular/core';
import { ProjectStateEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = ProjectStateEnum | undefined;

@Directive({
  selector: '[assetsProjectStateChipResolver]',
})
export class ProjectStateChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const state = model ?? ProjectStateEnum.Active;

    const classNames = state
      ? state === ProjectStateEnum.Active
        ? ['assets-chip-success']
        : state === ProjectStateEnum.OnHold ||
          state === ProjectStateEnum.Opportunity
        ? ['assets-chip-gray']
        : state === ProjectStateEnum.Completed
        ? ['assets-chip-yellow']
        : ['assets-chip-danger']
      : [];

    return {
      classNames,
      contentToken: state,
    };
  }
}
