export const REQUISITIONS_PERMISSIONS = {
  read: { module: 'Requisition', action: 'GetRequisitionsByFilter' },
  readByFilter: { module: 'Requisition', action: 'GetRequisitionsByFilter' },
  readById: { module: 'Requisition', action: 'GetRequisitionById' },
  update: { module: 'Requisition', action: 'UpdateRequisition' },
  create: { module: 'Requisition', action: 'AddRequisition' },
  updateRequisitionStatus: {
    module: 'Requisition',
    action: 'UpdateRequisitionStatus',
  },
  addRequisitionComment: {
    module: 'Requisition',
    action: 'AddRequisitionComment',
  },
  copyRequisition: { module: 'Requisition', action: 'CopyRequisition' },
};
