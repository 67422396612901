import {
  Directive,
  ElementRef,
  HostBinding,
  OnInit,
  inject,
} from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { MatLegacyInput } from '@angular/material/legacy-input';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription, takeUntil } from 'rxjs';

@Directive({
  selector: '[inputRef]',
})
export class InputRefDirective implements OnInit {
  private readonly translate = inject(TranslateService);
  private readonly matInput = inject(MatLegacyInput);
  private readonly formInputComponent = inject(FormInputComponent);
  private readonly inputEl: HTMLInputElement = inject(ElementRef).nativeElement;
  private tranlateSubscription: Subscription;

  @HostBinding('class') className = 'form-input_field';

  constructor(ngControl: NgControl) {
    if (ngControl.valueAccessor) {
      trimValueAccessor(ngControl.valueAccessor);
    }
  }

  ngOnInit() {
    this.setInputProps();
  }

  setInputProps() {
    this.inputEl.type = this.formInputComponent.type;
    this.inputEl.step = this.formInputComponent.step?.toString();
    this.inputEl.min = this.formInputComponent.minValue?.toString();
    this.inputEl.max = this.formInputComponent.maxValue?.toString();
    this.inputEl.maxLength = this.formInputComponent.maxLength;
    this.inputEl.readOnly = this.formInputComponent.readOnly;

    this.tranlateSubscription?.unsubscribe();
    this.tranlateSubscription = (
      !this.formInputComponent.placeholder
        ? EMPTY
        : this.translate.stream(this.formInputComponent.placeholder)
    )
      .pipe(takeUntil(this.formInputComponent.destroy$))
      .subscribe(placeholder => {
        this.inputEl.placeholder = placeholder;
      });

    this.matInput.errorStateMatcher = this.formInputComponent.matcher;
  }
}

function trimValueAccessor(valueAccessor: ControlValueAccessor) {
  const original = valueAccessor.registerOnChange;

  valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
    return original.call(valueAccessor, (value: unknown) => {
      return fn(typeof value === 'string' ? value.trim() : value);
    });
  };
}
