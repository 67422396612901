export const BILLINGS_PERMISSIONS = {
  readByFilter: { module: 'Billing', action: 'GetBillingsByFilter' },
  getHeader: { module: 'Billing', action: 'GetBillingsHeader' },
  updateState: { module: 'Billing', action: 'GetBaseNameBillings' },
  readGlobalBilling: { module: 'Billing', action: 'GetGlobalBillingByFilter' },
  timeSheetWithHistoryById: {
    module: 'Billing',
    action: 'GetTimeSheetWithHistoryById',
  },
};
