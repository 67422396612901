import { Injectable } from '@angular/core';
import { IError } from '@common/models';
import { Observable, of } from 'rxjs';
import { SnackbarComponent } from '../components/snackbars/snackbar.component';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Time } from '../enums/units.enum';
import { CrudMethod } from '@common/enums/crud.method';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  loginError: Observable<IError>;
  private readonly durationInSeconds = 3;

  constructor(
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  occurredError(error: any) {
    this.loginError = of({ message: error.message });
  }

  notify(message: string, status?: string, config?: any): MatSnackBarRef<any> {
    // const snackRes: MatSnackBarRef<any> = this._snackBar.open(message, undefined, {
    //   duration: this.durationInSeconds * 1000,
    // })
    /** new version*/
    const snackRes = this._snackBar.openFromComponent(
      SnackbarComponent,
      Object.assign(config ?? {}, {
        duration: this.durationInSeconds * Time.SECOND,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          message,
          status,
        },
      }),
    );
    return snackRes;
  }

  notifyError(message: string) {
    this.notify(message, 'error');
  }

  notifySuccess(modelName: string, method?: CrudMethod) {
    const methodToMessage = {
      [CrudMethod.Create]: 'ADDED_SUCCESSFULLY',
      [CrudMethod.Update]: 'UPDATED_SUCCESSFULLY',
      [CrudMethod.Delete]: 'DELETED_SUCCESSFULLY',
      [CrudMethod.Activate]: 'ACTIVATED_SUCCESSFULLY',
      [CrudMethod.Deactivate]: 'DEACTIVATED_SUCCESSFULLY',
    };

    let message = this.translateService.instant(modelName);
    if (method !== undefined) {
      message += ' ' + this.translateService.instant(methodToMessage[method]);
    }

    this.notify(message, 'success');
  }

  openDialog(message: string, status: string) {
    const config = {
      data: { message },
      height: '60rem',
      width: '45rem',
      maxWidth: '100%',
      disableClose: false,
      hasBackdrop: false,
    };

    switch (status) {
      case 'error':
        this._snackBar.openFromComponent(SnackbarComponent, {
          ...config,
          panelClass: 'error-snackbar',
        });
        break;
      case 'warn':
        this._snackBar.openFromComponent(SnackbarComponent, {
          ...config,
          panelClass: 'warn-snackbar',
        });
        break;
      case 'success':
        this._snackBar.openFromComponent(SnackbarComponent, {
          ...config,
          panelClass: 'success-snackbar',
        });
        break;
      default:
        this._snackBar.openFromComponent(SnackbarComponent, {
          ...config,
          panelClass: 'info-snackbar',
        });
    }
  }
}
