import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../projects/gtop/src/environments/environment';
import { GlobalErrorHandlerModule } from './errors/global-error-handling.module';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json?v=' + Date.now(),
  );
}

@NgModule({
  declarations: [],
  imports: [CommonModule, GlobalErrorHandlerModule, TranslateModule.forRoot()],
})
export class CoreModule {}
