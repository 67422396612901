import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, inject, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { I18N_CONFIG } from '../const';
import { AuthenticationService } from '../services/authentication.service';
import { NON_INTERCEPTED_REQUEST_CHECKER } from '@common/const/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private translate = inject(TranslateService);
  private authService = inject(AuthenticationService);
  private nonInterceptedReqChecker = inject(NON_INTERCEPTED_REQUEST_CHECKER);

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.authService.accessToken;
    const language = this.translate.currentLang || I18N_CONFIG.defaultLang;

    if (this.nonInterceptedReqChecker(req)) {
      return next.handle(req);
    }

    if (!token) {
      if (isDevMode()) {
        console.warn(
          "This request was called without token. And this method's name is not in NON_INTERCEPTED_REQUESTS const.",
        );
      }
      return EMPTY;
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': language,
      },
    });

    return next.handle(req);
  }
}

//TODO use functional interceptor with withInterceptors() in main.ts
export function authInterceptor(
  req: HttpRequest<any>,
  next: HttpHandler,
): Observable<HttpEvent<any>> {
  const token = inject(AuthenticationService).accessToken;
  const language =
    inject(TranslateService).currentLang || I18N_CONFIG.defaultLang;
  const nonInterceptedReqChecker = inject(NON_INTERCEPTED_REQUEST_CHECKER);

  if (nonInterceptedReqChecker(req)) {
    return next.handle(req);
  }

  if (!token) {
    if (isDevMode()) {
      console.warn(
        "This request was called without token. And this method's name is not in NON_INTERCEPTED_REQUESTS const.",
      );
    }
    return EMPTY;
  }

  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': language,
    },
  });

  return next.handle(req);
}
