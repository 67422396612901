import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusDropdownSelectComponent } from './status-dropdown-select.component';
import { I18nModule } from '../../i18n';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@common/components/form/form.module';

@NgModule({
  declarations: [StatusDropdownSelectComponent],
  imports: [CommonModule, FormModule, I18nModule, TranslateModule],
  exports: [StatusDropdownSelectComponent],
})
export class StatusDropdownSelectModule {} //TODO make it standalone
