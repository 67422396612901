import { Injector, NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  Observable,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { AuthenticationService } from '@common/services/authentication.service';
import { environment } from '@root/environments/environment';
import { onError } from '@apollo/client/link/error';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  skip,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SystemErrorCode } from '@common/enums/system-error-code.enum';
import { EMPTY, Subject } from 'rxjs';
import { NON_INTERCEPTED_OPERATIONS } from '@common/const/apollo.const';

const uri = environment.apiURL;

function isAuthErrorResponse(event: HttpErrorResponse): boolean {
  const isUnauthorized =
    event instanceof HttpErrorResponse &&
    event.status === HttpStatusCode.Unauthorized;
  if (!isUnauthorized) {
    return false;
  }

  type AuthError = {
    extensions?: {
      code?:
        | SystemErrorCode.AuthNotAuthenticated
        | SystemErrorCode.AuthNotAuthorized;
    };
  };
  const errors: AuthError[] = event.error?.errors ?? [];
  const isAuthError = errors.some(err =>
    (
      [
        SystemErrorCode.AuthNotAuthenticated,
        SystemErrorCode.AuthNotAuthorized,
      ] as const
    ).includes(err?.extensions?.code!),
  );

  return isAuthError;
}

export function createApollo(httpLink: HttpLink, injector: Injector) {
  const basic = setContext((operation, context) => ({
    connectToDevTools: true,
  }));

  const auth = setContext((operation, { headers }) => {
    const token = localStorage.getItem('accessToken');
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  let refreshTokenInProgress = false;

  const unauthorizedErrorLink = onError(
    ({ forward, graphQLErrors, networkError, operation }) => {
      const authService = injector.get<AuthenticationService>(
        AuthenticationService,
      );
      const unsubscribe$ = new Subject<void>();

      if (
        NON_INTERCEPTED_OPERATIONS.includes(
          operation.operationName as (typeof NON_INTERCEPTED_OPERATIONS)[number],
        )
      ) {
        return;
      }

      if (!isAuthErrorResponse(networkError as HttpErrorResponse)) {
        return;
      } else {
        return new Observable(subscriber => {
          if (refreshTokenInProgress) {
            authService.accessToken$
              .pipe(
                skip(1),
                distinctUntilChanged(),
                switchMap(token => {
                  forward(operation).subscribe(subscriber);
                  return EMPTY;
                }),
                takeUntil(unsubscribe$),
                takeUntil(
                  authService.user$.pipe(
                    filter(user => user === null),
                    tap(() => subscriber.complete()),
                  ),
                ),
              )
              .subscribe();
          } else {
            refreshTokenInProgress = true;
            return authService
              .refreshToken()
              .pipe(
                finalize(() => (refreshTokenInProgress = false)),
                switchMap(result => {
                  forward(operation).subscribe(subscriber);
                  return EMPTY;
                }),
                catchError(err => {
                  subscriber.error(err);
                  return EMPTY;
                }),
                takeUntil(unsubscribe$),
              )
              .subscribe();
          }

          return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
          };
        });
      }
    },
  );

  const link = ApolloLink.from([
    unauthorizedErrorLink,
    basic,
    httpLink.create({ uri, withCredentials: true }),
  ]);
  const cache = new InMemoryCache();
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  };
  return {
    link,
    cache,
    defaultOptions,
  };
}

@NgModule({
  exports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Injector],
    },
  ],
})
export class GraphQLModule {}
