import { NgModule } from '@angular/core';
import { StandaloneComponentModule } from './standalone-component.module';
import { PageTabBodyComponent } from '@common/components/page-tab/page-tab-body/page-tab-body.component';
import { PageTabMainComponent } from '@common/components/page-tab/page-tab-main/page-tab-main.component';
import { PageTabActionsComponent } from '@common/components/page-tab/page-tab-actions/page-tab-actions.component';

@NgModule({
  imports: [
    PageTabBodyComponent,
    PageTabMainComponent,
    PageTabActionsComponent,
  ],
  exports: [
    PageTabBodyComponent,
    PageTabMainComponent,
    PageTabActionsComponent,
  ],
})
export class StandalonePageTabModule {}
