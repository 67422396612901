import { Directive, Injector, Input, isDevMode } from '@angular/core';
import { StatusChipComponent } from '@common/components/status-chip/status-chip.component';

@Directive()
export abstract class StatusChipResolverDirective<T> {
  @Input() set model(value: T) {
    this.setPropertiesOnChipComponent(value);
  }

  @Input() optionPrefix: string;

  constructor(private injector: Injector) {}

  abstract getValues(
    model: T,
  ): Pick<StatusChipComponent<T>, 'classNames' | 'contentToken'>;

  private setPropertiesOnChipComponent(model: T) {
    const statusActivityComponent: StatusChipComponent<T> =
      this.injector.get(StatusChipComponent);
    if (!statusActivityComponent) {
      if (isDevMode()) {
        console.error(
          `${this.constructor.name} have to be applied on StatusActivityComponent`,
        );
      }
    } else {
      const { classNames, contentToken } = this.getValues(model);
      statusActivityComponent.classNames = classNames;
      statusActivityComponent.contentToken = this.optionPrefix
        ? this.optionPrefix + '_' + contentToken
        : contentToken;
    }
  }
}
