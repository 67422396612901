<div class="col-9">
  <h3 class="required-skill_header">{{ 'REQUIRED_SKILL' | translate }}</h3>
  <div class="required-skills">
    <section class="extendable">
      <div #TABLE class="table-wrapper equipments-container">
        <mat-table
          matSort
          [dataSource]="requiredSkills"
          class="cursor-pointer table-gray"
        >
          <ng-container matColumnDef="skill">
            <mat-header-cell *matHeaderCellDef
              >{{ 'SKILL' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{ row.skill.name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="level">
            <mat-header-cell *matHeaderCellDef
              >{{ 'SKILL_LEVEL' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{ row.level.name }}</span>
            </mat-cell>
          </ng-container>

          <!-- Header and Row Declarations -->
          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </section>
  </div>
</div>
