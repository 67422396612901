import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { configConfirm } from '@common/const/dialog.const';
import { NotificationService } from '@common/services/notification.service';
import { GlobalErrorWithReloadDialog } from './global-error-with-reload-dialog.component';
import { AuthenticationService } from '@common/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private auth: AuthenticationService,
  ) {}

  private currentError = '';

  handleError(error: Error) {
    if (this.handleChunkLoadError(error)) {
      return;
    }

    if (error instanceof HttpErrorResponse) {
      const errors = (error.error as any).errors;
      if (errors) {
        this.notificationService.notifyError(errors[0].message);
      }

      return;
    }

    const errorText = error.toString();

    if (this.currentError !== errorText) {
      this.currentError = errorText;
      this.zone.run(() => {
        this.notificationService.notify(errorText, 'error');
      });
    }

    console.error('error occurred in handleError', error);
  }

  handleChunkLoadError(error: any): boolean {
    if (error.rejection?.name === 'ChunkLoadError') {
      this.zone.run(() => {
        const config = {
          ...configConfirm,
          data: {
            title: 'CHUNK_LOAD_ERROR',
          },
        };
        const referenceInfoDialog = this.matDialog.open(
          GlobalErrorWithReloadDialog,
          config,
        );
        referenceInfoDialog.componentInstance.reload$.subscribe(() => {
          this.auth
            .logoutWithRedirect()
            .then(result => result && window.location.reload());
        });
      });

      return true;
    }

    return false;
  }
}
