import { Directive, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { IError } from '@common/models';

@Directive({
  selector: '[appError]',
})
export class ErrorDirective implements OnInit {
  error: IError;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    // this.notificationService.occurredError()
  }
}
