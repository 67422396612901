import { Directive } from '@angular/core';
import { StatusChipResolverDirective } from '@common/directives/status-chip-resolver/status-chip-resolver.directive';
import { OtherBillStatusEnum } from '@common/generated/graphql';

type Item = OtherBillStatusEnum | undefined;
@Directive({
  selector: '[assetsOtherBillStatusChipResolver]',
})
export default class OtherBillStatusChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const status = model?.toString();
    let classNames: string[];
    switch (model) {
      case OtherBillStatusEnum.Review:
        classNames = ['assets-chip-gray'];
        break;
      case OtherBillStatusEnum.PaApproved:
        classNames = ['assets-chip-yellow'];
        break;
      case OtherBillStatusEnum.ExecutiveApproved:
        classNames = ['assets-chip-primary'];
        break;
      case OtherBillStatusEnum.Paid:
        classNames = ['assets-chip-success'];
        break;
      default:
        classNames = ['assets-chip-danger'];
        break;
    }

    return {
      classNames,
      contentToken: status ?? '',
    };
  }
}
