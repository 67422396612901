import { Directive } from '@angular/core';
import { CandidateActionEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = CandidateActionEnum;

@Directive({
  selector: '[appCandidateActionsChipResolver]',
})
export class CandidateActionsChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const action = model;
    const classNames = this.resolveClassName(action);

    return {
      classNames,
      contentToken: action ?? '',
    };
  }

  resolveClassName(state: CandidateActionEnum): string[] {
    switch (state) {
      case CandidateActionEnum.AcceptBenchHire:
      case CandidateActionEnum.AcceptFromClient:
      case CandidateActionEnum.AcceptRecommendation:
      case CandidateActionEnum.CreateCandidate:
        return ['assets-chip-yellow'];
      case CandidateActionEnum.PlaceCandidate:
      case CandidateActionEnum.ApproveBenchHire:
      case CandidateActionEnum.ContinueOnboarding:
        return ['assets-chip-success'];
      case CandidateActionEnum.WithdrawCandidate:
      case CandidateActionEnum.ScheduleInterviews:
        return ['assets-chip-primary'];
      case CandidateActionEnum.RecommendToDelivery:
      case CandidateActionEnum.LogInterviewResult:
        return ['mat-chip-grey'];
      default:
        return ['assets-chip-danger'];
    }
  }
}
