import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthorizationService } from '@common/services/authorization.service';
import { RoleEnum } from '@common/generated/graphql';
import { checkIfAuthorizedGuard } from '@common/guards/role-guard/check-if-authorized.guard';

export function hasRoleGuard(role: RoleEnum): CanActivateFn {
  return () =>
    checkIfAuthorizedGuard(inject(AuthorizationService).hasRole(role));
}
