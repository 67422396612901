<mat-list class="nav-list">
  <ng-container *ngFor="let topRoute of routes">
    <ng-container
      *ngIf="checkRouteVisibility | memoize: topRoute.checkVisibility"
    >
      <mat-accordion
        *appPermissionIf="topRoute.permission; mode: topRoute.permissionsMode"
        [multi]="true"
        class="mat-elevation-z0 nav-item-expansion-panel"
      >
        <ng-container
          [ngTemplateOutlet]="navMenuItem"
          [ngTemplateOutletContext]="{
            route: topRoute,
            routerCommands: [],
            level: 0
          }"
        ></ng-container>
        <ng-template
          #navMenuItem
          [typedTemplate]="navMenuItemTypeToken"
          let-route="route"
          let-routerCommands="routerCommands"
          let-level="level"
        >
          <ng-container *ngIf="!route.children; else hasChildren">
            <ng-container
              [ngTemplateOutlet]="listItem"
              [ngTemplateOutletContext]="{
                route: route,
                routerCommands: routerCommands,
                level: level,
                hasChildren: false
              }"
            ></ng-container>
          </ng-container>
          <ng-template #hasChildren>
            <mat-expansion-panel
              class="mt-0"
              *appPermissionIf="route.permission; mode: route.permissionsMode"
            >
              <mat-expansion-panel-header matRipple>
                <mat-panel-title>
                  <ng-container
                    [ngTemplateOutlet]="listItem"
                    [ngTemplateOutletContext]="{
                      route: route,
                      routerCommands: [],
                      level: level,
                      hasChildren: true
                    }"
                  ></ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let submenu of route.children">
                <ng-container
                  [ngTemplateOutlet]="navMenuItem"
                  [ngTemplateOutletContext]="{
                    route: submenu,
                    routerCommands: (routerCommands.length
                      ? routerCommands
                      : [route.path]
                    ).concat(submenu.path),
                    level: level + 1
                  }"
                ></ng-container>
              </ng-container>
            </mat-expansion-panel>
          </ng-template>
          <ng-template
            #listItem
            [typedTemplate]="listItemTypeToken"
            let-hasChildren="hasChildren"
            let-route="route"
            let-routerCommands="routerCommands"
            let-level="level"
          >
            <ng-container
              *ngIf="checkRouteVisibility | memoize: route.checkVisibility"
            >
              <mat-list-item
                class="mb-2 cursor-pointer"
                *appPermissionIf="route.permission; mode: route.permissionsMode"
                [style]="'padding-left:' + level * 13 + 'px'"
                assetsReturnBackUrl
                assetsBlockedReturnBackUrl
                matRipple
                [disableRipple]="hasChildren"
                [routerLink]="
                  hasChildren
                    ? null
                    : routerCommands.length
                    ? routerCommands
                    : [route.path]
                "
                [routerLinkActive]="hasChildren ? '' : 'mat-list-item-active'"
                [ngClass]="{
                  disabled: route.disabled,
                  'expansion-header cursor-pointer': hasChildren
                }"
              >
                <div class="nav-item">
                  <p>{{ route.name | uppercase | translate }}</p>
                </div>
              </mat-list-item>
            </ng-container>
          </ng-template>
        </ng-template>
      </mat-accordion>
    </ng-container>
  </ng-container>
</mat-list>
