import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export const DEBOUNCE_TIMEOUT = 700;
export const MAX_DATE_STRING = '9999-12-31';

export const NUMBER_FN = Validators.pattern('-?[0-9.]*');
export const INTEGER_FN = Validators.pattern('[0-9]*');

export const NUMBER_WITH_LETTERS_FN = Validators.pattern('[a-zA-z0-9 -]*');

export const STRING_NOT_EMPTY_FN = Validators.pattern('.*\\S.*[a-zA-z0-9 ]*');
export const WEB_URL_PATTERN_FN = Validators.pattern(
  '/^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/\n',
);

export const MIN_LENGTH_1_FN = Validators.minLength(1);

export const MIN_LENGTH_2_FN = Validators.minLength(2);

export const MIN_LENGTH_3_FN = Validators.minLength(3);
export const MAX_LENGTH_3_FN = Validators.maxLength(3);

export const MIN_LENGTH_4_FN = Validators.minLength(4);
export const MAX_LENGTH_4_FN = Validators.maxLength(4);

export const MAX_LENGTH_5_FN = Validators.maxLength(5);

export const MIN_LENGTH_6_FN = Validators.minLength(6);
export const MAX_LENGTH_6_FN = Validators.maxLength(6);

export const MAX_LENGTH_20_FN = Validators.maxLength(20);
export const MAX_LENGTH_25_FN = Validators.maxLength(25);

export const MAX_LENGTH_50_FN = Validators.maxLength(50);

export const MAX_LENGTH_100_FN = Validators.maxLength(100);

export const MAX_LENGTH_250_FN = Validators.maxLength(250);

export const MAX_LENGTH_256_FN = Validators.maxLength(256);

export const MAX_LENGTH_500_FN = Validators.maxLength(500);

export const MAX_LENGTH_1000_FN = Validators.maxLength(1000);

export const MAX_LENGTH_3000_FN = Validators.maxLength(3000);

export const MIN_PASSWORD_LENGTH_FN = MIN_LENGTH_4_FN;
export const MAX_PASSWORD_LENGTH_FN = Validators.maxLength(16);

export const LATIN_RUS_NAME_FN = Validators.pattern('[a-z,A-Z,-,)(, ,-]*');

export const WEB_URL_FN = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  let givenURL: URL;
  let isValid = true;
  try {
    givenURL = new URL(control.value);
  } catch (error) {
    isValid = false;
  }
  isValid =
    isValid &&
    (givenURL!.protocol === 'http:' || givenURL!.protocol === 'https:');
  return !isValid ? { invalidUrl: { value: control.value } } : null;
};

export const POSITIVE_NUMBER_FN = (
  control: AbstractControl,
): ValidationErrors | null => {
  const isNegative = Number(control.value) < 0;
  return isNegative ? { nonPositive: { value: control.value } } : null;
};

export const ONE_TO_THREE_NUMBER_FN = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = Number(control.value);
  const isValid = value === 1 || value === 2 || value === 3 || !value;
  return !isValid ? { valid: { value: control.value } } : null;
};

export const EMAILS_LIST_STRING = (
  control: AbstractControl,
): ValidationErrors | null => {
  const emails = (control.value ?? '').split(';');
  const isValid = emails
    .filter(Boolean)
    .every((email: string) => !Validators.email(new FormControl(email.trim())));
  return !isValid
    ? { error: 'Please enter a semicolon-separated list of emails' }
    : null;
};

export function appFormFactory<T extends FormGroup>(
  fg: T,
  initValue: () => {},
) {
  return {
    fg,
    initValue,
  };
}
