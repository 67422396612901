import { Pipe, PipeTransform } from '@angular/core';
import { ShortUserInfo, UserInfo } from '@common/generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

@Pipe({
  name: 'userName',
})
export class UserNameTransformPipe implements PipeTransform {
  transform(user: Maybe<ShortUserInfo | UserInfo> | undefined) {
    if (!user) {
      return '';
    }

    return user?.firstName !== null
      ? `${user?.firstName} ${user?.lastName}`
      : user?.userName ?? '';
  }
}
