<h3 class="font-weight-bold m-0">{{ label | translate }}</h3>
<ng-container *appPermissionIf="permission">
  <assets-rich-text [control]="control"></assets-rich-text>
  <div class="locate-between justify-content-end">
    <button
      mat-flat-button
      (click)="addLog()"
      [disabled]="control?.invalid || control?.untouched"
      class="assets-btn primary-btn mt-2"
    >
      <mat-icon>add</mat-icon>
      {{ 'SEND' | translate }}
    </button>
  </div>
</ng-container>

<div>
  <ng-container *ngFor="let comment of comments">
    <div class="d-flex flex-column">
      <p class="text-md">
        {{ comment.dateTime | customDateTime }}
      </p>
      <ng-container *ngIf="!comment?.employeeId; else employee">
        <p class="mb-3">{{ comment.userName }}</p>
      </ng-container>
      <ng-template #employee>
        <a
          class="text-md mb-3"
          [routerLink]="['/employees/', comment?.employeeId]"
          >{{ comment.employeeName }}</a
        >
      </ng-template>
      <quill-view-html [content]="comment?.message ?? ''"></quill-view-html>
    </div>
  </ng-container>
</div>
