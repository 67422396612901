<mat-progress-bar
  *ngIf="navigation$ | async"
  class="nav-progress-bar"
  mode="indeterminate"
></mat-progress-bar>
<div
  [class]="'light-theme h-100 w-100 assets-wrapper'"
  [class.full-screen]="fullScreenService.isOn"
>
  <nav
    class="assets-navbar"
    [class.nav-non-visible]="fullScreenService.isOn && isOverlayOpened"
  >
    <div>
      <app-header-logo />
    </div>
    <div>
      <app-breadcrumb />
    </div>
    <div>
      <button
        *ngIf="hasRightDrawer"
        mat-icon-button
        color="primary"
        (click)="rightDrawer.toggle()"
      >
        <mat-icon>{{
          projectedPage?.rightDrawer?.icon ?? 'filter_list'
        }}</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="fullScreenService.toggle()"
      >
        <mat-icon>{{
          !fullScreenService.isOn ? 'fullscreen' : 'fullscreen_exit'
        }}</mat-icon>
      </button>
      <app-report-issue-button />
      <!--      TODO 81992 hided localization -->
      <!--      <app-i18n-dropdown class="mr-2"></app-i18n-dropdown>-->
      <!--      <div>-->
      <app-avatar-bar />
      <!--      </div>-->
    </div>
  </nav>
  <mat-drawer-container autosize [hasBackdrop]="hasBackdrop">
    <mat-drawer
      #leftDrawer
      class="left-drawer"
      disableClose
      mode="side"
      opened="true"
      position="start"
      [class]="panelOpenState ? 'opened' : 'collapsed'"
    >
      <div class="sidenav" [@inOutAnimation]="animationState">
        <main>
          <app-nav-menu></app-nav-menu>
        </main>
        <footer class="m-2">
          <ng-container *appVar="releaseVersion$ | async as releaseVersion">
            <div
              class="release-version"
              *ngIf="releaseVersion?.backend || releaseVersion?.frontend"
            >
              <span>{{ releaseVersion.frontend ?? '-' }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{{ releaseVersion.backend ?? '-' }}</span>
            </div>
          </ng-container>
        </footer>
      </div>
    </mat-drawer>
    <div #page class="page">
      <!--      <app-breadcrumb></app-breadcrumb>-->
      <router-outlet></router-outlet>
      <ngx-spinner name="root"> </ngx-spinner>
    </div>
    <mat-drawer
      #rightDrawer
      class="right-drawer"
      mode="over"
      opened="false"
      position="end"
      (openedStart)="onRightDrawerOpen()"
    >
      <ng-template appPageRightDrawerContent></ng-template>
    </mat-drawer>
  </mat-drawer-container>
</div>
<!---->
