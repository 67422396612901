import { Observable } from 'rxjs';

export type GqlItem<T> = NonNullable<
  T[Exclude<keyof T, '__typename'>]
> extends {
  items?: Array<infer I> | null;
}
  ? I
  : never;

export type NonNullableGqlItem<T> = NonNullable<GqlItem<T>>;

export type GQLItemFromService<T extends (...args: any) => any> =
  ReturnType<T> extends Observable<infer R> ? NonNullable<R> : never;

export function NonNullableArray<T>(value: T[]): NonNullable<T>[] {
  return value as NonNullable<T>[];
}
