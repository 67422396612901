import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

function loadTranslations() {
  const translateService = inject(TranslateService);
  const http = inject(HttpClient);

  return http
    .get(`/assets/i18n/${translateService.currentLang}.json?v=` + Date.now())
    .pipe(
      tap(data =>
        translateService.setTranslation(
          translateService.currentLang,
          data,
          true,
        ),
      ),
    );
}

export const authGuard = () => {
  const authenticationService = inject(AuthenticationService);
  const currentUser = authenticationService.currentUser;

  if (!currentUser) {
    return authenticationService.logoutWithRedirect();
  }

  return loadTranslations();
};
