import { Component, Inject, OnInit } from '@angular/core';
import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { IDialogData } from '@common/components/dialog/basic-dialog-form.component';
import { UploadFileBase64 } from '../form/form-file-input/form-file-input.component';

@Component({
  selector: 'assets-upload-dialog-form',
  template: ` <assets-base-dialog
      [actions]="action"
      [spinnerName]="data.spinnerName"
      [dialogTitle]="data.title"
    >
      <ng-container *ngFor="let control of data.controls">
        <ng-container [ngSwitch]="control.type">
          <ng-template [ngSwitchCase]="'file'">
            <assets-form-upload
              [validationKeys]="control?.validationKeys"
              [control]="$any(control.control)"
              [label]="control.label"
              (file)="onFileChange($event)"
              [accept]="control.accept"
              [showCropper]="control.showCropper"
              [placeholder]="control?.placeholder ?? control.label"
            >
            </assets-form-upload>
          </ng-template>

          <ng-template [ngSwitchCase]="'input'">
            <assets-form-input
              [validationKeys]="control?.validationKeys"
              [control]="$any(control.control)"
              [label]="control.label"
              [type]="control.inputType"
              [placeholder]="control?.placeholder ?? control.label"
            >
            </assets-form-input>
          </ng-template>
        </ng-container>
      </ng-container>
    </assets-base-dialog>

    <ng-template #action>
      <button
        type="submit"
        [disabled]="!valid"
        class="assets-btn primary-btn w-100"
        mat-flat-button
        (click)="onSubmit()"
      >
        {{ data.submitButtonText ?? 'ADD' | translate }}
      </button>
    </ng-template>`,
})
export class UploadDialogFormComponent
  extends BaseDialogComponent<any>
  implements OnInit
{
  content: string | ArrayBuffer | null;
  documentExtension: string;
  name: string;

  constructor(
    dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
  ) {
    super(dialogRef);
  }

  onFileChange(file: UploadFileBase64 | null) {
    if (file) {
      this.documentExtension = file.documentExtension;
      this.name = file.name;
      this.content = file.content;
    } else {
      this.documentExtension = '';
      this.name = '';
    }
  }
}
