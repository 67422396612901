import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { ENVIRONMENT, TITLE_PREFIX } from '@common/const/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleStrategy extends TitleStrategy {
  private readonly titleService = inject(Title);
  private readonly titlePrefix = inject(TITLE_PREFIX, { optional: true });
  private readonly environment = inject(ENVIRONMENT);
  private readonly translateService = inject(TranslateService);
  private readonly breadcrumbsSubscription: Subscription;

  override updateTitle(routerState: RouterStateSnapshot): void {}

  private setTitle(breadcrumbs: string[]) {
    const titleParts = [this.titlePrefix ?? 'gTOP'];
    if (this.environment.title) {
      titleParts.push(this.environment.title);
    }
    breadcrumbs.shift();
    breadcrumbs = breadcrumbs.map(breadcrumb =>
      this.translateService.instant(breadcrumb),
    );

    const title = titleParts.concat(breadcrumbs).join(' - ');

    this.titleService.setTitle(title);
  }

  pushBreadcrumbs(breadcrumbs: string[]) {
    this.setTitle(breadcrumbs);
  }
}
