import { Component, inject, Input, OnDestroy } from '@angular/core';
import { StandaloneComponentModule } from '@common/modules';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'assets-spinner',
  standalone: true,
  imports: [StandaloneComponentModule],
  template: `<ngx-spinner [name]="name"></ngx-spinner>`,
})
export class StandaloneSpinnerComponent implements OnDestroy {
  private readonly service = inject(SpinnerService);

  @Input() name: string = 'page-host';
  @Input() set show(value: boolean) {
    if (value) {
      this.service.show(this.name);
    } else {
      this.service.hide(this.name);
    }
  }

  ngOnDestroy(): void {
    this.service.hide(this.name);
  }
}
