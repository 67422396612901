import { Directive } from '@angular/core';
import { InvoiceStatusEnum } from '@common/generated/graphql';
import InvoiceStatusChipResolverDirective from '@common/directives/status-chip-resolver/invoice-status-chip-resolver.directive';

type Item = InvoiceStatusEnum | undefined;
@Directive({
  selector: '[assetsAccountReceivableStatusChipResolver]',
})
export default class AccountReceivableStatusChipResolverDirective extends InvoiceStatusChipResolverDirective {
  override getValues(model: Item) {
    let status = model?.toString();
    let classNames: string[];

    if (status === InvoiceStatusEnum.Billed) {
      classNames = ['assets-chip-gray', 'assets-chip-outline-bold'];
      status = `${this.translateService.instant(status ?? '')}!`;
    } else {
      return super.getValues(model);
    }

    return {
      classNames,
      contentToken: status ?? '',
    };
  }
}
