import { Pipe, PipeTransform } from '@angular/core';
import { BasicDialogControl, ISelectOption } from '@common/models';
import { isObservable, Observable, of } from 'rxjs';

@Pipe({
  name: 'baseDialogOptions',
})
export class BaseDialogOptionsPipe implements PipeTransform {
  transform(
    value: BasicDialogControl['options'],
  ): Observable<ISelectOption[] | string[]> {
    return isObservable(value) ? value : of(value ?? []);
  }
}
