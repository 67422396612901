import { NgModule } from '@angular/core';
import { TextSearchComponent } from './text-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@common/components/form/form.module';
import { TippyDirective } from '@ngneat/helipopper';
import { PipesModule } from '@common/pipes/pipes.module';
import { DirectivesModule } from '@common/directives/directives.module';

@NgModule({
  declarations: [TextSearchComponent],
  exports: [TextSearchComponent],
  imports: [
    FormModule,
    TranslateModule,
    TippyDirective,
    PipesModule,
    DirectivesModule,
  ],
})
export class TextSearchModule {}
