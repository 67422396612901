import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BasePageFacadeService } from '@common/pages/base-page-facade.service';
import { PageRouteConfig } from '@common/pages/route-config';
import { PAGE_BREADCRUMB_MAP_FN } from '@common/pages/tokens';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-requisite',
  templateUrl: './requisite.component.html',
  styleUrls: ['./requisite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisiteComponent {
  title$: Observable<string>;

  constructor(
    private pageFacadeService: BasePageFacadeService<any>,
    @Inject(PAGE_BREADCRUMB_MAP_FN)
    pageBreadcrumbMapFn: NonNullable<PageRouteConfig['pageRequisite']>,
  ) {
    this.title$ = this.pageFacadeService?.pageData$.pipe(
      map(pageBreadcrumbMapFn),
    );
  }
}
