import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-status-chip',
  templateUrl: 'status-chip.component.html',
  styleUrls: ['status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusChipComponent<T> {
  @Output() readonly click = new EventEmitter<Event>();
  @Input() classNames: string[];
  @Input() contentToken: string;

  onClick(e: Event) {
    this.click.next(e);
  }
}
