import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { filter, Observable, of } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { map, tap } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public opened = false;
  public isOpen: Observable<boolean> = of(false);
  private dialogRef!: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private router: Router,
  ) {}

  init() {
    this.router.events
      .pipe(
        map(e => e),
        filter(event => {
          switch (event.constructor) {
            case NavigationEnd:
            case NavigationCancel:
            case NavigationError:
              return true;
            default:
              return false;
          }
        }),
      )
      .subscribe(() => this.dialog.closeAll());
  }

  openDialog(dialog: ComponentType<any>, config: MatDialogConfig) {
    if (!this.opened) {
      this.opened = true;
      this.isOpen = of(true);
      let dialogConfig = config ?? {
        data: undefined,
        maxHeight: '100%',
        width: '33.33rem',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      };
      this.dialogRef = this.dialog.open(dialog, dialogConfig);
      return this.dialogRef.afterClosed().pipe(
        tap(() => {
          this.opened = false;
          this.isOpen = of(false);
        }),
      );
    }
    return;
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
  openBottomSheet(component: any, config: any) {
    return this._bottomSheet.open(component, config);
  }
}
