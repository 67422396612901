import { SortDirection } from '@angular/material/sort';
import { SortEnumType } from '@common/generated/graphql';
import { Query } from 'apollo-angular';
import { Primitive } from 'ts-essentials';

export type ComplexSortItem<T> = {
  [K in keyof T]?: T[K] extends Primitive
    ? SortDirectionUppercase
    : ComplexSortItem<T[K]>;
};

type QueryWithFetch = { fetch: Query['fetch'] };
type SortFields<T extends QueryWithFetch> = Exclude<
  NonNullable<NonNullable<Parameters<T['fetch']>[0]>['order']>,
  any[]
>;
type ComplexSortFields<T extends SortFields<any>> = {
  [K in keyof NonNullable<T>]?:
    | NonNullable<Pick<NonNullable<T>, K>>[K]
    | null
    | SortEnumType;
};
export type ComplexSort<T extends QueryWithFetch> = {
  [K: string]: ComplexSortFields<SortFields<T>>;
};
export type ComplexSort2<
  T extends QueryWithFetch,
  KEY extends keyof any,
> = Partial<Record<KEY, ComplexSortFields<SortFields<T>>>>;

export type DefaultOrders<T extends QueryWithFetch> = ComplexSortFields<
  SortFields<T>
>[];

export type SortDirectionUppercase = Uppercase<SortDirection>;

export type Group = {
  isGroup?: boolean;
  group?: string;
};
export type Grouped<T> =
  | T
  | {
      isGroupBy?: boolean;
      group?: string;
    };
