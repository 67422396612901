import { Injectable } from '@angular/core';
import { DetailsInfoDialog } from '@common/components/dialog/details-dialog';
import {
  MAX_LENGTH_100_FN,
  MAX_LENGTH_250_FN,
  MIN_LENGTH_2_FN,
  STRING_NOT_EMPTY_FN,
} from '@common/const/form.const';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { BasicDialogControl, ISelectOption } from '@common/models';
import {
  BasicDialogFormComponent,
  IDialogData,
} from '@common/components/dialog/basic-dialog-form.component';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InputMaybe } from '@common/generated/graphql';

export interface IReference {
  id?: InputMaybe<number>;
  name?: InputMaybe<string>;
  description?: InputMaybe<string>;
}

export interface IReferenceDTO extends IReference {
  __typename?: InputMaybe<string>;
  isDefault?: InputMaybe<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  status: ISelectOption[] = [
    {
      name: 'NOT_ACTIVE',
      value: false,
    },
    {
      name: 'ACTIVE',
      value: true,
    },
  ];

  userInSystemStatus: ISelectOption[] = [
    {
      name: 'NOT_ACTIVE',
      value: true,
    },
    {
      name: 'ACTIVE',
      value: false,
    },
  ];

  constructor(
    private translateService: TranslateService,
    private matDialog: MatDialog,
    private fb: UntypedFormBuilder,
  ) {}

  openDetailDialog(row: IReferenceDTO, dictionaryName: string) {
    const { id, __typename, isDefault, ...fieldsForForm } = row;
    type FieldsForm = typeof fieldsForForm;
    const data = {
      title: this.translateService.instant(dictionaryName),
      fields: Object.keys(fieldsForForm).map(key => ({
        label: this.translateService.instant(key.toUpperCase()),
        value: fieldsForForm[key as keyof FieldsForm],
      })),
    };

    const detailsInfoDialog = this.matDialog.open(DetailsInfoDialog, { data });
    return detailsInfoDialog.afterClosed();
  }

  openDictionaryDialog(
    model?: IReference,
    dictionaryName?: string,
    lengthValidations = {
      name: MAX_LENGTH_100_FN,
      description: MAX_LENGTH_250_FN,
    },
  ) {
    const form = this.fb.group({
      name: [
        model?.name ?? '',
        {
          validators: [
            Validators.required,
            MIN_LENGTH_2_FN,
            lengthValidations.name,
            STRING_NOT_EMPTY_FN,
          ],
          updateOn: 'blur',
        },
      ],
      description: [model?.description ?? '', lengthValidations.description],
    });

    const controls: BasicDialogControl[] = [
      {
        type: 'input',
        control: form.get('name') as UntypedFormControl,
        label: 'NAME',
        placeholder: 'ENTER_NAME',
      },
      {
        type: 'textarea',
        control: form.get('description') as UntypedFormControl,
        label: 'DESCRIPTION',
        placeholder: 'ENTER_DESCRIPTION',
      },
    ];

    const data: IDialogData = {
      title: (model ? 'EDIT_' : 'ADD_') + dictionaryName,
      dialogRes: true,
      controls,
      spinnerName: 'reference-dialog',
    };

    const dialogRef = this.matDialog.open(BasicDialogFormComponent, { data });
    return dialogRef.componentInstance.submitted$.pipe(
      map(result =>
        result
          ? {
              ...form.value,
              id: model ? model.id : 0,
            }
          : 0,
      ),
    );
  }
}
