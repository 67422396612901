import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';
import { ImageCroppedEvent } from 'ngx-image-cropper/lib/interfaces';
import { UploadFileBase64 } from '../form-file-input/form-file-input.component';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'assets-form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormUploadComponent extends FormInputComponent {
  @Input() override type: string = 'file';
  @ViewChild('fileInput') override input: ElementRef<HTMLInputElement>;
  @Input() accept: string = '';
  @Input() required: boolean | null = null;
  @Input() showCropper: boolean = false;
  @Input() clearHidden: boolean = false;
  @Output() readonly file = new EventEmitter<UploadFileBase64 | null>();
  @ContentChild('name') content: TemplateRef<string>;
  fileName?: string;
  imageChangedEvent: Event | string = '';
  croppedImage: ImageCroppedEvent['base64'] = '';

  onChange(event: Event): void {
    this.imageChangedEvent = event;
    const fileList = (event.target as HTMLInputElement)?.files ?? null;
    this.changeFile(fileList);
  }

  changeFile(fileList: FileList | null): void {
    const file = fileList?.[0];
    if (!file) {
      return;
    }
    this.fileName = file?.name ?? '';
    this.convertFileToBase64(file);
  }

  imageCropped(event: ImageCroppedEvent & UploadFileBase64) {
    this.croppedImage = event.base64;
    event.content = event.base64?.toString() ?? '';
    this.emitFile(event.content);
  }

  convertFileToBase64(file: File) {
    if (file.size === 0) {
      this.control.setErrors({
        emptyFile: 'EMPTY_FILE_ERROR',
      });
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.emitFile(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  emitFile(content: string): void {
    const value: UploadFileBase64 = {
      name: this.fileName!,
      documentExtension: this.fileName!.split('.').at(-1)!,
      content: content.replace(/^(data:.+,)/, ''),
    };

    this.file.emit(value);
  }

  override hasRequiredField = (control: AbstractControl): boolean => {
    if (this.required !== null) {
      return this.required;
    }

    return control.hasValidator(Validators.required);
  };

  override clear() {
    this.fileName = '';
    this.input.nativeElement.value = '';
    this.control.setValue(null);
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.file.emit(null);
  }
}
