import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { I18nComponent } from './i18n.component';
import { LANG_CONFIG } from './i18n.service';
import { LanguageConfig } from './i18n.model';

@NgModule({
  declarations: [I18nComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule],
  exports: [I18nComponent],
})
export class I18nModule {
  static forRoot(config: LanguageConfig): ModuleWithProviders<any> {
    return {
      ngModule: I18nModule,
      providers: [{ provide: LANG_CONFIG, useValue: config || {} }],
    };
  }
}
