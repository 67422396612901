<div class="change-image-box align-items-center d-flex flex-column">
  <img
    [extension]="extension"
    [useAsBase64]="useAsBase64"
    [appAvatar]="useAsBase64 ? imageBase64 : imageURL"
    [defaultImage]="defaultImage"
  />

  <button
    *appPermissionIf="permission"
    mat-flat-button
    class="assets-btn ghost-btn-deep-1 mr-2 mt-3 btn-change-photo"
    [title]="title | translate"
    (click)="openImageDialog()"
  >
    <mat-icon>edit</mat-icon>
    {{ 'CHANGE_' + title | translate }}
  </button>
</div>
