<div
  [class]="
    'assets-form upload-form assets-form-field ' + ('label-' + labelPosition)
  "
>
  <span class="assets-form-field-label text-left">
    {{ label | translate }}
    <span
      *ngIf="hasRequiredField(control)"
      class="text-danger font-weight-light"
      >*</span
    >
  </span>
  <div class="drop-container" [ngClass]="{ 'file-invalid': control.invalid }">
    <div>
      <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
      <ng-container *ngIf="content && !fileName">
        <span class="file-name">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </span>
      </ng-container>
      <ng-container *ngIf="!content && !fileName">{{
        'CHOOSE_FILE' | translate
      }}</ng-container>
      <input
        #fileInput
        type="file"
        [formControl]="control"
        [accept]="accept"
        (change)="onChange($event)"
      />
    </div>

    <button
      *ngIf="file && !clearHidden"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clear()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-icon *ngIf="suffix" matSuffix>{{ suffix }}</mat-icon>
  <mat-icon *ngIf="prefix" matPrefix>{{ prefix }}</mat-icon>

  <image-cropper
    *ngIf="showCropper"
    class="pt-4"
    format="png"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 4"
    [resizeToWidth]="200"
    [roundCropper]="true"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>
</div>
