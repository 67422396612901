import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'customDateTime',
})
export class CustomDateTimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(
    value: Date | string,
    format = 'M/d/yyyy h:mm a',
    timezone = '+0000',
  ): string | null {
    const datePipe = new DatePipe(this.translateService.currentLang, timezone);
    return datePipe.transform(value, format);
  }
}
