import { Directive } from '@angular/core';
import { RequirementSkillEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = RequirementSkillEnum | undefined;

@Directive({
  selector: '[assetsRequirementModeSkillChipResolver]',
})
export class RequirementSkillModeChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const state = model ?? RequirementSkillEnum.Required;

    const classNames = state
      ? state === RequirementSkillEnum.Required
        ? ['assets-chip-success']
        : state === RequirementSkillEnum.NiceToHave
        ? ['assets-chip-gray']
        : ['assets-chip-yellow']
      : [];

    return {
      classNames,
      contentToken: state,
    };
  }
}
