import { Component } from '@angular/core';
import { StandaloneComponentModule } from '@common/modules';

@Component({
  selector: 'assets-page-tab-body',
  imports: [StandaloneComponentModule],
  standalone: true,
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
      }
    `,
  ],
})
export class PageTabBodyComponent {}
