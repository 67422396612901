import { Directive } from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';

/**
 * The directive is an alias for CdkDrag.
 * It applies directly to the "mat-header-cell" to provide column reordering.
 */
@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-header-cell',
  hostDirectives: [CdkDrag],
})
export class TableDragElementDirective {}
