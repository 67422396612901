import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { BasicDialogControl } from '@common/models';
import { takeUntil, Observable } from 'rxjs';
import { hasRequiredField } from '@common/common/form.utils';

export interface IDialogData<T = undefined> {
  data?: T;
  title: string;
  buttonName?: string;
  dialogRes?: boolean;
  noAction?: boolean;
  spinnerName?: string;
  controls?: BasicDialogControl[];
  searchMethod?: (...params: unknown[]) => unknown;
  multiple?: boolean;
  notClosedAfterSubmit?: boolean;
  submitButtonText?: string;
  callback?: {
    synchronous: boolean;
    action: (...params: unknown[]) => Observable<unknown>;
  };
}

@Component({
  selector: 'assets-basic-dialog-form',
  template: ` <assets-base-dialog
      *ngIf="data"
      [actions]="data.noAction ? null : action"
      [spinnerName]="data.spinnerName"
      [dialogTitle]="data.title"
    >
      <ng-content></ng-content>

      <ng-container *ngFor="let control of data.controls">
        <ng-container [ngSwitch]="control.type">
          <ng-template ngSwitchCase="input">
            <assets-form-input
              [validationKeys]="control?.validationKeys"
              [control]="$any(control.control)"
              [step]="control.step!"
              [label]="control.label!"
              [type]="control.inputType!"
              [placeholder]="control?.placeholder ?? control.label!"
              [isPassword]="control.isPassword!"
              [isMatcher]="control.isMatcher!"
              [mask]="control.mask!"
              [disabled]="control.isDisabled!"
              [readonly]="control.isReadonly!"
              [minValue]="control.minValue!"
            />
          </ng-template>

          <ng-template ngSwitchCase="dropdown-search">
            <assets-form-dropdown-search
              [validationKeys]="control?.validationKeys"
              [control]="$any(control.control)"
              [label]="control.label!"
              [key]="control.key!"
              [placeholder]="control?.placeholder ?? control.label ?? ''"
              [searchMethod]="control.searchMethod!"
              [options]="(control.options | baseDialogOptions | async) ?? []"
              [disabled]="control.isDisabled ?? false"
              [multiple]="control?.multiple ?? false"
            />
          </ng-template>

          <ng-template ngSwitchCase="dropdown-search-with-chips">
            <assets-form-dropdown-with-chips
              [control]="control.control"
              [options]="(control.options | baseDialogOptions | async) ?? []"
              [label]="control.label"
              [placeholder]="control.placeholder"
            />
          </ng-template>

          <ng-template ngSwitchCase="dropdown">
            <assets-form-dropdown
              [control]="$any(control.control)"
              [validationKeys]="control?.validationKeys"
              [label]="control.label"
              [placeholder]="control?.placeholder ?? control.label"
              [options]="control.options | baseDialogOptions | async"
            />
          </ng-template>

          <ng-template ngSwitchCase="textarea">
            <assets-form-textarea
              [control]="$any(control.control)"
              [rows]="control?.rows ?? 2"
              [validationKeys]="control?.validationKeys"
              [label]="control.label"
              [placeholder]="control?.placeholder ?? control.label"
            />
          </ng-template>

          <ng-template ngSwitchCase="datepicker">
            <assets-form-datepicker
              [control]="$any(control.control)"
              [minDate]="control.minDate"
              [maxDate]="control.maxDate"
              [placeholder]="control.placeholder"
              [validationKeys]="control?.validationKeys"
              [label]="control.label"
              [type]="control.inputType ?? 'date'"
            />
          </ng-template>

          <ng-template ngSwitchCase="rich-text">
            <div>
              <span class="assets-form-field-label">
                {{ control.label | translate }}
                <span
                  *ngIf="hasRequiredField(control.control)"
                  class="text-danger font-weight-light"
                  >*</span
                >
              </span>
              <assets-rich-text [control]="control.control" />
            </div>
          </ng-template>

          <!-- TODO: We must eliminate this usecase  -->
          <ng-template ngSwitchCase="quill-editor">
            <assets-rich-text class="w-100" [control]="$any(control.control)" />
          </ng-template>

          <ng-template ngSwitchCase="rich-text-viewer">
            <quill-view-html [content]="$any(control.control.value)" />
          </ng-template>

          <ng-template ngSwitchCase="file">
            <assets-form-file-input
              [label]="control.label"
              [accept]="control.acceptFormat"
              [control]="$any(control.control)"
            />
          </ng-template>

          <ng-template ngSwitchCase="checkbox">
            <mat-checkbox
              class="mb-4"
              color="primary"
              [formControl]="control.control"
            >
              <span class="assets-form-field-label">
                {{ control.label ?? '' | translate }}
              </span>
            </mat-checkbox>
          </ng-template>
        </ng-container>
      </ng-container>
    </assets-base-dialog>

    <ng-template #action>
      <button
        type="submit"
        class="assets-btn primary-btn w-100"
        mat-flat-button
        [disabled]="!(valid && dirty)"
        (click)="onSubmit()"
      >
        {{ data.buttonName ?? 'SAVE' | translate }}
      </button>
    </ng-template>`,
})
export class BasicDialogFormComponent
  extends BaseDialogComponent<unknown>
  implements OnInit, AfterViewInit
{
  @Input() isMatcher: boolean;
  @Input('data') set dataInput(value: IDialogData) {
    this.data = value ?? this.data;
  }
  @Output() onSubmitted = new EventEmitter<void>();
  @ViewChild(BasicDialogFormComponent) component: BasicDialogFormComponent;

  hasRequiredField = hasRequiredField;

  constructor(
    dialogRef: MatDialogRef<unknown>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
  ) {
    super(dialogRef);
  }

  override onSubmit() {
    this.submitted$.next(true);
    if (this.data?.callback) {
      if (this.data.callback.synchronous) {
        this.data.callback.action();
        return this.dialogRef.close(this.data?.dialogRes ?? false);
      } else {
        this.data.callback.action().subscribe(() => {
          this.dialogRef.close(this.data?.dialogRes ?? false);
        });
      }
    }

    this.onSubmitted.next();

    if (!this.data.notClosedAfterSubmit) {
      this.dialogRef.close(true);
      // TODO should it be closed immediately?
    }
  }

  override ngAfterViewInit() {
    if (this.component) {
      this.component.submitted$
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => this.submitted$.next(res));
    }
  }

  override get valid(): boolean {
    return !this.data.controls!.some(
      control =>
        control.control.status === 'INVALID' ||
        (control.control.status === 'DISABLED' && control.control.invalid),
    );
  }

  get touched() {
    return this.data.controls!.some(control => control.control.touched);
  }

  get dirty() {
    return this.data.controls!.some(control => control.control.dirty);
  }
}
