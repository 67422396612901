import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'assets-status-dropdown-select',
  templateUrl: './status-dropdown-select.component.html',
  styleUrls: ['./status-dropdown-select.component.scss'],
})
export class StatusDropdownSelectComponent {
  @Input() active: UntypedFormControl;
  options: { name: string; value: string }[];
  @Input() set optionsMap(value: { [key: string]: string } | string[]) {
    this.options = Object.values(value).map(value => ({ name: value, value }));
  }
  @Input() label: string = 'STATUS';
  @Input() placeholder: string = '';
}
