import { Component, ViewEncapsulation } from '@angular/core';
import { I18nService } from './i18n.service';

@Component({
  selector: 'app-i18n-dropdown',
  template: `
    <div class="cursor-pointer languages-select" [matMenuTriggerFor]="menu">
      <img src="assets/images/{{ selectedLanguage }}.svg" />
      <mat-icon>arrow_drop_down</mat-icon>
      <mat-menu #menu="matMenu" class="languages-menu" xPosition="before">
        <!--        <button mat-menu-item (click)="handleChange('ky-KG')"> <img src="assets/images/ky-KG.svg" /> Кыргыз тили</button>-->
        <button mat-menu-item (click)="handleChange('ru-RU')">
          <img src="assets/images/ru-RU.svg" /> Русский язык
        </button>
        <button mat-menu-item (click)="handleChange('en-EN')">
          <img src="assets/images/en-EN.svg" /> English
        </button>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      .languages-select {
        display: flex;
        align-items: center;
        background: #f3f3f3;
        padding: 4px 8px;
        border-radius: 8px;
      }

      .languages-select img,
      .languages-menu img {
        width: 28px;
        height: 20px;
        margin-right: 5px;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class I18nComponent {
  selectedLanguage: string | undefined;
  constructor(private languageSv: I18nService) {
    this.selectedLanguage = this.languageSv.currentLanguage?.value;
  }

  handleChange(option: string) {
    this.selectedLanguage = option;
    this.languageSv.updateLang(option);
  }
}
