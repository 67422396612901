<assets-form-dropdown-search
  class="w-100"
  [control]="control"
  [options]="optionsForDropdown"
  [placeholder]="placeholder"
  [label]="label"
  [key]="key"
  [multiple]="true"
  [initOption]="initOption"
  [fetchMethod]="fetchMethod"
  (onSelected)="onSelected.next($event)"
>
</assets-form-dropdown-search>

<div *ngIf="selectedIds?.size" class="dropdown-chips-box">
  <ng-container *ngFor="let selectedId of selectedIds; trackBy: trackById">
    <div *ngIf="optionsMap.size" class="dropdown-chip app-chip-gray">
      <p class="my-auto">{{ optionsMap.get(selectedId)!.name }}</p>
      <button
        mat-icon-button
        class="icon-btn-md ml-3"
        (click)="remove(selectedId)"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
