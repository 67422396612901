import { inject } from '@angular/core';
import { BaseApolloService } from './base.apollo.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import { LOADER, ROUTES } from '../const';
import { HttpRequest } from '@angular/common/http';
import {
  ExternalSignInGQL,
  ExternalSignInQuery,
  ExternalSignInQueryVariables,
  ProviderEnum,
  SignInQuery,
  SignInQueryVariables,
  UserInfo,
} from '@common/generated/graphql';
import { DeepNullable, DeepPartial } from 'ts-essentials';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserPreferenceService } from './user-preferences.service';
import { SystemErrorCode } from '@common/enums/system-error-code.enum';
import { AuthMode } from '@common/modules/auth/pages/login/login.component';
import { IToken } from '@common/models';
import { RefreshTokenService } from './refresh-token.service';

type LoginResponse = {
  success: boolean;
  nextMode?: AuthMode;
};

export abstract class AuthenticationService extends BaseApolloService {
  private refreshTokenService = inject(RefreshTokenService);
  jwt$: Observable<string>;
  private userSubject = new BehaviorSubject<UserInfo | null>(null);
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(localStorage.getItem('accessToken') || '');
  accessToken$: Observable<string> = this.accessTokenSubject.asObservable();
  private socialSignInToken: string | null = null;

  user$ = this.userSubject.asObservable();

  get currentUser(): UserInfo | null {
    return structuredClone(this.userSubject.value);
  }

  get accessToken() {
    return this.accessTokenSubject.value;
  }

  private readonly authSocialService = inject(SocialAuthService, {
    optional: true,
  });
  private readonly userPreferencesService = inject(UserPreferenceService);
  private readonly matDialog = inject(MatDialog);
  private readonly router = inject(Router);

  constructor() {
    super();
    this.initUser();
    this.authSocialService?.authState
      .pipe(
        switchMap(socialUser =>
          socialUser ? this.socialSignIn(socialUser?.idToken) : of(),
        ),
      )
      .subscribe();
  }

  initUser() {
    const token = this.accessToken;
    let decoded: any;
    if (token && token !== 'undefined') {
      try {
        decoded = jwtDecode(token);
      } catch (error) {}
    }
    if (decoded && decoded !== 'undefined') {
      const user: UserInfo = JSON.parse(localStorage.getItem('user')!);
      this.userSubject.next({
        ...user!,
      });
    }
  }

  setToken(token?: DeepPartial<DeepNullable<IToken>> | null) {
    let user: any;
    if (token) {
      const { accessToken, refreshToken } = token;
      if (accessToken) {
        user = jwtDecode(accessToken);
        localStorage.setItem('accessToken', accessToken);
        this.accessTokenSubject.next(accessToken);
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.accessTokenSubject.next('');
    }
    this.jwt$ = this.getAccessToken();
    return user;
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      this.logoutWithRedirect();
      return of(false);
    }

    return this.refreshTokenService.refreshToken(refreshToken).pipe(
      tap(data => {
        const { accessToken, refreshToken } = data;

        if (accessToken && refreshToken && localStorage.getItem('user')) {
          this.setToken({ accessToken, refreshToken });
        } else {
          this.logoutWithRedirect();
        }
        // this.accessTokenSubject.next('');  //TODO  remove after TASK 84293
      }),
      catchError(() => {
        this.logout();
        return of(false);
      }),
    );
  }

  abstract login(params: SignInQueryVariables): Observable<LoginResponse>;

  socialSignIn(idToken: string) {
    this.socialSignInToken = idToken;

    const params: ExternalSignInQueryVariables = {
      externalAuthRequest: {
        idToken,
        provider: ProviderEnum.Google,
      },
    };
    return this.query(ExternalSignInGQL, params, {
      spinner: { name: LOADER.ROOT },
    }).pipe(
      map(({ data, errors }) =>
        this.mapSignInResponse(data.externalSignIn, errors),
      ),
    );
  }

  protected mapSignInResponse(
    response:
      | Partial<SignInQuery['signIn']>
      | ExternalSignInQuery['externalSignIn'],
    errors?: ApolloQueryResult<unknown>['errors'],
  ): LoginResponse {
    const result: LoginResponse = { success: false };

    if (response?.twoFactorVerificationSucceeded === false) {
      result.nextMode = AuthMode.TwoStepVerification;
    } else if (
      errors?.some(
        error => error.extensions?.code === SystemErrorCode.Auth2FACodeExpired,
      )
    ) {
      this.notificationService.notify('2FA_CODE_EXPIRED', 'error');
      result.nextMode = AuthMode.Login;
    } else if (errors && errors.length) {
      this.notificationService.notify(errors[0].message, 'error');
    } else {
      const { token, userInfo, userPreferences } = response ?? {};

      if (token && userInfo) {
        this.setToken(token);
        this.setUser(userInfo);
        this.initUser();
      }

      if (userPreferences) {
        this.userPreferencesService.setUserPreferences(userPreferences);
      }

      const returnUrl = new URL(location.href).searchParams.get('returnUrl');

      if (returnUrl) {
        this.router.navigateByUrl(decodeURI(returnUrl), {});
      } else {
        this.router.navigate([ROUTES.root]);
      }

      result.success = true;
    }

    return result;
  }

  logoutWithRedirect() {
    return this.logout('withRedirect');
  }

  logout(withRedirect?: 'withRedirect') {
    // if (!(this.currentUser && this.accessToken)) { //TODO  remove after TASK 84293
    //   return Promise.resolve(false);
    // }

    localStorage.setItem('refreshToken', '');
    localStorage.setItem('accessToken', '');
    this.accessTokenSubject.next('');

    localStorage.setItem('user', '');
    this.userSubject.next(null);

    if (this.socialSignInToken) {
      this.authSocialService
        ?.signOut()
        .then(() => (this.socialSignInToken = null));
    }
    this.matDialog.closeAll();
    const url = new URL(location.href);
    return this.router.navigate(
      [ROUTES.auth],
      withRedirect
        ? { queryParams: { returnUrl: encodeURI(url.pathname + url.search) } }
        : {},
    );
  }

  isAuthorized(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        of(Boolean(token && this.userSubject.value)),
      ),
    );
  }

  private getAccessToken(): Observable<string> {
    return of(this.accessTokenSubject.value);
  }

  getTokens(): IToken {
    return {
      accessToken: localStorage.getItem('accessToken') ?? '',
      refreshToken: localStorage.getItem('accessToken') ?? '',
    };
  }

  public skipRequest(request: HttpRequest<any>): boolean {
    return request.body?.operationName === 'RefreshToken';
  }

  public verifyTokenRequest(request: HttpRequest<any>): boolean {
    return request.body?.operationName === 'RefreshToken';
  }

  private setUser(
    user: NonNullable<NonNullable<SignInQuery['signIn']>['userInfo']>,
  ) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  abstract forgotPassword(email: string): Observable<string>;

  abstract resend2FACode(
    email: string,
  ): Observable<string | null | undefined | void>;
}
