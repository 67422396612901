import { Injectable } from '@angular/core';
import { isArray } from 'lodash-es';
import { AuthenticationService } from './authentication.service';
import { BaseNameInfo, RoleEnum } from '@common/generated/graphql';
import { PermissionsMode } from '@common/directives/permission-if.directive';

export type PermissionConfig = {
  module: string;
  action: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private authService: AuthenticationService) {
    this.authService.user$.subscribe(user => {
      const userRoles = (user?.userRoles ?? []).map(userRole => userRole!.type);
      this._userRoles = new Set<RoleEnum>(userRoles);
      this._hasPrimaryRole = !user
        ? false
        : this.hasAnyRole([
            RoleEnum.DeliveryManager,
            RoleEnum.AccountManager,
            RoleEnum.Recruiter,
          ]);
      this._attachedEmployee = {
        id: user?.employee?.id,
        name: `${user?.employee?.firstName} ${user?.employee?.lastName}`
      };
    });
  }

  private _attachedEmployee: BaseNameInfo | null = null;
  private _hasPrimaryRole = false;
  private _userRoles = new Set<RoleEnum>();

  hasPermission(
    permissions: PermissionConfig | PermissionConfig[],
    permissionsMode: PermissionsMode = PermissionsMode.Some,
  ): boolean {
    const permissionModels = this.getPermissionsModule();
    const methodsByMode = {
      [PermissionsMode.Some]: Array.prototype.some,
      [PermissionsMode.Every]: Array.prototype.every,
    };

    if (permissionModels) {
      if (isArray(permissions)) {
        return methodsByMode[permissionsMode].call(permissions, p =>
          this.checkPermission(p, permissionModels),
        );
      }
      return this.checkPermission(permissions, permissionModels);
    }
    return false;
  }

  hasRole(role: RoleEnum): boolean {
    return this.hasAnyRole([role]);
  }

  hasAnyRole(roles: RoleEnum[]): boolean {
    return roles.some(role => this._userRoles.has(role));
  }

  hasSingleRole(role: RoleEnum): boolean {
    if (this._userRoles.size !== 1) {
      return false;
    }
    return this.hasRole(role);
  }

  includesOnlyRoles(values: RoleEnum[]) {
    return [...this._userRoles].every(role => values.includes(role));
  }

  get isInterviewerOrEmployee() {
    return this.includesOnlyRoles([RoleEnum.Resource, RoleEnum.Interviewer]);
  }

  get hasPrimaryRole() {
    return this._hasPrimaryRole;
  }

  get hasAttachedEmployee() {
    return Boolean(this._attachedEmployee);
  }

  // TODO: move to another place, AuthorizationService is not a good place for this logic
  get attachedEmployee() {
    return this._attachedEmployee;
  }

  checkPermission(
    permission: PermissionConfig,
    permissionModels: any,
  ): boolean {
    const module = permissionModels.find(
      (m: any) => m.name === permission.module,
    );
    if (!module) {
    }
    if (module) {
      const permissionInModule = module.permissions.find(
        (p: any) => p.name === permission.action,
      );
      if (permissionInModule) {
        const permissions = this.getPermissions();
        const permission = permissions?.find(
          (p: any) => p.permissionId === permissionInModule.id,
        );
        return !!permission;
      }
    }
    return false;
  }

  getPermissionsModule() {
    return this.authService.currentUser?.allowedPermissions?.modules;
  }

  getPermissions() {
    return this.authService.currentUser?.userPermissionsForAuthorize;
  }
}
