import { NgClass } from '@angular/common';
import {
  Component,
  inject,
  InjectionToken,
  Input,
  OnInit,
} from '@angular/core';
import { StandaloneComponentModule } from '@common/modules/standalone-component.module';
import {
  AuthorizationService,
  PermissionConfig,
} from '@common/services/authorization.service';

export const CLICKABLE_LINKS = new InjectionToken<boolean>('CLICKABLE_LINKS');

@Component({
  standalone: true,
  imports: [StandaloneComponentModule],
  selector: 'assets-link',
  templateUrl: './link.component.html',
})
export class LinkComponent implements OnInit {
  readonly authorizationService = inject(AuthorizationService);
  private readonly clickableValueFromContext =
    inject(CLICKABLE_LINKS, { optional: true }) ?? true;
  @Input() clickable: boolean;
  @Input() text: string | null | undefined;
  @Input() commands: string[];
  @Input() permissions: PermissionConfig | PermissionConfig[];
  @Input() target: string = '_self';
  @Input() classes: NgClass['ngClass'];

  private hasPermission: boolean;

  protected clickableValue: boolean;

  ngOnInit(): void {
    if (this.permissions) {
      this.hasPermission = this.authorizationService.hasPermission(
        this.permissions,
      );
    }
    this.clickableValue =
      this.clickable ?? this.hasPermission ?? this.clickableValueFromContext;
  }
}
