import { Directive } from '@angular/core';
import { YesNoEnum } from '@common/enums/yes-no.enum';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = YesNoEnum;

@Directive({
  selector: '[appYesNoChipResolver]',
})
export class YesNoChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const classNames =
      model === YesNoEnum.Yes
        ? ['assets-chip-success']
        : ['assets-chip-danger'];
    return {
      classNames,
      contentToken: model,
    };
  }
}
