import { Component } from '@angular/core';
import { AuthenticationService } from '@common/services/authentication.service';
import { DictionaryService } from '../../../../../projects/gtop/src/app/shared/services/dictionary.service';
import { of } from 'rxjs';
import { WrapperModule } from '@common/components/wrapper/wrapper.module';
import { AnchorAppLayoutDirective } from '@common/directives/anchor-app-layout.directive';

@Component({
  standalone: true,
  selector: 'app-content-layout',
  template: ` <app-wrapper appLayoutAnchorRef></app-wrapper> `,
  imports: [WrapperModule, AnchorAppLayoutDirective],
})
export default class ContentLayoutComponent {
  loading$ = of(false);

  constructor(
    public authService: AuthenticationService,
    public dictionaryService: DictionaryService,
  ) {}
}
