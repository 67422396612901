import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDialogComponent } from '@common/components/dialog/base-dialog.component';
import { MaterialModule } from '@common/modules/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { BasicDialogFormComponent } from '@common/components/dialog/basic-dialog-form.component';
import { FormModule } from '@common/components/form/form.module';
import { UploadDialogFormComponent } from '@common/components/dialog/upload-dialog-form.component';
import { RouterModule } from '@angular/router';
import { BaseDialogOptionsPipe } from '@common/components/dialog/base-dialog-options.pipe';
import { ConfirmDeletionDialogComponent } from './confirm-dialog.component';
import { StatusDropdownSelectModule } from '@common/components/filters/status-dropdown-select/status-dropdown-select.module';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '@common/shared.module';
import { FormDropdownWithChipsComponent } from '@common/components/form/form-dropdown-with-chips/form-dropdown-with-chips.component';

@NgModule({
  declarations: [
    BaseDialogComponent,
    BasicDialogFormComponent,
    BaseDialogOptionsPipe,
    UploadDialogFormComponent,
    ConfirmDeletionDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    TranslateModule,
    FormModule,
    RouterModule,
    StatusDropdownSelectModule,
    QuillModule,
    SharedModule,
    FormDropdownWithChipsComponent,
  ],
  exports: [
    BaseDialogComponent,
    BasicDialogFormComponent,
    UploadDialogFormComponent,
    ConfirmDeletionDialogComponent,
  ],
})
export class DialogModule {}
