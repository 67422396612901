import { Component, inject } from '@angular/core';
import { NotificationService } from '@common/services/notification.service';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import ReportIssueService from '@common/services/report-issue.service';
import { DirectivesModule } from '@common/directives/directives.module';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  standalone: true,
  selector: 'app-report-issue-button',
  templateUrl: 'report-issue-button.component.html',
  imports: [MatIconModule, DirectivesModule, MatLegacyButtonModule],
})
export default class ReportIssueButtonComponent {
  protected destroy$ = new Subject<void>();
  reportIssueService = inject(ReportIssueService);
  notificationService = inject(NotificationService);

  reportIssue() {
    this.reportIssueService
      .open()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.notificationService.notify(
          'REPORT_ISSUE_SENT_SUCCESSFULLY',
          'success',
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
