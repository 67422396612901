import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  ForgotPasswordGQL,
  SendTwoStepVerificationMessageGtopGQL,
  SendTwoStepVerificationMessageGtopMutationVariables,
  SignInGQL,
  SignInQueryVariables,
} from '@common/generated/graphql';
import { AuthenticationService } from '@common/services/authentication.service';
import { LOADER } from '@common/const';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGtopService extends AuthenticationService {
  override login(params: SignInQueryVariables) {
    return this.query(SignInGQL, params, {spinner: {name: LOADER.ROOT}}).pipe(
      map(({ data, errors }) => super.mapSignInResponse(data.signIn, errors)),
    );
  }

  override resend2FACode(email: string) {
    const params: SendTwoStepVerificationMessageGtopMutationVariables = {
      request: {
        email,
      },
    };
    return this.mutate(SendTwoStepVerificationMessageGtopGQL, params).pipe(
      map(response => response.data?.sendTwoStepVerificationMessageGtop),
    );
  }

  override forgotPassword(email: string) {
    return this.mutate(ForgotPasswordGQL, { email }).pipe(
      map(response => response.data?.forgotPassword ?? ''),
    );
  }
}
