import { Directive } from '@angular/core';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = { isActive?: boolean | null };

@Directive({
  selector: '[appActivityStatusChipResolver]',
})
export class ActivityStatusChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const isActive = model?.isActive;

    const classNames = !isActive
      ? ['assets-chip-danger']
      : ['assets-chip-success'];

    return {
      classNames,
      contentToken: isActive ? 'ACTIVE' : 'IN_ACTIVE',
    };
  }
}
