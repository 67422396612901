import { Directive } from '@angular/core';
import { PlacementStatusEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

@Directive({
  selector: '[appPlacementStatusChipResolver]',
})
export class PlacementStatusChipResolverDirective extends StatusChipResolverDirective<PlacementStatusEnum> {
  getValues(model: PlacementStatusEnum) {
    const status = model;

    const classNames = status
      ? status === PlacementStatusEnum.Canceled
        ? ['assets-chip-danger']
        : status === PlacementStatusEnum.Active
        ? ['assets-chip-success']
        : status === PlacementStatusEnum.Completed
        ? ['assets-chip-yellow']
        : status === PlacementStatusEnum.OnHold ||
          status === PlacementStatusEnum.Replaced
        ? ['mat-chip-grey']
        : []
      : [];
    return {
      classNames,
      contentToken: status ?? '',
    };
  }
}
