import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-required-skills-table',
  templateUrl: './required-skills-table.component.html',
  styleUrls: ['./required-skills-table.component.scss'],
})
export class RequiredSkillsTableComponent {
  @Input() requiredSkills: [];

  displayedColumns = ['skill', 'level'];
}
