import { RefreshTokenGQL } from '@common/generated/graphql';
import { BaseApolloService } from '@common/services/base.apollo.service';
import { RefreshTokenService } from '@common/services/refresh-token.service';
import { map } from 'rxjs';

export class RefreshTokenGtopService
  extends BaseApolloService
  implements RefreshTokenService
{
  refreshToken(refreshToken: string) {
    const params = {
      RefreshTokenRequestInput: {
        token: refreshToken,
      },
    };

    return this.mutate(RefreshTokenGQL, params).pipe(
      map(data => {
        const { accessToken, refreshToken } =
          data?.data?.refreshToken?.token ?? {};

        return {
          accessToken: accessToken ?? '',
          refreshToken: refreshToken ?? '',
        };
      }),
    );
  }
}
