import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseApolloService } from '@common/services/base.apollo.service';
import {
  AddOrUpdateUserSkillsGQL,
  AddOrUpdateUserSkillsMutationVariables,
  DropManualPermissionsGQL,
  DropManualPermissionsMutationVariables,
  GroupedUserPreferencesGQL,
  GroupedUserPreferencesQueryVariables,
  ReportIssueGtopGQL,
  ReportIssueGtopMutationVariables,
  ResetUserPasswordGQL,
  ResetUserPasswordMutationVariables,
  SetOrUnsetUserToEmployeeAndVendorGQL,
  SetOrUnsetUserToEmployeeAndVendorMutationVariables,
  ToggleNotificationsGQL,
  ToggleNotificationsMutationVariables,
  UpdateUserGQL,
  UpdateUserMutationVariables,
  UpdateUserPreferencesGQL,
  UpdateUserPreferencesMutationVariables,
  UserByIdGQL,
  UserByIdQuery,
  UserByIdQueryVariables,
} from '@common/generated/graphql';

type UserInfo = NonNullable<UserByIdQuery['userById']>['user'];

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends BaseApolloService {
  user: UserInfo;

  getUser(params: UserByIdQueryVariables) {
    return this.query(UserByIdGQL, params).pipe(
      map(userResponse => userResponse?.data?.userById?.user ?? null),
    );
  }

  resetUserPassword(params: ResetUserPasswordMutationVariables) {
    return this.mutate(ResetUserPasswordGQL, params);
  }

  setOrUnsetUserToEmployeeAndVendor(
    params: SetOrUnsetUserToEmployeeAndVendorMutationVariables,
  ) {
    return this.mutate(SetOrUnsetUserToEmployeeAndVendorGQL, params);
  }

  addOrUpdateUserSkills(params: AddOrUpdateUserSkillsMutationVariables) {
    return this.mutate(AddOrUpdateUserSkillsGQL, params);
  }

  updateUserPreference(params: UpdateUserPreferencesMutationVariables) {
    return this.mutate(UpdateUserPreferencesGQL, params).pipe(
      map(response => response?.data?.updateUserPreference),
    );
  }

  getGroupedUserPreferences(params: GroupedUserPreferencesQueryVariables) {
    return this.query(GroupedUserPreferencesGQL, params).pipe(
      map(response => response?.data?.groupedUserPreferences),
    );
  }

  reportIssue(message: string) {
    const params: ReportIssueGtopMutationVariables = {
      message,
    };
    return this.mutate(ReportIssueGtopGQL, params).pipe(
      map(response => response.data?.reportIssueGtop),
    );
  }

  updateUser(params: UpdateUserMutationVariables) {
    return this.mutate(UpdateUserGQL, params);
  }

  toggleNotifications(params: ToggleNotificationsMutationVariables) {
    return this.mutate(ToggleNotificationsGQL, params);
  }

  dropManualPermissions(params: DropManualPermissionsMutationVariables) {
    return this.mutate(DropManualPermissionsGQL, params).pipe(
      map(response => response.data?.dropManualPermissions),
    );
  }
}
