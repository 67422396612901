export const TIME_SHEET_PERMISSIONS = {
  readById: { module: 'TimeSheet', action: 'GetTimeSheetById' },
  readByFilter: { module: 'TimeSheet', action: 'GetTimeSheetsByFilter' },
  updateComment: { module: 'TimeSheet', action: 'UpdateTimeSheetComment' },
  updateState: { module: 'TimeSheet', action: 'UpdateTimeSheetState' },
  updateHours: { module: 'TimeSheet', action: 'UpdateTimeSheetHours' },
  updateTimesheet: { module: 'TimeSheet', action: 'UpdateTimeSheet' },
  exportCSV: { module: 'TimeSheet', action: 'ExportTimeSheetCSV' },
  importCSV: { module: 'TimeSheet', action: 'ImportTimeSheetCSV' },
  resetTimeSheetDiscrepancy: {
    module: 'TimeSheet',
    action: 'ResetTimeSheetDiscrepancy',
  },
  getTimeSheetsAllByFilter: {
    module: 'TimeSheet',
    action: 'GetTimeSheetsAllByFilter',
  },
  changeStatusTimeSheets: {
    module: 'TimeSheet',
    action: 'ChangeStatusTimeSheets',
  },
  updateTimeSheetDiscrepancy: {
    module: 'TimeSheet',
    action: 'UpdateTimeSheetDiscrepancy',
  },
};
