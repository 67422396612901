export const VENDORS_PERMISSIONS = {
  read: { module: 'Vendor', action: 'GetAllVendors' },
  readByFilter: { module: 'Vendor', action: 'GetVendorsByFilter' },
  readById: { module: 'Vendor', action: 'GetVendorById' },
  update: { module: 'Vendor', action: 'UpdateVendor' },
  create: { module: 'Vendor', action: 'AddVendor' },
  activateDeactivateVendor: {
    module: 'Vendor',
    action: 'ActivateDeactivateVendor',
  },
  uploadLogoVendor: { module: 'Vendor', action: 'UploadLogoVendor' },
  uploadVendorDocument: {
    module: 'Vendor',
    action: 'UploadVendorDocument',
  },
  uploadVendorInvoice: {
    module: 'Vendor',
    action: 'UploadVendorInvoice',
  },
  downloadVendorDocument: {
    module: 'Vendor',
    action: 'GetDownloadVendorDocument',
  },
  downloadVendorInvoice: {
    module: 'Vendor',
    action: 'GetDownloadVendorInvoice',
  },
  deleteVendorDocument: {
    module: 'Vendor',
    action: 'DeleteVendorDocument',
  },
  deleteVendorInvoice: {
    module: 'Vendor',
    action: 'DeleteVendorInvoice',
  },
  updateVendorRounding: { module: 'Vendor', action: 'UpdateVendorRounding' },
  readVendorInvoices: { module: 'Vendor', action: 'GetVendorInvoices' },
  readVendorDocuments: { module: 'Vendor', action: 'GetVendorDocuments' },
  readVendorReportNotes: { module: 'Vendor', action: 'GetVendorReportNotes' },
  updateVendorReportNotes: {
    module: 'Vendor',
    action: 'UpdateVendorReportNotes',
  },
  changeVendorSowSigned: { module: 'Vendor', action: 'ChangeVendorSowSigned' },
};
