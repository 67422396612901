export const CANDIDATES_PERMISSIONS = {
  readByFilter: { module: 'Candidate', action: 'GetCandidatesByFilter' },
  readPersonalCandidates: {
    module: 'Candidate',
    action: 'GetPersonalCandidatesByFilter',
  },
  readById: { module: 'Candidate', action: 'GetCandidateById' },
  update: { module: 'Candidate', action: 'UpdateCandidate' },
  create: { module: 'Candidate', action: 'AddCandidate' },
  requestKandaCV: { module: 'Candidate', action: 'RequestKandaCV' },
  candidateRateByCandidateId: {
    module: 'Candidate',
    action: 'GetCandidateRateByCandidateId',
  },
  readCandidatesWithColorByFilter: {
    module: 'Candidate',
    action: 'GetCandidatesWithColorByFilter',
  },
  addCandidateComment: { module: 'Candidate', action: 'AddCandidateComment' },
};
