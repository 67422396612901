import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[assetsFormPageTabGroup]',
})
export class FormPageTabGroupDirective {
  @HostBinding('@.disabled')
  animationsDisabled = true;
}
