export const INTERVIEWS_PERMISSIONS = {
  readByFilter: {
    module: 'CandidateInterview',
    action: 'GetSelfCandidateInterviewsByFilter',
  },
  readById: {
    module: 'CandidateInterview',
    action: 'GetSelfCandidateInterviewByCandidateId',
  },
  update: {
    module: 'CandidateInterview',
    action: 'UpdateSelfCandidateInterview',
  },
};
