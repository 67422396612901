import {
  Component,
  inject,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { IDialogData } from '@common/components/dialog/basic-dialog-form.component';

@Component({
  selector: 'assets-base-dialog',
  template: `
    <div class="dialog">
      <div class="dialog-title" *ngIf="dialogData?.title ?? dialogTitle">
        <div>
          <h2 class="m-0">
            {{ dialogData?.title ?? dialogTitle ?? '' | translate }}
          </h2>
          <button mat-icon-button matDialogClose>
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="dialog-content">
        <ng-container *ngIf="dialogData?.title">
          <div class="mb-2" *ngFor="let field of dialogData?.fields">
            <p class="text-gray m-0 text-sm">{{ field.label | translate }}:</p>
            <ng-container *ngIf="!field.isRichText; else richText">
              <p
                class="text-dark text-lg font-weight-bold text-break"
                [innerHTML]="field.value"
              ></p>
            </ng-container>
            <ng-template #richText>
              <quill-view-html [content]="field.value ?? ''"></quill-view-html>
            </ng-template>
          </div>
        </ng-container>
        <ng-content></ng-content>
      </div>
      <div class="dialog-actions" *ngIf="!withoutActions">
        <ng-container *ngIf="actions; else defaultAction">
          <ng-template [ngTemplateOutlet]="actions"></ng-template>
        </ng-container>
        <ng-template #defaultAction>
          <button
            type="submit"
            mat-flat-button
            class="w-100 assets-btn primary-btn"
            (click)="close()"
          >
            {{ dialogData?.submitButtonText ?? 'OK' | translate }}
          </button>
        </ng-template>
      </div>
      <ngx-spinner [name]="spinnerName ?? ''"></ngx-spinner>
    </div>
  `,
})
export class BaseDialogComponent<T> implements OnDestroy {
  model: T;
  selectedIndex = 0;
  totalTabs: number;
  submitted$ = new Subject<boolean>();
  @Input() dialogTitle?: string;
  @Input() withoutActions: boolean;
  @Input() spinnerName?: string;
  @Input() actions: TemplateRef<any> | null;
  subscriptions$: Subscription[] = [];
  protected readonly destroy$ = new Subject<void>();
  readonly dialogData?: IDialogData<T> = inject(MAT_DIALOG_DATA);

  constructor(public dialogRef: MatDialogRef<any>) {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  isFormValid(): boolean {
    return false;
  }

  ngOnDestroy() {
    if (this.subscriptions$.length > 0) {
      this.subscriptions$.forEach(sub => {
        if (!sub.closed && sub) sub.unsubscribe();
      });
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    this.submitted$.next(true);
    if (this.dialogData?.callback) {
      if (this.dialogData.callback.synchronous) {
        this.dialogData.callback.action();
        return this.dialogRef.close(this.dialogData?.dialogRes ?? false);
      } else {
        this.dialogData.callback.action().subscribe(() => {
          this.dialogRef.close(this.dialogData?.dialogRes ?? false);
        });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  nextTab(index?: any) {
    if (index) {
      if (index > this.selectedIndex) {
        if (this.isFormValid()) {
          this.selectedIndex = index;
        } else {
        }
      } else {
        this.selectedIndex = index;
      }
    } else {
      if (this.isFormValid()) {
        this.selectedIndex = (this.selectedIndex + 1) % this.totalTabs;
      }
    }
  }

  get valid(): boolean {
    return !this.dialogData?.controls?.some(
      control =>
        control.control.status === 'INVALID' ||
        (control.control.status === 'DISABLED' && control.control.invalid),
    );
  }
}
