import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToOptions',
})
export class EnumToOptionsPipe implements PipeTransform {
  transform(
    options: Record<string, string | number>,
    map: Record<string, string | number> = {},
    prefix: string = '',
  ) {
    return Object.values(options).map((option: string | number) => {
      const name = map[option] ?? option;
      return {
        id: option,
        name: prefix ? prefix + '_' + name : String(name),
      };
    });
  }
}
