import { Directive, Input } from '@angular/core';

interface LinkContext {
  openInNewTab?: boolean;
}

@Directive({
  selector: '[appLinkContext]',
  standalone: true,
})
export class LinkContextDirective {
  @Input('appLinkContext') context: LinkContext;
}
