import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixedLink',
})
export class PrefixedLinkPipe implements PipeTransform {
  transform(base: string[], prefix?: string[]) {
    return [...(prefix ?? []), ...base];
  }
}
