import { HttpRequest } from '@angular/common/http';
import { SortEnumType } from '@common/generated/graphql';

export const minimumSetOfDictionaryParams = {
  variables: {
    take: 20,
  },
};

export const DEFAULT_SPINNER_TYPE = 'ball-clip-rotate-multiple';
export const DEFAULT_SPINNER_CONFIG = Object.freeze({
  size: 'medium',
  type: DEFAULT_SPINNER_TYPE,
  color: 'rgba(0, 124, 217, 1)',
  fullScreen: false,
  zIndex: 1000,
  bdColor: 'transparent',
});

export const ORDER_BY_NAME = {
  order: {
    name: SortEnumType.Asc,
  },
};

export const ORDER_BY_NAME_DESC = {
  order: {
    name: SortEnumType.Desc,
  },
};

export const NON_INTERCEPTED_OPERATIONS = [
  'RefreshToken',
  'SignIn',
  'externalSignIn',
  'ForgotPassword',
  'sendTwoStepVerificationMessageGtop',
] as const;
