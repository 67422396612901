export const config = {
  maxWidth: '100%',
  width: '37rem',
  height: '48.33rem',
  disableClose: false,
  hasBackdrop: true,
};

export const configConfirm = {
  maxWidth: '100%',
  width: '50rem',
  height: '20rem',
  disableClose: true,
  hasBackdrop: true,
};

export const updateDateDialogConfig = {
  maxWidth: '100%',
  width: '37rem',
  height: '24rem',
  disableClose: true,
  hasBackdrop: true,
};

export const candidateActionsDialogConfig = {
  maxWidth: '100%',
  width: '60rem',
  height: '55rem',
  disableClose: true,
  hasBackdrop: true,
};

export const employeeDuplicateDialogConfig = {
  maxWidth: '100%',
  width: '34rem',
  height: '25rem',
  disableClose: true,
  hasBackdrop: true,
};
