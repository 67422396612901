import { inject, Injectable } from '@angular/core';
import { GetBackendVersionGQL } from '@common/generated/graphql';
import {
  catchError,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { BaseApolloService } from '../base.apollo.service';
import { AuthenticationService } from '@common/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../models';

export abstract class ConfigService extends BaseApolloService {
  httpClient = inject(HttpClient);
  releaseVersion$ = inject(AuthenticationService).accessToken$.pipe(
    filter(Boolean),
    switchMap(() =>
      forkJoin([this.getFronendAppConfig(), this.getBackendVersion()]),
    ),
    map(([frontendVersion, backendVersion]) => ({
      frontend: frontendVersion,
      backend: backendVersion,
    })),
  );

  protected abstract getBackendVersion(): Observable<string | null>;

  private getFronendAppConfig() {
    return this.httpClient
      .get<AppConfig>('/assets/app.config.json?v=' + Date.now())
      .pipe(
        map(data => data.VERSION),
        catchError(err => {
          console.error(err);
          return of(null);
        }),
      );
  }
}
