import { Injectable } from '@angular/core';
import { GetBackendVersionGQL } from '@common/generated/graphql';
import { catchError, map, of } from 'rxjs';
import { ConfigService } from '@common/services/core/config.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigGtopService extends ConfigService {
  override getBackendVersion() {
    return this.query(GetBackendVersionGQL, {}).pipe(
      map(result => result.data.releaseVersion?.releaseVersion ?? null),
      catchError(() => of(null)),
    );
  }
}
