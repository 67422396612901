import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { hasRequiredField } from '../common/form.utils';

@Pipe({
  name: 'isRequiredField',
})
export class IsRequiredFieldPipe implements PipeTransform {
  transform(control: AbstractControl) {
    return hasRequiredField(control);
  }
}
