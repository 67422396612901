import { Directive } from '@angular/core';
import { CandidateStateEnum } from '@common/generated/graphql';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';

type Item = CandidateStateEnum;

@Directive({
  selector: '[appCandidateStatesChipResolver]',
})
export class CandidateStatesChipResolverDirective extends StatusChipResolverDirective<Item> {
  getValues(model: Item) {
    const state = model;
    const classNames = this.returnClassName(state);

    return {
      classNames,
      contentToken: state ?? '',
    };
  }

  returnClassName(state: CandidateStateEnum): string[] {
    switch (state) {
      case CandidateStateEnum.NewCandidate:
      case CandidateStateEnum.ClientPresentation:
      case CandidateStateEnum.Withdrawn:
        return ['assets-chip-yellow'];
      case CandidateStateEnum.BenchApproval:
        return ['assets-chip-success'];
      case CandidateStateEnum.Closed:
      case CandidateStateEnum.Rejected:
        return ['assets-chip-danger'];
      case CandidateStateEnum.Placed:
        return ['assets-chip-primary'];
      case CandidateStateEnum.Evaluation:
      case CandidateStateEnum.DeliveryReview:
      case CandidateStateEnum.Onboarding:
        return ['mat-chip-grey'];
      default:
        return [];
    }
  }
}
