<ng-container *ngIf="clickableValue; else unclickable">
  <a
    appClickStop
    [routerLink]="commands"
    [title]="text"
    [target]="target"
    [ngClass]="classes"
  >
    {{ text }}
  </a>
</ng-container>
<ng-template #unclickable>
  <span [ngClass]="classes">
    {{ text }}
  </span>
</ng-template>
